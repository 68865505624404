import { IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

interface BackButtonProps {
  classes?: ReactNode;
  onClick?: Function;
  history?: any;
  fontSize?: number;
  className?: any;
}

export const BackButton: React.FC<BackButtonProps> = props => {
  const history = useHistory();
  const { onClick } = props;
  const classes = useStyles(props);

  const handleClick = e => {
    if (onClick) {
      onClick(e);
    } else {
      history.goBack();
    }
  };

  return (
    <div className={props.className}>
      <IconButton onClick={e => handleClick(e)} size="medium" className={classes.icon}>
        <ChevronLeft fontSize="large" />
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    icon: {
      color: theme.colors.peacockBlue,
      opacity: 0.75,
    },
  })
);
