import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {
  HomeOutlined,
  ImportExportOutlined,
  MailOutlineOutlined,
  PermIdentityOutlined,
  SearchOutlined,
} from '@material-ui/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ApplicationState } from 'store';
import { AuthState } from 'store/auth/types';
import shortid from 'shortid';
import { adminLinks } from 'pages/admin';
import { useLayout } from 'layout/context';

export interface LinkInput {
  label: string;
  Icon?: any;
  to?: string;
  onClick?: any;
  [key: string]: any;
}

export interface MenuLink<T = {}, S = {}> extends LinkInput {
  subLinks?: LinkInput[];
}
const anonymousLinks: MenuLink[] = [
  {
    label: 'Dashboard',
    Icon: HomeOutlined,
    to: '/dashboard',
  },
  {
    label: 'Browse',
    Icon: SearchOutlined,
    to: '/lps',
  },
];

const userLinks: MenuLink[] = [
  ...anonymousLinks,
  {
    label: 'Reports',
    Icon: MailOutlineOutlined,
    to: '/reports',
  },
  {
    label: 'Profile',
    Icon: PermIdentityOutlined,
    to: '/profile',
  },
  {
    label: 'Upload / Download',
    Icon: ImportExportOutlined,
    to: '/docs',
  },
];

const ListItemSidebar: React.FC<MenuLink & any> = ({
  label,
  to,
  Icon: IconComponent,
  subLinks,
  indent: level = 0,
  child = false,
  className,
  sidebarIsOpen,
}) => {
  let match = useRouteMatch(to);
  const history = useHistory();
  // const location = useLocation();

  // const classes = useStyles();
  let open = match !== null;

  let color;
  if (match !== null) color = 'secondary';
  if (match?.isExact) color = 'primary';

  const handleClick = () => {
    if (to === undefined) {
      open = true;
    } else {
      open = true;
      history.push(to);
    }
  };

  return (
    <List key={shortid()} disablePadding>
      <ListItem button onClick={handleClick} selected={match?.isExact} style={{ minWidth: 0 }}>
        {IconComponent && (
          <ListItemIcon>
            <IconComponent color={color} />
          </ListItemIcon>
        )}

        <ListItemText
          primary={match?.isExact && <Typography color={color}>{label}</Typography>}
          secondary={
            !match?.isExact && (
              <Typography style={{ fontWeight: match?.isExact ? 'bold' : 'normal' }} color={color}>
                {label}
              </Typography>
            )
          }
          color={color}
          style={{ marginLeft: IconComponent === undefined ? 20 : 0 }}
        />
      </ListItem>

      <Collapse in={open && sidebarIsOpen}>
        {subLinks?.map(subLink => (
          <ListItemSidebar key={shortid()} {...subLink} indent={level + 1} child={true} />
        ))}
      </Collapse>
    </List>
  );
};

const SidebarLinks: React.FC = () => {
  const { sidebarOpen } = useLayout();
  const { data } = useSelector<ApplicationState, AuthState>(state => state.auth);
  let links = anonymousLinks;

  if (data?.UserType === 'Partner') links = userLinks;
  if (data?.Roles.includes('LPAdmin')) links = adminLinks;

  return (
    <>
      {links.map(props => (
        <ListItemSidebar key={shortid()} {...props} sidebarIsOpen={sidebarOpen} />
      ))}
    </>
  );
};

export default SidebarLinks;
