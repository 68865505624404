import styled from 'styled-components';
import { fluidPadding } from '../../utils/styles';

export const Main = styled.main`
  ${fluidPadding};
  padding-top: 2em;
  padding-bottom: 2em;
  height: 100%;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.iceBlue};
  display: grid;
  grid-template-columns:
    1em
    [content-start] 1fr [content-end]
    1em;
  grid-auto-rows: max-content;
  justify-content: stretch;
  justify-items: stretch;

  * {
    grid-column: content;
  }

  .MuiExpansionPanelDetails-root {
    display: unset;
    .MuiPaper-root {
      border: none;
    }
  }
`;

export const Section = styled.section`
  ${fluidPadding};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.03);
  * {
    box-shadow: none !important;
    border: none !important;
    * {
      box-shadow: none !important;
      border: none !important;
    }
  }
`;

export const List = styled.ul`
  margin: 0;
  width: 100%;
  list-style-type: none;
  margin-bottom: 1em;
  border-bottom: 1px solid ${({ theme }) => theme.colors.navyBlue};
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-content: center;
  align-items: center;

  li {
    margin: 0;
    padding: 0;
    padding: 0.5em 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.navyBlue};

    * {
      margin: 0;
      padding: 0;
    }

    a {
      text-transform: uppercase;
    }

    :first-child {
      width: 60%;
    }

    :last-child {
      justify-self: flex-end;
    }
  }
`;

export const Div = styled.div`
  margin: 0.5em 0 !important;
`;

export const LP = styled(List)`
  ${fluidPadding};
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.03);
  background-color: ${({ theme }) => theme.colors.navyBlue};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 1em !important;

  li {
    font-weight: bold;
  }

  h4 {
    justify-self: flex-start;
    font-size: 1em;
    font-weight: bold;
  }

  button {
    color: ${({ theme }) => theme.colors.white};
  }
`;
