import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

import useAxios from 'hooks/useAxios';

import { SignAuthorityOverlay } from 'components/data/SignAuthorityOverlay';
import { DocusignEmbeddedCA } from 'components/data/DocusignEmbeddedCA';

import { ApplicationState } from 'store';
import { OfferingState } from 'store/offerings/types';
import { AuthState } from 'store/auth/types';
import { updateUserSigningAuthorityRequest } from 'store/user-management/actions';

import { DocusignState } from 'store/docusign/types';
import { DocusignCreateCA } from 'store/docusign/types';
import { docusignCreateCARequest } from 'store/docusign/actions';

import { UserContext } from 'components/data/context';

import { lp as LpTermSheetRoute } from 'paths';

const SignCaPage = () => {
  const user = useContext(UserContext);
  const auth = useSelector<ApplicationState, AuthState>(state => state.auth);
  const docusign = useSelector<ApplicationState, DocusignState>(state => state.docusign);

  const [{ data: latestCaStatusData, loading: latestCaStatusLoading }, executeGet] = useAxios(
    {
      url: `usermanagement/users/${user.id}/latestCaStatus`,
      method: 'get',
    },
    { manual: true, useCache: false }
  );

  const dispatch = useDispatch();

  const ForwardSigningRequest = (recipient: string, recipientEmail: string) => {
    const caForward: DocusignCreateCA = {
      embedded: false,
      userObjectId: user.id,
      userEmailAddress: user.email,
      recipientEmailAddress: recipientEmail,
      recipientName: recipient,
      rtNumber: user.routingNumber,
      salesforceAccountId: null,
      embeddedViewReturnUrl: window.location.origin + '/docusign-callback',
    };

    dispatch(docusignCreateCARequest(caForward));
  };

  const [displaySigOverlay, setDisplaySigOverlay] = useState(true);
  useEffect(() => {
    if (!user.loading && user.id != null) {
      executeGet();
    }
    setDisplaySigOverlay(false);
  }, [executeGet, user]);

  const showSigOverlay = (shouldShow: boolean) => {
    setDisplaySigOverlay(shouldShow);
  };

  const closeSigOverlay = () => {
    executeGet();
    showSigOverlay(false);
  };

  const signingAuthPatchOperation = {
    op: 'replace',
    path: '/signingAuthority',
    value: 'true',
  };
  const addSigningAuth = () => {
    const patchData = [signingAuthPatchOperation];
    dispatch(updateUserSigningAuthorityRequest(auth.data.UserObjectId, patchData));

    const ca: DocusignCreateCA = {
      embedded: true,
      userObjectId: user.id,
      userEmailAddress: user.email,
      recipientEmailAddress: user.email,
      recipientName: user.name,
      rtNumber: user.routingNumber,
      salesforceAccountId: user.creditUnionAccountId,
      embeddedViewReturnUrl: window.location.origin + '/docusign-callback',
    };
    dispatch(docusignCreateCARequest(ca));
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    showSigOverlay(true);
  };

  const negateSigningAuth = () => {
    const patchData = [{ ...signingAuthPatchOperation, value: 'false' }];
    dispatch(updateUserSigningAuthorityRequest(auth.data.UserObjectId, patchData));
  };

  const [lpTermSheetUnverifiedMessage, setLpTermSheetUnverifiedMessage] = React.useState(
    UnverifiedUserMessage
  );
  const [lpTermSheetButtonLabel, setLpTermSheetButtonLabel] = React.useState('Sign Here');
  const lpTermSheetRouteMatch = useRouteMatch(LpTermSheetRoute);
  const offering = useSelector<ApplicationState, OfferingState>(state => state.offering);

  useEffect(() => {
    if (!latestCaStatusLoading && latestCaStatusData?.status === 'completed') {
      setLpTermSheetUnverifiedMessage(UnVerifiedUserWaiting);
      if (!offering.loading && offering?.data?.State === 'Open') {
        setLpTermSheetUnverifiedMessage(UnVerifiedUserWaitingOpenTermSheet);
      }
      setLpTermSheetButtonLabel('Submit a new User Agreement');
    }
  }, [latestCaStatusData, latestCaStatusLoading, offering]);

  if (user.authenticated && !user.verified && !user.admin) {
    return (
      <Fragment>
        <OverallWrapper>
          {!latestCaStatusLoading &&
            latestCaStatusData?.status === 'completed' &&
            !lpTermSheetRouteMatch?.isExact && (
              <Wrapper>
                <Message>{UnVerifiedUserWaiting}</Message>
              </Wrapper>
            )}
          {((!latestCaStatusLoading &&
            (!latestCaStatusData || latestCaStatusData?.status !== 'completed')) ||
            lpTermSheetRouteMatch?.isExact) && (
            <Wrapper>
              <Button variant="contained" color="primary" onClick={handleClick}>
                {lpTermSheetButtonLabel}
              </Button>
              <Message>{lpTermSheetUnverifiedMessage}</Message>
            </Wrapper>
          )}

          <SignAuthorityOverlay
            onClose={closeSigOverlay}
            displayOverlay={displaySigOverlay}
            userInfo={auth}
            addSigningAuth={addSigningAuth}
            negateSigningAuth={negateSigningAuth}
            ForwardSigningRequest={ForwardSigningRequest}
            loading={docusign && docusign.loading}
          />
          <DocusignEmbeddedCA docusign={docusign} />
        </OverallWrapper>
      </Fragment>
    );
  }

  return null;
};
export default SignCaPage;

const OverallWrapper = styled.div<any>`
  z-index: 9999999;
  position: sticky;
  bottom: 0;
`;

const Wrapper = styled.div`
  border-radius: 3px;
  position: sticky;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 900;
  height: 115;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  padding: 0.25em;
  button {
    margin: 0.25em;
  }
`;
const Message = styled.div`
  && {
    color: white;
  }
`;
const UnverifiedUserMessage =
  'Thank you for signing up for the LP Exchange.  Please feel free to browse our website.  To view Due Diligence information for any Loan Participation offering, your organization must have a signed User Agreement on file.';

const UnVerifiedUserWaitingOpenTermSheet =
  'We are currently processing the User Agreement for your credit union.  Once complete you will have access to view Due Diligence information.  Please contact us if you require immediate assistance.';

const UnVerifiedUserWaiting =
  'We are currently processing the User Agreement for your credit union.  Please contact us if you require immediate assistance.';
