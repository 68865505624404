import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { LPActionTypes } from './types';
import {
  fetchError,
  fetchSuccess,
  select,
  dueDiligenceRequest,
  dueDiligenceError,
  dueDiligenceSuccess,
  fetchSuccessSingle,
  fetchImagesRequest,
  fetchImagesSuccess,
  // sfAccountNumberError,
  // sfAccountNumberSuccess,
  // sfAccountNumberRequest
} from './actions';
import { callApi } from '../../utils/api';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

function* handleFetch() {
  try {
    const res = yield call(callApi, 'get', API_ENDPOINT, '/termsheets');
    yield res.error ? put(fetchError(res.error)) : put(fetchSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(fetchError(err.stack!))
      : put(fetchError('An unknown error occured.'));
  }
}

function* handleSelect(action: ReturnType<typeof select>) {
  try {
    const res = yield call(callApi, 'get', API_ENDPOINT, `/termsheets/${action.payload}`);
    yield res.error ? put(fetchError(res.error)) : put(fetchSuccessSingle(res));
  } catch (err) {
    yield err instanceof Error
      ? put(fetchError(err.stack!))
      : put(fetchError('An unknown error occured.'));
  }
}

function* handleFetchImages(action: ReturnType<typeof fetchImagesRequest>) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/documentcatalog/offering/${action.payload}/termsheetimages`
    );
    yield res.error ? put(fetchError(res.error)) : put(fetchImagesSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(fetchError(err.stack!))
      : put(fetchError('An unknown error occured.'));
  }
}

function* handleDueDiligence(action: ReturnType<typeof dueDiligenceRequest>) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/documentcatalog/offering/${action.payload}/files`
    );
    yield res.error ? put(dueDiligenceError(res.error)) : put(dueDiligenceSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(dueDiligenceError(err.stack!))
      : put(dueDiligenceError('An unknown error occured.'));
  }
}

function* watchSelect() {
  yield takeLatest(LPActionTypes.SELECT, handleSelect);
}

function* watchFetchRequest() {
  yield takeEvery(LPActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchFetchLpImagesRequest() {
  yield takeEvery(LPActionTypes.FETCH_LP_IMAGES_REQUEST, handleFetchImages);
}

function* watchDueDiligenceRequest() {
  yield takeEvery(LPActionTypes.DUE_DILIGENCE_REQUEST, handleDueDiligence);
}

function* lpSaga() {
  yield all([
    fork(watchFetchRequest),
    fork(watchSelect),
    fork(watchDueDiligenceRequest),
    fork(watchFetchLpImagesRequest),
  ]);
}

export { lpSaga as LPSaga };
