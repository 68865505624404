import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LPButton } from '../LPButton';

const useStyles = makeStyles({
  tabWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 30,
    '& button': {
      marginBottom: '1em',
    },
    '& span': {
      fontWeight: 600,
      marginRight: '0.5em',
    },
  },
});

export default function LPTableShowAll({ onShowAll }) {
  const classes = useStyles(undefined);
  function handleClick(event) {
    onShowAll(event, Number.POSITIVE_INFINITY);
  }

  return (
    <div className={classes.tabWrapper}>
      <LPButton variant="outlined" onClick={handleClick}>
        Show All
      </LPButton>
    </div>
  );
}
