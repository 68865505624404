import { Store, createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { History } from 'history';
import { ApplicationState, CreateRootReducer, RootSaga } from '.';

const composeEnhancers = composeWithDevTools({});
const sagaMiddleware = createSagaMiddleware();

export default function configureStore(
  history: History,
  initialState: ApplicationState
): Store<ApplicationState> {
  const store = createStore(
    CreateRootReducer(history),
    initialState,
    process.env.NODE_ENV === 'development'
      ? composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
      : applyMiddleware(routerMiddleware(history), sagaMiddleware)
    // ! Use this middleware in develpment to connect redux-devtools */
  );

  sagaMiddleware.run(RootSaga);

  return store;
}
