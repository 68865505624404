import React, { useState } from 'react';
import { UserInfo } from '../../../store/user-management/types';
import { LPTableDetailView } from './LPTableDetailView';
import { LPTableRowMain } from './LPTableRowMain';
import { Header } from './types';
import { apiUrl } from '../../../utils/api';

export interface LPTableRowProps {
  row?: UserInfo;
  headers?: Header[];
  supplementalViewEndpoint?: string;
  detailHeaders?: Header[];
  selectable?: boolean;
  selected?: any[];
  handleSelect?: (event: React.ChangeEvent, add: boolean, id) => void;
  keyIdentifier?: string;
  isNew?: boolean;
}

export const LPTableRow: React.FC<LPTableRowProps> = props => {
  const {
    row,
    handleSelect,
    keyIdentifier,
    selected = [],
    detailHeaders,
    supplementalViewEndpoint,
  } = props;
  const [showDetailView, setShowDetailView] = useState(false);
  const [checked, setChecked] = useState(
    selected.find(el => el === row[keyIdentifier]) !== undefined
  );
  const [supplementalView, setSupplementalView] = useState({
    data: undefined,
    loading: false,
    errors: undefined,
  });

  const handleClick = async (event: React.MouseEvent<any>) => {
    if (detailHeaders) {
      setShowDetailView(!showDetailView);
      if (supplementalViewEndpoint && !showDetailView) {
        setSupplementalView({ ...supplementalView, loading: true });
        const ep =
          apiUrl + `api/${supplementalViewEndpoint.replace('{keyIdentifier}', row[keyIdentifier])}`;
        const res = await fetch(ep);
        if (!res.ok) {
          setSupplementalView({
            data: undefined,
            loading: false,
            errors: `${res.status} ${res.statusText}`,
          });
        } else {
          const data = await res.json();
          setSupplementalView({
            data: data,
            loading: false,
            errors: undefined,
          });
        }
      }
    }
  };

  const onSelect = (e: React.ChangeEvent, index) => {
    const tempChecked = checked;
    setChecked(!checked);
    handleSelect(e, !tempChecked, index);
  };

  return (
    <React.Fragment>
      <LPTableRowMain
        {...props}
        handleClick={handleClick}
        checked={checked}
        onSelect={onSelect}
        showDetailView={showDetailView}
      />
      {detailHeaders && showDetailView && (
        <LPTableDetailView row={row} supplementalView={supplementalView} {...props} />
      )}
    </React.Fragment>
  );
};
