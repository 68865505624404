import { Box, CircularProgress } from '@material-ui/core';
import useAxios from 'hooks/useAxios';
import {
  IPurchaseCommitment,
  Offering,
  IPurchaseCommitmentViewModel,
} from 'pages/forms/PurchaseCommitment';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TransformResponse } from 'utils/api';
import { formatTermsheet, TermsheetData } from 'utils/formatters';
import CodeHelper from 'components/CodeHelper';
import { isDev } from 'utils/helpers';

type FormContextState = {
  offering: Offering;
  termsheet: TermsheetData;
  commitment?: IPurchaseCommitmentViewModel;
  loading: boolean;
};

const FormContext = React.createContext<FormContextState | undefined>(undefined);

export const FormContextProvider = ({ children }) => {
  const { id: lpNumber } = useParams();
  const { pathname } = useLocation();
  const [offeringRequest] = useAxios<Offering>(`/marketplace/offerings/${lpNumber}`, {
    useCache: false,
  });

  const { data: offering, loading: loadingOffering, error: offeringError } = offeringRequest;

  const [termsheetRequest] = useAxios<TermsheetData>(
    {
      method: 'GET',
      url: `/termsheets/${lpNumber}`,
      transformResponse: TransformResponse(formatTermsheet),
    },
    { useCache: false }
  );

  const { data: termsheet, loading: loadingTermsheet, error: termsheetError } = termsheetRequest;

  const isCommitment = pathname.split('/').includes('purchase-commitment');
  console.log('isCommitment: ', isCommitment);

  const [{ data: commitment, loading: loadingCommitment, error: commitmentError }] = useAxios<
    IPurchaseCommitmentViewModel
  >(`marketplace/offering/${lpNumber}/user/commitment`, {
    useCache: false,
    manual: !isCommitment,
  });

  if (loadingOffering || loadingTermsheet || loadingCommitment)
    return (
      <Box
        height="100vh"
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        color="white"
      >
        <CircularProgress color="inherit" />
      </Box>
    );

  if (termsheetError && isDev) return <CodeHelper error={termsheetError} />;
  if (offeringError && isDev) return <CodeHelper error={offeringError} />;
  if (commitmentError && isDev) return <CodeHelper error={commitmentError} />;

  return (
    <FormContext.Provider
      value={{
        loading: loadingOffering && loadingCommitment && loadingTermsheet,
        termsheet,
        offering,
        commitment,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export function useFormContext() {
  return React.useContext(FormContext);
}
