import React, { useState } from 'react';
import { ConfirmationModal } from '../components/Common/ConfirmationModal';

interface FlashNotificationProps {
  message: string;
  header: string;
  buttonText: string;
}

export const setFlashNotification = (header, message, buttonText) => {
  sessionStorage.setItem(
    'flashNotification',
    JSON.stringify({ header: header, message: message, buttonText: buttonText })
  );
};

export const getFlash = () => {
  return sessionStorage.getItem('flashNotification');
};

export const resetFlash = () => {
  sessionStorage.setItem('flashNotification', JSON.stringify({}));
};

const useFlashNotification: () => JSX.Element = () => {
  if (getFlash() === null) resetFlash();
  const flash = JSON.parse(getFlash());
  const { header, message, buttonText } =
    typeof flash === 'object'
      ? (flash as FlashNotificationProps)
      : { header: '', message: '', buttonText: '' };
  const [showFlashNotification, setShowFlashNotification] = useState<boolean>(
    Boolean(header) && Boolean(message)
  );
  const closeModal = () => setShowFlashNotification(false);
  return (
    <ConfirmationModal
      message={message}
      header={header}
      buttonText={buttonText}
      onClose={closeModal}
      display={showFlashNotification}
    />
  );
};

export default useFlashNotification;
