import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { UserManagementActionTypes, SalesforceAccountQuery } from './types';
import {
  fetchRequest,
  fetchError,
  fetchSuccess,
  userSelected,
  selectUser,
  sfAccountNumberError,
  sfAccountNumberSuccess,
  sfAccountNumberRequest,
  acceptUserRequest,
  updateUserSigningAuthoritySuccess,
  updateUserSigningAuthorityRequest,
  filterRequest,
  filterSuccess,
  filterError,
  acceptUserError,
  acceptUserSuccess,
  updateUserRequest,
  updateUserError,
  updateUserSuccess,
  fetchResourceError,
  fetchResourceSuccess,
  fetchResourceRequest,
} from './actions';
import { callApi } from '../../utils/api';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

function* handleFetch(action: ReturnType<typeof fetchRequest>) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/usermanagement/views/admin/users/?${action.payload}`
    );

    yield res.error ? put(fetchError(res.error)) : put(fetchSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(fetchError(err.stack!))
      : put(fetchError('An unknown error occured.'));
  }
}

function* handleFetchResource(action: ReturnType<typeof fetchResourceRequest>) {
  try {
    const res = yield call(callApi, 'get', API_ENDPOINT, action.payload);
    yield res.error ? put(fetchResourceError(res.error)) : put(fetchResourceSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(fetchResourceError(err.stack!))
      : put(fetchResourceError('An unknown error occured.'));
  }
}

function* handleFilter(action: ReturnType<typeof filterRequest>) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/usermanagement/views/admin/readytoverify/?${action.payload}`
    );

    yield res.error ? put(filterError(res.error)) : put(filterSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(filterError(err.stack!))
      : put(filterError('An unknown error occured.'));
  }
}

function* handleAddUserSigningAuthority(
  action: ReturnType<typeof updateUserSigningAuthorityRequest>
) {
  try {
    const res = yield call(
      callApi,
      'patch',
      API_ENDPOINT,
      `/usermanagement/users/${action.payload.id}`,
      action.payload.data
    );

    yield res.error ? put(updateUserError(res.error)) : put(updateUserSigningAuthoritySuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(updateUserError(err.stack!))
      : put(updateUserError('An unknown error occured.'));
  }
}

function* handleUpdateUserRequest(action: ReturnType<typeof updateUserRequest>) {
  try {
    const res = yield call(
      callApi,
      'patch',
      API_ENDPOINT,
      `/usermanagement/users/${action.payload.id}`,
      action.payload.data
    );

    let successActions: any[] = [put(updateUserSuccess(res))];
    if (action.payload.verifyUserSubmission) {
      successActions.push(put(acceptUserRequest(action.payload.verifyUserSubmission)));
    }

    yield res.error ? put(updateUserError(res.error)) : all(successActions);
  } catch (err) {
    yield err instanceof Error
      ? put(updateUserError(err.stack!))
      : put(updateUserError('An unknown error occured.'));
  }
}

function* handleAcceptUserRequest(action: ReturnType<typeof acceptUserRequest>) {
  try {
    const res = yield call(
      callApi,
      'post',
      API_ENDPOINT,
      `/usermanagement/users/${action.payload.userId}/accept`,
      {
        salesforceAccountId: action.payload.salesforceAccountId,
        rtNumber: action.payload.rtNumber,
        envelopeId: action.payload.envelopeId,
        creditUnionName: action.payload.creditUnionName,
        userId: action.payload.userId,
      }
    );

    yield res.error
      ? put(acceptUserError(res.error))
      : all([put(acceptUserSuccess(action.payload.userId))]);
  } catch (err) {
    yield err instanceof Error
      ? put(acceptUserError(err.stack!))
      : put(acceptUserError('An unknown error occured.'));
  }
}

function* handleSFAccountNumber(action: ReturnType<typeof sfAccountNumberRequest>) {
  try {
    const res = yield call(
      callApi,
      'post',
      API_ENDPOINT,
      '/salesforce/accountnumbersandcontacts',
      action.payload
    );

    yield res.error ? put(sfAccountNumberError(res.error)) : put(sfAccountNumberSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(sfAccountNumberError(err.stack!))
      : put(sfAccountNumberError('An unknown error occured.'));
  }
}

function* handleSelect(action: ReturnType<typeof selectUser>) {
  try {
    const res = yield call(callApi, 'get', API_ENDPOINT, `/usermanagement/users/${action.payload}`);

    const query: SalesforceAccountQuery = {
      creditUnionName: res.creditUnionName,
      contactName: res.displayName,
      email: res.emailAddress,
      routingNumber: res.rtNumber,
    };

    yield res.error
      ? put(fetchError(res.error))
      : all([put(userSelected(res)), put(sfAccountNumberRequest(query))]);
  } catch (err) {
    yield err instanceof Error
      ? put(fetchError(err.stack!))
      : put(fetchError('An unknown error occured.'));
  }
}

function* watchSFAccountNumbersRequest() {
  yield takeLatest(UserManagementActionTypes.SF_ACCOUNTNUMBER_REQUEST, handleSFAccountNumber);
}

function* watchSelectUser() {
  yield takeLatest(UserManagementActionTypes.SELECT_USER, handleSelect);
}

function* watchFetchRequest() {
  yield takeEvery(UserManagementActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchFetchResourceRequest() {
  yield takeEvery(UserManagementActionTypes.FETCH_RESOURCE_REQUEST, handleFetchResource);
}

function* watchFilterRequest() {
  yield takeEvery(UserManagementActionTypes.FILTER_REQUEST, handleFilter);
}

function* watchUpdateUserRequest() {
  yield takeEvery(UserManagementActionTypes.UPDATE_USER_REQUEST, handleUpdateUserRequest);
}

function* watchAcceptUserRequest() {
  yield takeEvery(UserManagementActionTypes.ACCEPT_USER_REQUEST, handleAcceptUserRequest);
}

function* watchAddUserSigningAuthority() {
  yield takeEvery(
    UserManagementActionTypes.UPDATE_USER_SIGN_AUTHORITY_REQUEST,
    handleAddUserSigningAuthority
  );
}

function* userManagementSaga() {
  yield all([
    fork(watchFetchRequest),
    fork(watchFetchResourceRequest),
    fork(watchFilterRequest),
    fork(watchAcceptUserRequest),
    fork(watchAddUserSigningAuthority),
    fork(watchSFAccountNumbersRequest),
    fork(watchSelectUser),
    fork(watchUpdateUserRequest),
  ]);
}

export { userManagementSaga as UserManagementSaga };
