import { Button, CircularProgress, Container } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { fluidMargin } from '../../utils/styles';
import { useState } from 'react';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { resetFlash } from '../../hooks/useFlashNotification';

interface ConfirmationModalProps {
  display: boolean;
  message: string;
  header: string;
  buttonText?: string;
  secondButton?: {
    exists: boolean;
    text?: string;
    onClick?: (e: any) => Promise<void>;
  };
  onClose: () => void;
  requestState?: {
    loading: boolean;
    success: boolean;
    errors: boolean;
    message?: string;
  };
}

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 3600;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(119, 126, 138, 0.75);
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  align-content: space-between;
  align-items: center;

  .box {
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
    ${fluidMargin};

    padding: 2em 2em;
    border-radius: 10px;
    background: #fff;
    height: 400px;
    max-width: 450px;

    h2 {
      text-transform: uppercase;
      color: ${props => props.theme.colors.navyBlue};
      font-size: 2em;
    }

    p {
      font-size: 1.5em;
    }
  }

  .close {
    position: absolute;
    top: 0.25em;
    left: 0.25em;
  }

  .check {
    width: 80px;
    height: 80px;
    svg {
      font-size: 4em;
      color: ${props => props.theme.colors.signupBlue};
    }
  }

  .button {
    padding: 8px 44px;
    margin: 0px 5px 0px 5px;
  }

  .loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
`;

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  header,
  message,
  buttonText,
  secondButton = { exists: false, text: '', onClick: null },
  requestState = { loading: false, errors: false, success: true },
  display = false,
  onClose,
}) => {
  const [show, setShow] = useState(display);

  // remove flash storage of notification on page change, if the notification is showing
  useEffect(() => {
    const removeFlash = () => {
      if (show) resetFlash();
    };
    window.addEventListener('beforeunload', removeFlash);
    return () => window.removeEventListener('beforeunload', removeFlash);
  });

  useEffect(() => {
    if (display && !show) {
      setShow(display);
    }
    return () => {
      setShow(false);
    };
  }, [display]);

  if (!show) return null;

  const handleClose = () => {
    resetFlash();
    onClose();
  };

  const ModalContent = () => {
    if (requestState.loading) {
      return (
        <div className="loading-indicator">
          <CircularProgress />
        </div>
      );
    }

    if (requestState.errors) {
      return <p>There was an error in sending the request</p>;
    }

    if (!requestState.loading && requestState.success) {
      return (
        <Fragment>
          <div>
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>

            <Fab size="large" color="secondary" disabled aria-label="add" className="check">
              <CheckRoundedIcon />
            </Fab>
          </div>
          <div>
            <h2>{header}</h2>
            <p>{message}</p>
          </div>

          <span>
            <Button variant="contained" color="primary" onClick={handleClose} className="button">
              {buttonText ? buttonText : 'CONTINUE'}
            </Button>
            {secondButton.exists && (
              <Button
                variant="contained"
                color="primary"
                onClick={secondButton.onClick}
                value={secondButton.text}
                className="button"
              >
                {secondButton.text}
              </Button>
            )}
          </span>
        </Fragment>
      );
    }
  };

  return (
    <ModalWrapper>
      <Container className="box">
        <ModalContent />
      </Container>
    </ModalWrapper>
  );
};
