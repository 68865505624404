import { combineReducers, Dispatch, Action, AnyAction } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { connectRouter, RouterState } from 'connected-react-router';

import { History } from 'history';

import { AuthReducer } from './auth/reducer';
import { AuthState } from './auth/types';
import { AuthSaga } from './auth/sagas';

import { SignupState } from './signup/types';
import { SignupReducer } from './signup/reducer';
import { SignupSaga } from './signup/sagas';

import { UserManagementReducer } from './user-management/reducer';
import { UserManagementState } from './user-management/types';
import { UserManagementSaga } from './user-management/sagas';

import { LPReducer } from './lps/reducer';
import { LPState } from './lps/types';
import { LPSaga } from './lps/sagas';

import { DocusignReducer } from './docusign/reducer';
import { DocusignState } from './docusign/types';
import { DocusignSaga } from './docusign/sagas';
import { LPOfferingSaga } from './offerings/sagas';
import { OfferingState } from './offerings/types';
import { OfferingReducer } from './offerings/reducer';
import { InterestState } from './interests/types';
import { InterestSaga } from './interests/sagas';
import { InterestReducer } from './interests/reducer';
import { DownloadsReducer } from './downloads/reducer';
import { DownloadsSaga } from './downloads/sagas';
import { DownloadsState } from './downloads/types';

export interface ApplicationState {
  auth: AuthState;
  router: RouterState;
  signup: SignupState;
  docusign: DocusignState;
  userManagement: UserManagementState;
  lp: LPState;
  offering: OfferingState;
  interest: InterestState;
  downloads: DownloadsState;
}

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

export const CreateRootReducer = (history: History) =>
  combineReducers({
    auth: AuthReducer,
    router: connectRouter(history),
    signup: SignupReducer,
    docusign: DocusignReducer,
    userManagement: UserManagementReducer,
    lp: LPReducer,
    offering: OfferingReducer,
    interest: InterestReducer,
    downloads: DownloadsReducer,
  });

export function* RootSaga() {
  yield all([
    fork(AuthSaga),
    fork(SignupSaga),
    fork(DocusignSaga),
    fork(UserManagementSaga),
    fork(LPSaga),
    fork(LPOfferingSaga),
    fork(InterestSaga),
    fork(DownloadsSaga),
  ]);
}
