// export interface LPInfo extends ApiResponse {
//   objectId: string;
//   state: number;
//   city: string;
//   displayName: string;
//   givenName: string;
//   emailAddress: string;
//   jobTitle: string;
//   postalCode: string;
//   stateProvince: string;
//   surname: string;
//   userIsNew: boolean;
//   rtNumber: string;
//   creditUnionName: string;
//   accountEnabled: boolean;
//   streetAddress: string;
//   accountNumbers: AccountNumber[];
// }

// export interface AccountNumber extends ApiResponse {
//   name: string;
//   RoutingNumber: string;
//   account_Name__c: string;
//   account: Account;
//   account_No_Type__c: string;
//   acct_No_Status__c: string;
//   attributes: Attributes;
//   CCUN_CU_Name__c?: any;
//   Charter_No__c?: any;
//   Account_Name__r: Account;
// }

// export interface Attributes {
//   type: string;
//   url: string;
// }

// export interface Account {
//   name: string;
//   attributes: Attributes;
// }

// export interface Account {
//   attributes: Attributes;
//   Name: string;
// }

// export type ApiResponse = Record<string, any>;

export enum LPActionTypes {
  FETCH_REQUEST = '@@lp/FETCH_REQUEST',
  FETCH_SUCCESS = '@@lp/FETCH_SUCCESS',
  FETCH_SINGLE_LO_SUCCESS = '@@lp/FETCH_SINGLE_LO_SUCCESS',
  FETCH_ERROR = '@@lp/FETCH_ERROR',
  SELECT = '@@lp/SELECT',
  SELECTED = '@@lp/SELECTED',
  CLEAR_SELECTED = '@@lp/CLEAR_SELECTED',
  DUE_DILIGENCE_REQUEST = '@@lp/INDICATION_OF_INTEREST_REQUEST',
  DUE_DILIGENCE_SUCCESS = '@@lp/INDICATION_OF_INTEREST_SUCCESS',
  DUE_DILIGENCE_ERROR = '@@lp/INDICATION_OF_INTEREST_ERROR',
  FETCH_LP_IMAGES_REQUEST = '@@lp/FETCH_LP_IMAGES_REQUEST',
  FETCH_LP_IMAGES_SUCCESS = '@@lp/FETCH_LP_IMAGES_SUCCESS',
  // SF_ACCOUNTNUMBER_REQUEST = '@@lp/SF_ACCOUNTNUMBER_REQUEST',
  // SF_ACCOUNTNUMBER_SUCCESS = '@@lp/SF_ACCOUNTNUMBER_SUCCESS',
  // SF_ACCOUNTNUMBER_ERROR = '@@lp/SF_ACCOUNTNUMBER_ERROR'
}

export interface LPState {
  readonly loading: boolean;
  // readonly sfLoading: boolean;
  readonly data?: any;
  readonly files?: [];
  readonly selected?: any;
  readonly errors?: string;
  readonly lpData?: any;
  readonly lpImages?: [];
}
