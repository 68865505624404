import React from 'react';
import { Redirect } from 'react-router-dom';

interface Props {}

const LogoutPage: React.FC<Props> = () => {
  window.location.assign('/api/auth/signout');
  return <Redirect to="/" />;
};

export default LogoutPage;
