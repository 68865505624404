import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { FormatDateTime } from '../../utils/helpers';
import { Wrapper } from './styles';

const FileDropZone = () => {
  const maxSize = 5.12e8;
  const [fileNames, setFileNames] = useState([]);

  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return { url: 'api/documentcatalog/uploads' };
  };

  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === 'done') {
      setFileNames(old => [...old, meta]);
      remove();
    }
    console.log(status, meta, meta.name);
  };

  return (
    <Wrapper className="upload">
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxSizeBytes={maxSize}
        styles={{
          dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
        }}
      />

      <List style={{ maxHeight: '100%', overflow: 'auto' }}>
        {fileNames.map((file, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <CheckCircleOutlineIcon color="secondary" />
            </ListItemAvatar>
            <ListItemText
              primary={`${file.name} - Uploaded ${FormatDateTime(file.uploadedDate)}`}
            />
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

export default FileDropZone;
