import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { AuthState } from '../store/auth/types';
import { fetchRequest } from '../store/auth/actions';
import { UserContext } from 'components/data/context';

export default function useUserInfo() {
  return useContext(UserContext);
}
