import { action } from 'typesafe-actions';
import { OfferingActionTypes } from './types';

export const fetchRequest = (queryParams: Object) =>
  action(OfferingActionTypes.FETCH_REQUEST, queryParams);
export const fetchSuccess = (data: any[]) => action(OfferingActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(OfferingActionTypes.FETCH_ERROR, message);

export const filterRequest = (queryParams: Object) =>
  action(OfferingActionTypes.FILTER_REQUEST, queryParams);
export const filterSuccess = (data: any[]) => action(OfferingActionTypes.FILTER_SUCCESS, data);
export const filterError = (message: string) => action(OfferingActionTypes.FILTER_ERROR, message);

export const selectRequest = (id: string) => action(OfferingActionTypes.SELECT_REQUEST, id);
export const selectSuccess = (data: any[]) => action(OfferingActionTypes.SELECT_SUCCESS, data);
export const selectError = (message: string) => action(OfferingActionTypes.SELECT_ERROR, message);

export const fetchLPRequest = () => action(OfferingActionTypes.FETCH_LP_REQUEST);
export const fetchLPSuccess = (data: any[]) => action(OfferingActionTypes.FETCH_LP_SUCCESS, data);
export const fetchLPError = (message: string) =>
  action(OfferingActionTypes.FETCH_LP_ERROR, message);

export const publishRequest = (id: string) => action(OfferingActionTypes.PUBLISH_REQUEST, id);
export const publishSuccess = (data: any[]) => action(OfferingActionTypes.PUBLISH_SUCCESS, data);
export const publishError = (message: string) => action(OfferingActionTypes.PUBLISH_ERROR, message);

export const unpublishRequest = (id: string) => action(OfferingActionTypes.UNPUBLISH_REQUEST, id);
export const unpublishSuccess = (data: any[]) =>
  action(OfferingActionTypes.UNPUBLISH_SUCCESS, data);
export const unpublishError = (message: string) =>
  action(OfferingActionTypes.UNPUBLISH_ERROR, message);

export const updateOfferingRequest = (id: string, patch: any) =>
  action(OfferingActionTypes.UPDATE_OFFERING_REQUEST, { id: id, data: patch });
export const updateOfferingSuccess = (data: any[]) =>
  action(OfferingActionTypes.UPDATE_OFFERING_SUCCESS, data);
export const updateOfferingError = (message: string) =>
  action(OfferingActionTypes.UPDATE_OFFERING_ERROR, message);
