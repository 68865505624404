import { action } from 'typesafe-actions';
import { LPActionTypes } from './types';

export const fetchRequest = () => action(LPActionTypes.FETCH_REQUEST);
export const fetchSuccess = (data: any[]) => action(LPActionTypes.FETCH_SUCCESS, data);
export const fetchImagesRequest = (lpId: any) =>
  action(LPActionTypes.FETCH_LP_IMAGES_REQUEST, lpId);
export const fetchImagesSuccess = (data: any[]) =>
  action(LPActionTypes.FETCH_LP_IMAGES_SUCCESS, data);
export const fetchSuccessSingle = (data: any[]) =>
  action(LPActionTypes.FETCH_SINGLE_LO_SUCCESS, data);
export const fetchError = (message: string) => action(LPActionTypes.FETCH_ERROR, message);
export const dueDiligenceRequest = (id: string) => action(LPActionTypes.DUE_DILIGENCE_REQUEST, id);
export const dueDiligenceSuccess = (data: any[]) =>
  action(LPActionTypes.DUE_DILIGENCE_SUCCESS, data);
export const dueDiligenceError = (message: string) =>
  action(LPActionTypes.DUE_DILIGENCE_ERROR, message);
export const clearSelected = () => action(LPActionTypes.CLEAR_SELECTED);
export const select = (lpId: string) => action(LPActionTypes.SELECT, lpId);
export const selected = (lp: any) => action(LPActionTypes.SELECTED, lp);
