import styled from 'styled-components';

const LoadingInner = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

export default LoadingInner;
