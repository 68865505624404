import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { toBool } from '../useUserProfileReducer';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxTitle: {
      color: theme.colors.white,
      fontWeight: 600,
      marginTop: '10px',
      fontSize: '12pt',
    },
    boxSubTitle: {
      color: theme.colors.white,
      fontWeight: 400,
      fontStyle: 'italic',
      fontSize: '10pt',
    },
    hrLine: {
      marginTop: '20px',
      color: theme.colors.white,
      backgroundColor: theme.colors.white,
      height: 2,
    },
    checkBox: {
      '& svg': {
        fontSize: '1.75em',
      },
    },
  })
);

export const UserPermissionsForm = props => {
  const { user, togglePermission } = props;
  const classes = useStyles(props);
  const disableReports = toBool(user.disableReports);
  const disableUpload = toBool(user.disableUpload);

  const BoxLabel = props => {
    return (
      <span>
        <span className={classes.boxTitle}>{props.title}</span>
        <br />
        <span className={classes.boxSubTitle}>{props.subtitle}</span>
        <br />
      </span>
    );
  };

  return (
    <PermissionsWrapper>
      <hr className={classes.hrLine} />
      <Typography variant="h4">Edit User Permissions</Typography>
      <Typography variant="subtitle1">
        Change the users account status and their ability to download reports or upload files.
      </Typography>
      <Checkboxes>
        <CheckboxGroup>
          <Checkbox
            onChange={() => togglePermission('accountEnabled')}
            checked={!user.accountEnabled}
            className={classes.checkBox}
          />
          <BoxLabel
            title="Disable the Account"
            subtitle="(Clicking this box will disable the user)"
          />
        </CheckboxGroup>
        <CheckboxGroup>
          <Checkbox
            onChange={() => togglePermission('disableReports')}
            checked={disableReports}
            className={classes.checkBox}
          />
          <BoxLabel
            title="Disable Report Download"
            subtitle="(Clicking this box will disable the user from being able to download reports)"
          />
        </CheckboxGroup>
        <CheckboxGroup>
          <Checkbox
            onChange={() => togglePermission('disableUpload')}
            checked={disableUpload}
            className={classes.checkBox}
          />
          <BoxLabel
            title="Disable Upload"
            subtitle="(Clicking this box will disable the user from uploading)"
          />
        </CheckboxGroup>
      </Checkboxes>
    </PermissionsWrapper>
  );
};

const PermissionsWrapper = styled.div`
  margin-top: 1em;
  h4 {
    font-size: 2.75em;
    font-weight: bold;
    margin-top: 2em;
  }
`;

const Checkboxes = styled.div`
  margin-top: 2em;
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1em;
`;
