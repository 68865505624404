import { OrderDirection } from './types';

export const desc = (a, b, orderBy) => {
  let valueA = typeof orderBy === 'function' ? orderBy(a) : a[orderBy];
  let valueB = typeof orderBy === 'function' ? orderBy(b) : b[orderBy];

  // assign empty values an empty string so they are first when sorted ascending
  if (valueA === null || valueA === undefined) {
    valueA = '';
  }
  if (valueB === null || valueB === undefined) {
    valueB = '';
  }

  // uppercase for consistent comparisons
  if (typeof valueA === 'string' && typeof valueB === 'string') {
    valueA = valueA.toUpperCase();
    valueB = valueB.toUpperCase();
  }

  let result = 0;
  if (valueB < valueA) {
    result = -1;
  }
  if (valueB > valueA) {
    result = 1;
  }
  return result;
};

export function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const getSorting = (order: OrderDirection, orderBy: string | number | symbol | Function) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

export const getRestrictions = ({ WhitelistedFinancialEntities, BlacklistedFinancialEntities }) => {
  if (WhitelistedFinancialEntities) return 'Whitelist';
  if (BlacklistedFinancialEntities) return 'Blacklist';
  return 'None';
};
