import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { DownloadActionTypes } from './types';
import {
  fetchError,
  fetchDownloadError,
  fetchDownloadRequest,
  fetchDownloadSuccess,
  fetchRequest,
  fetchSuccess,
} from './actions';
import { callApi } from '../../utils/api';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

// documentcatalog/downloads/mycu

function* handleFetch(action: ReturnType<typeof fetchRequest>) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/documentcatalog/downloads/mycu?${action.payload}`
    );
    yield res.error ? put(fetchError(res.error)) : all([put(fetchSuccess(res))]);
  } catch (err) {
    yield err instanceof Error
      ? put(fetchError(err.stack!))
      : put(fetchError('An unknown error occured.'));
  }
}

function* handleDownloadFetch(action: ReturnType<typeof fetchDownloadRequest>) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/documentcatalog/downloads/mycu?${action.payload}`
    );
    yield res.error ? put(fetchDownloadError(res.error)) : all([put(fetchDownloadSuccess(res))]);
  } catch (err) {
    yield err instanceof Error
      ? put(fetchDownloadError(err.stack!))
      : put(fetchDownloadError('An unknown error occured.'));
  }
}

function* watchFetchRequest() {
  yield takeEvery(DownloadActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchDownloadFetchRequest() {
  yield takeEvery(DownloadActionTypes.FETCH_DOWNLOAD_REQUEST, handleDownloadFetch);
}

function* downloadSaga() {
  yield all([fork(watchFetchRequest), fork(watchDownloadFetchRequest)]);
}

export { downloadSaga as DownloadsSaga };
