import Typography, { TypographyOptions } from 'typography';

const theme: TypographyOptions = {
  googleFonts: [
    {
      name: 'Montserrat',
      styles: ['400', '500', '600', '700', '900'],
    },
  ],
  baseFontSize: '14px',
  baseLineHeight: 1.5,
  headerFontFamily: [
    'Montserrat',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Helvetica',
    'Arial',
    'Roboto',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ],
  bodyFontFamily: [
    'Montserrat',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ],
  scaleRatio: 1.75,
  headerWeight: 700,
  bodyWeight: 'normal',
  boldWeight: 600,
};

const typography = new Typography(theme);

// Export helper functions
export const { scale, rhythm, options } = typography;
export default typography;

// export const typographyCss
