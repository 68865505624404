import {
  FormControlLabelProps,
  SortDirection,
  Switch as MuiSwitch,
  TablePaginationProps,
} from '@material-ui/core';
import { AxiosError, AxiosPromise, AxiosRequestConfig } from 'axios';
import { RefetchOptions } from 'axios-hooks';
import { TableDataEntry, TableProps } from 'components/Table/Table';
import useAxios from 'hooks/useAxios';
import useQuery from 'hooks/useQuery';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import shortid from 'shortid';
import { TransformResponse } from 'utils/api';
import { createSort, rowsPerPageOptions } from './helpers';
import _ from 'lodash';
import { FilteredColumn } from './components';

/**
 * @param orderBy - string
 * @param setOrderBy - Dispatch<React.SetStateAction<string>>
 * @param order - SortDirection
 * @param setOrder - Dispatch<React.SetStateAction<SortDirection>>
 * @param size - string
 * @param setSize - Dispatch<SetStateAction<string>>
 * @param rowsPerPage - number
 * @param setRowsPerPage - Dispatch<SetStateAction<number>>
 * @param page - number
 * @param setPage - Dispatch<React.SetStateAction<number>>
 * @param query - string
 * @param requestConfig - AxiosRequestConfig
 * @param loading - boolean
 * @param fetchedData - any
 * @param error - AxiosError<any>
 * @param refresh - (config?: AxiosRequestConfig, options?: RefetchOptions) => AxiosPromise<any>
 * @param handleChangePage - (event: unknown, newPage: number) => void
 * @param handleChangeRowsPerPage - (event: React.ChangeEvent<HTMLInputElement>) => void
 * @param handleRequestSort - (event: React.MouseEvent<unknown>, property: any) => void
 * @param createSortHandler - (property: string | number | symbol) => (event) => void
 * @param createFilterHandler - (property: FilteredColumns[]) => () => void
 * @param handleTableSize - () => void
 * @param selected - any
 * @param setSelected - Dispatch<any>
 * @param emptyRows - number
 * @param createdData - unknown[]
 */
export type UseTableResponse = TableProps & {
  columns: number;
  orderBy: string;
  setOrderBy: Dispatch<React.SetStateAction<string>>;
  order: SortDirection;
  setOrder: Dispatch<React.SetStateAction<SortDirection>>;
  size: string;
  setSize: Dispatch<SetStateAction<string>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  page: number;
  setPage: Dispatch<React.SetStateAction<number>>;
  query: string;
  requestConfig: AxiosRequestConfig;
  loading: boolean;
  fetchedData: any;
  error: AxiosError<any>;
  refresh: (config?: AxiosRequestConfig, options?: RefetchOptions) => AxiosPromise<any>;
  createSortHandler: (
    property: string | number | symbol
  ) => (event: React.MouseEvent<unknown, MouseEvent>) => void;
  createFilterHandler: Dispatch<any>;
  handleTableSize: () => void;
  selected: any;
  setSelected: Dispatch<any>;
  emptyRows: number;
  createdData: unknown[];
  count: number;
  empty: boolean;
  refreshTable: (config?: AxiosRequestConfig) => void;
  toggleTableSizeProps: FormControlLabelProps;
  tablePaginationProps: TablePaginationProps;
};

export type UseTable = (props: TableProps) => UseTableResponse;

const useTable: UseTable = props => {
  const { endpoint, tableData, order: initialOrder, orderBy: initialOrderBy } = props;

  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [order, setOrder] = useState(initialOrder);
  const [filteredColumns, setFilteredColumns] = useState<FilteredColumn[]>([]);
  const [size, setSize] = useLocalStorage('table-size', 'small');
  const [rowsPerPage, setRowsPerPage] = useLocalStorage('table-pagination', rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(null);

  let query = useQuery(props.search) || '';

  let requestConfig = props.requestConfig ?? {
    url: endpoint,
    method: 'GET',
    params: { $top: 50000, ...props?.oData },
    transformResponse: TransformResponse(props?.transformResponse),
  };

  const [{ loading, error, data: fetchedData, response }, refresh] = useAxios(requestConfig, {
    useCache: props.useCache,
    manual: props.manual,
  });

  useEffect(() => {
    if (loading) setSelected(null);
    return () => {};
  }, [loading, selected]);

  const refreshTable = (config = response.config) => {
    refresh(config);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const handleRequestFilter = (property: FilteredColumn[]) => {
    setFilteredColumns(property);
  };

  const createFilterHandler = (property: FilteredColumn[]) => {
    handleRequestFilter(property);
  };

  const handleTableSize = () => {
    if (size === 'small') setSize('medium');
    if (size === 'medium') setSize('small');
  };

  const rows = new TableEntries(
    loading,
    fetchedData,
    tableData,
    order,
    orderBy,
    query,
    filteredColumns,
    page,
    rowsPerPage
  );

  const data = rows
    .filterData()
    .searchData()
    .orderData()
    .paginateData().entries;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data?.length - page * rowsPerPage);
  const columns = props.selectable ? tableData.length + 1 : tableData.length;
  const count = rows.count;

  const toggleTableSizeProps: FormControlLabelProps = {
    color: 'primary',
    control: <MuiSwitch checked={size === 'small'} onChange={handleTableSize} />,
    label: 'Dense padding',
  };

  const tablePaginationProps = {
    component: 'div',
    count,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
  };

  return {
    ...props,
    count,
    empty: !loading && !error && count === 0,
    columns,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    size,
    setSize,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    query,
    requestConfig,
    loading,
    fetchedData,
    error,
    refresh,
    createSortHandler,
    createFilterHandler,
    handleTableSize,
    selected,
    setSelected,
    emptyRows,
    createdData: data,
    toggleTableSizeProps,
    tablePaginationProps,
    refreshTable,
  };
};

export default useTable;
class TableEntries {
  loading: boolean;
  entries: any;
  tableData: TableDataEntry<any>[];
  order: SortDirection;
  orderBy: string;
  query: string;
  filteredColumns?: FilteredColumn[];
  page: number;
  rowsPerPage: number;
  _data: any;
  initialEntries: any[];
  count: any;
  constructor(
    loading: boolean,
    data: any,
    tableData: TableDataEntry[],
    order: SortDirection,
    orderBy: string,
    query: string,
    filteredColumns: FilteredColumn[],
    page: number,
    rowsPerPage: number
  ) {
    this.loading = loading;
    this.initialEntries = loading ? [] : formatRows(data, tableData);
    this.entries = this.initialEntries;
    this.count = this.entries.length;
    this._data = data;
    this.tableData = tableData;
    this.order = order;
    this.orderBy = orderBy;
    this.query = query;
    this.filteredColumns = filteredColumns;
    this.page = page;
    this.rowsPerPage = rowsPerPage;
  }

  getData() {
    return this;
  }

  filterData(filteredColumns = this.filteredColumns) {
    const filtered = this.entries.filter(entry => {
      var passesFilter: boolean = true;

      filteredColumns.forEach(filteredColumn => {
        if (
          filteredColumn.text &&
          !(entry[filteredColumn.field] as string)
            .toLowerCase()
            .includes((filteredColumn.text as string).toLowerCase())
        ) {
          passesFilter = false;
        }
      });

      return passesFilter;
    });

    this.count = filtered.length;
    this.entries = filtered;

    return this;
  }

  searchData(str = this.query) {
    const searched = this.entries.filter(entry => {
      switch (createSort(str)) {
        case '':
          return entry;
        case null:
          return entry;
        default:
          const hits = entry.cells.filter(cell =>
            [cell.value, cell.display, cell.sort]
              .flat()
              .toString()
              .includes(createSort(str))
          );

          return hits.length > 0 ? true : false;
      }
    });

    this.count = searched.length;
    this.entries = searched;

    return this;
  }

  paginateData(page = this.page, rowsPerPage = this.rowsPerPage) {
    const rows = this.entries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    this.entries = rows;
    return this;
  }

  orderData(orderBy = this.orderBy, order = this.order) {
    const ordered = _.orderBy(this.entries, row => createSort(row[orderBy]), order);
    this.entries = ordered;
    return this;
  }
}

function formatRows(data: unknown[], tableData: TableDataEntry[]) {
  let rows = [];
  data?.forEach((r, rowIndex) => {
    let cells = [];
    let tRow = {
      id: shortid(),
      number: rowIndex + 1,
      row: r,
      cells,
    };

    tableData.forEach(cell => {
      const value = cellValue('value', cell, r);
      const display = cell.display ? cellValue('display', cell, r) : value;
      const sort = createSort(value);

      cells.push({
        value,
        display,
        sort,
      });

      if (cell.field) tRow[cell.field] = value;
    });

    rows.push(tRow);
  });

  return rows;
}

function cellValue(key, cell: TableDataEntry, row: Object, emptyValue = 'empty') {
  switch (typeof cell[key]) {
    case 'function':
      return cell[key](row);
    case null:
      return emptyValue;
    case undefined:
      return emptyValue;
    case 'boolean':
      return JSON.stringify(cell[key]);
    default:
      return cell[key];
  }
}
