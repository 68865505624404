import { createStyles, makeStyles, Table, Theme } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import React, { Fragment } from 'react';
import Loading from '../../../components/data/Loading';
import LoadingInner from '../../../components/data/LoadingInner';
import LoadingSpinner from '../../../components/data/LoadingSpinner';
import { LPTableHead } from './LPTableHead';
import { LPTableRow } from './LPTableRow';
import { getSorting, stableSort } from './sortUtils';
import { useTable } from './table-context';
import { LPTableInnerProps, Order } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 200,
      marginTop: theme.spacing(3),
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    loadingIndicator: {
      position: 'absolute',
      top: '30%',
    },
    message: {
      height: 125,
      backgroundColor: 'transparent !important',
      '& td': {
        textAlign: 'center',
        border: 'none',
        boxShadow: 'none !important',
      },
    },
  })
);

export const LPTableInner: React.FC<LPTableInnerProps> = props => {
  const { headers, onFilter, onSort, selectable = false, keyIdentifier } = props;
  const [table] = useTable();
  let {
    filter: filterState,
    order: orderState,
    tableSize,
    data,
    newDataIndices,
    errors,
    filterLoading,
    showAllLoading,
  } = table;
  const classes = useStyles(props);
  const [handleFilter, handleFilterDebounced] = onFilter;

  const filter = (array: any[], field: Order['field']) => {
    return array.filter((obj: any) => {
      if (!filterState.text) {
        return true;
      }
      const value = typeof field === 'function' ? field(obj) : obj[field];

      //Fuzzy search for LP Number column
      if (filterState.serverField === 'LPNumber') {
        return (
          value &&
          value
            .toString()
            .toLowerCase()
            .includes(filterState.text.toLowerCase())
        );
      }

      return (
        value &&
        value
          .toString()
          .toLowerCase()
          .startsWith(filterState.text.toLowerCase())
      );
    });
  };

  const sortedAndFiltered = () => {
    if (!orderState.serverField) {
      if (filterState.serverField && filterState.text) {
        return filter(data, filterState.field);
      }
      return data;
    }

    const sorted = stableSort(data, getSorting(orderState.direction, orderState.serverField));
    if (!filterState.serverField || !filterState.text) {
      return sorted;
    }
    const filtered = filter(sorted, filterState.field);
    return filtered;
  };

  filterLoading = filterLoading || (!data && !errors);

  const TableBodyContents = () => {
    if (!filterLoading && !showAllLoading) {
      return sortedAndFiltered().map((row, i) => {
        const isNew = newDataIndices[i];
        return <LPTableRow key={`${row[keyIdentifier]}-${i}`} row={row} {...props} isNew={isNew} />;
      });
    }
    return null;
  };

  const TableBodyMessages = () => {
    if (!filterLoading && !showAllLoading) {
      if (data.length === 0) {
        if (errors) {
          return <TableBodyMessage message={'There was an error in processing the request.'} />;
        } else {
          return <TableBodyMessage message={'No records available.'} />;
        }
      }
    }
    return null;
  };

  const TableBodyMessage = ({ message }) => {
    return (
      <tr className={classes.message}>
        <td colSpan={headers.length}>{message}</td>
      </tr>
    );
  };

  return (
    <Fragment>
      <Table className={classes.root} size={tableSize}>
        <LPTableHead
          headers={headers}
          onRequestSort={onSort}
          onFilter={(e, field, reset) => {
            if (e) {
              e.persist();
            }
            return reset ? handleFilter(e, field, reset) : handleFilterDebounced(e, field, reset);
          }}
          selectable={selectable}
        />
        <TableBody>
          <TableBodyContents />

          <TableBodyMessages />
        </TableBody>
      </Table>
      {(filterLoading || showAllLoading) && (
        <Loading className={classes.loadingIndicator}>
          <LoadingInner>
            <LoadingSpinner />
          </LoadingInner>
        </Loading>
      )}
    </Fragment>
  );
};
