import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { colors } from 'utils/theme';
import { UserContext } from './data/context';
import { post } from '../utils/api';
import { LPButton } from './Common/LPButton';
import { LPSnackBar } from './Common/LPSnackBar';

const LPTextField = styled('input')`
  display: block;
  background-color: ${colors.white};
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #6192d1;

  border-width: 2px;
  border-color: transparent;
  border-style: inset;

  outline: 0;
`;

const LPTextArea = styled('textarea')`
  display: block;
  background-color: ${colors.white};
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #6192d1;
  min-width: 215px;
  min-height: 169px;

  border-width: 2px;
  border-color: transparent;
  border-style: inset;

  outline: 0;
`;

interface ContactFormProps {
  maxWidth?: number;
  initialFormState?: ContactFormValues;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactForm: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: (props: ContactFormProps) => (props.maxWidth ? props.maxWidth + 'px' : '100%'),
      position: 'relative',
      '& button': {
        marginTop: 40,
        alignSelf: 'center',
        fontWeight: 600,
        boxShadow: '0 3px 10px 0 rgba(111, 11, 19, 0.62)',
      },
    },
    formFields: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      flexWrap: 'wrap',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto',
      },
    },
  })
);

export interface ContactFormValues {
  FirstName: string;
  LastName: string;
  Company: string;
  FromEmailAddress: string;
  PhoneNumber: string;
  Message: string;
}

export interface HiddenContactFormValues {
  UserObjectId: string;
  RelatedObjectType: string;
  RelatedObject: string;
  MessageSourceUrl: string;
}

export const ContactForm = props => {
  const { initialFormState } = props;
  const initialState = {
    FirstName: '',
    LastName: '',
    Company: '',
    FromEmailAddress: '',
    PhoneNumber: '',
    Message: '',
  };
  const classes = useStyles(props);
  const user = useContext(UserContext);
  const [values, setValues] = useState<ContactFormValues>(initialFormState || initialState);
  const initialPostRequestState = {
    loading: false,
    errors: false,
    success: false,
    message: '',
  };
  const hiddenValues: HiddenContactFormValues = {
    UserObjectId: user.id,
    RelatedObjectType: 'Comment',
    RelatedObject: '',
    MessageSourceUrl: window.location.href,
  };

  const [postRequest, setPostRequest] = useState(initialPostRequestState);

  const handleChange = (name: keyof React.ComponentState) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = async e => {
    // TODO: dispatch api request to POST endpoint
    // notify user of error/success
    e.preventDefault();
    setPostRequest({ ...initialPostRequestState, loading: true });
    const data = {
      ...values,
      ...hiddenValues,
    };
    try {
      await post('/notification/comments', data).then(() => {
        setPostRequest({
          ...postRequest,
          loading: false,
          success: true,
          message: 'Submitted successfully. We will be in touch as soon as possible.',
        });
        setValues(initialState);
      });
    } catch (e) {
      setPostRequest({
        ...postRequest,
        loading: false,
        errors: true,
        message: 'Error: ' + e.response.status + ' ' + e.response.statusText,
      });
      setValues(initialState);
    }
  };

  return (
    <form className={clsx(classes.contactForm, 'lPContactForm')} onSubmit={handleSubmit}>
      <div className={classes.formFields}>
        <div>
          <LPTextField
            type="text"
            id="first-name"
            placeholder="First Name"
            value={values.FirstName}
            onChange={handleChange('FirstName')}
          />
          <LPTextField
            type="text"
            id="last-name"
            placeholder="Last Name"
            value={values.LastName}
            onChange={handleChange('LastName')}
          />
          <LPTextField
            type="text"
            id="company"
            placeholder="I work at"
            value={values.Company}
            onChange={handleChange('Company')}
          />
          <LPTextField
            type="email"
            required
            id="email"
            placeholder="Email"
            value={values.FromEmailAddress}
            onChange={handleChange('FromEmailAddress')}
          />
        </div>
        <div>
          <LPTextField
            type="text"
            id="phonenumber"
            placeholder="Phone # (if you prefer a call)"
            value={values.PhoneNumber}
            onChange={handleChange('PhoneNumber')}
          />
          <LPTextArea
            id="message"
            placeholder="How can we help you?"
            value={values.Message}
            onChange={handleChange('Message')}
          />
        </div>
      </div>
      <LPButton
        color="primary"
        type="submit"
        variant="contained"
        style={{ padding: '5px 50px' }}
        loading={postRequest.loading}
      >
        SEND
      </LPButton>
      <LPSnackBar
        isOpen={postRequest.errors || postRequest.success}
        type={postRequest.errors ? 'error' : 'success'}
        message={postRequest.message}
      />
    </form>
  );
};
