/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Main, Section, Div } from './styles';
import { apiUrl } from '../../utils/api';
import axios from 'axios';
import LPTitle from '../../components/Common/LPTitle';
import { getFormattedDate } from '../../utils/helpers';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core';
import LpList from './LpList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LoadingSpinner from '../../components/data/LoadingSpinner';
import { v4 as uuid } from 'uuid';

interface Props {}
interface ApiReport {
  itemUniqueId: string;
  itemName: string;
  contentType: string;
  parentContentType: string;
  section?: null;
  exists: boolean;
  isTermSheet: boolean;
  version: number;
  views?: string[] | null;
  tags?: string[] | null;
  restrictions?: string[] | null;
  library: string;
  attributes: Attributes;
  path: string;
  relatedLP: string;
  cuFile?: null;
  dateReceived: string;
}
interface Attributes {
  LoanPool: string;
  SellerRTNumber: string;
  BuyerRTNumber: string;
}

interface Header<T> {
  keyOrGetter: (row: T) => string | string;
  label: string;
  serverField?: string;
  path?: Function;
  filterDisabled?: boolean;
}

export interface ReportLpFile {
  id: string;
  lpNumber: string;
  filename: string;
  date: string;
  path: string;
}

export interface ReportLp {
  lpNumber: string;
  files: ReportLpFile[];
}

interface Report {
  name: string;
  lps: ReportLp[];
}

const createReports = (items: ApiReport[]): Report[] => {
  const headings = [...new Set(items.map(item => item.section))];
  const lpNumbers = [...new Set(items.map(item => item.relatedLP))];

  const sections = headings.map(heading => ({
    name: heading ? heading : 'Misc',
    lps: lpNumbers.map(lpNumber => ({
      lpNumber,
      files: items
        .filter(
          item =>
            (heading === item.section || (!heading && !item.section)) && lpNumber === item.relatedLP
        )
        .map(lp => ({
          id: lp.itemUniqueId,
          lpNumber: lp.relatedLP,
          filename: lp.path.replace(/^.*[\\/]/, ''),
          date: getFormattedDate(lp.dateReceived),
          path: `${apiUrl}api/documentcatalog/offering/${lp.relatedLP}/reports/${lp.itemUniqueId}`,
        })),
    })),
  }));

  return sections;
};

const ReportsPage: React.FC<Props> = () => {
  const url = `${apiUrl}api/documentcatalog/offering/reports`;
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let mounted = true;
    const source = axios.CancelToken.source();
    axios
      .get(url, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        if (mounted) {
          setLoading(false);
          setReports(createReports(data));
        }
      })
      .catch(e => {
        if (mounted) {
          if (axios.isCancel(e)) {
            setError(e.message);
            console.debug(`request cancelled:${e.message}`);
          } else {
            setError(e.message);
            console.error('another error happened:' + e.message);
          }
        }
      });
    return () => {
      source.cancel();
      mounted = false;
    };
  }, []);

  if (loading) return <LoadingSpinner />;
  return (
    <Main>
      <div>
        <LPTitle>Reports Page</LPTitle>
        <p>{error}</p>
      </div>

      {reports.length > 0 ? (
        reports.map(({ name, lps }) => (
          <Div key={uuid()}>
            <Section>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{name}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <LpList lps={lps} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Section>
          </Div>
        ))
      ) : (
        <Typography>There are no reports</Typography>
      )}
    </Main>
  );
};

export default ReportsPage;
