import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AuthActionTypes } from './types';
import {
  fetchError,
  fetchSuccess,
  userSelectSuccess,
  userSelectError,
  userSelectRequest,
  userNotificationsSuccess,
  userNotificationUpdateSuccess,
  userNotificationsRequest,
  userNotificationUpdateRequest,
} from './actions';
import { callApi } from '../../utils/api';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

function* handleFetch() {
  try {
    let res = yield call(callApi, 'get', API_ENDPOINT, '/auth');
    // Get from auth user
    const userObjectId = res.UserObjectId;

    let nextActions: any[] = [put(fetchSuccess(res))];

    if (userObjectId) {
      nextActions.push(put(userSelectRequest(userObjectId)));
    }

    yield res.error ? put(fetchError(res.error)) : all(nextActions);
  } catch (err) {
    yield err instanceof Error
      ? put(fetchError(err.stack!))
      : put(fetchError('An unknown error occured.'));
  }
}

function* handleUserSelect(action: ReturnType<typeof userSelectRequest>) {
  try {
    const res = yield call(callApi, 'get', API_ENDPOINT, `/usermanagement/users/${action.payload}`);

    yield res.error ? put(userSelectError(res.error)) : put(userSelectSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(userSelectError(err.stack!))
      : put(userSelectError('An unknown error occured.'));
  }
}

function* handleUserNotificationRequest(action: ReturnType<typeof userNotificationsRequest>) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/notification/preferences/${action.payload}`
    );

    yield res.error ? put(userSelectError(res.error)) : put(userNotificationsSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(userSelectError(err.stack!))
      : put(userSelectError('An unknown error occured.'));
  }
}

function* handleUserNotificationUpdateRequest(
  action: ReturnType<typeof userNotificationUpdateRequest>
) {
  try {
    const res = yield call(
      callApi,
      'put',
      API_ENDPOINT,
      `/notification/preferences/${action.payload.userId}`,
      action.payload.notification
    );

    yield res.error ? put(userSelectError(res.error)) : put(userNotificationUpdateSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(userSelectError(err.stack!))
      : put(userSelectError('An unknown error occured.'));
  }
}

function* watchFetchRequest() {
  yield takeEvery(AuthActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchUserSelectRequest() {
  yield takeEvery(AuthActionTypes.USER_SELECT_REQUEST, handleUserSelect);
}

function* watchUserNotificationRequest() {
  yield takeEvery(AuthActionTypes.USER_NOTIFICAITON_REQUEST, handleUserNotificationRequest);
}

function* watchUserNotificationUpdateRequest() {
  yield takeEvery(
    AuthActionTypes.USER_NOTIFICAITON_UPDATE_REQUEST,
    handleUserNotificationUpdateRequest
  );
}

function* authSaga() {
  yield all([
    fork(watchFetchRequest),
    fork(watchUserSelectRequest),
    fork(watchUserNotificationRequest),
    fork(watchUserNotificationUpdateRequest),
  ]);
}

export { authSaga as AuthSaga };
