import { Reducer } from 'redux';
import { DocusignState, DocusignActionTypes, DocusignCAStatus } from './types';

export const initialState: DocusignState = {
  data: undefined,
  errors: undefined,
  loading: false,
  syncing: false,
  envelopeId: undefined,
  viewUrl: undefined,
  caStatus: undefined,
  newestCAStatus: undefined,
  rejectLoading: false,
  rejected: false,
};

export const DocusignReducer: Reducer<DocusignState> = (state = initialState, action) => {
  switch (action.type) {
    case DocusignActionTypes.DOCUSIGN_CREATE_CA_REQUEST:
      return { ...state, loading: true };
    case DocusignActionTypes.DOCUSIGN_CREATE_CA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        envelopeId: action.payload.envelope.envelopeId,
        viewUrl: action.payload.view,
      };
    case DocusignActionTypes.DOCUSIGN_CREATE_CA_ERROR:
      return { ...state, loading: false, errors: action.payload };
    case DocusignActionTypes.DOCUSIGN_SYNC_REQUEST:
      return { ...state, syncing: true };
    case DocusignActionTypes.DOCUSIGN_SYNC_SUCCESS:
      return {
        ...state,
        syncing: false,
        envelopeId: undefined,
        viewUrl: undefined,
      };
    case DocusignActionTypes.DOCUSIGN_SYNC_ERROR:
      return {
        ...state,
        syncing: false,
        envelopeId: undefined,
        viewUrl: undefined,
        errors: action.payload,
      };
    case DocusignActionTypes.DOCUSIGN_LATEST_CA_STATUS_REQUEST:
      return { ...state, loading: true };
    case DocusignActionTypes.DOCUSIGN_LATEST_CA_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        caStatus: action.payload,
        newestCAStatus: getNewestCAStatus(action.payload),
      };
    case DocusignActionTypes.DOCUSIGN_LATEST_CA_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        caStatus: undefined,
        errors: action.payload,
      };
    case DocusignActionTypes.CLEAR_BULK_CA_STATUS:
      return {
        ...state,
        caStatus: undefined,
        newestCAStatus: undefined,
      };
    case DocusignActionTypes.DOCUSIGN_REJECT_CA_REQUEST:
      return { ...state, loading: true, rejectLoading: true };
    case DocusignActionTypes.DOCUSIGN_REJECT_CA_SUCCESS:
      return {
        ...state,
        loading: false,
        envelopeId: undefined,
        viewUrl: undefined,
        rejectLoading: false,
        rejected: true,
      };
    case DocusignActionTypes.DOCUSIGN_REJECT_CA_ERROR:
      return {
        ...state,
        loading: false,
        envelopeId: undefined,
        viewUrl: undefined,
        errors: action.payload,
      };
    default:
      return state;
  }
};

const getNewestCAStatus = (caStatus: DocusignCAStatus[]) => {
  let documents = caStatus.filter(c => c.status === 'completed' && !c.rejected);

  if (documents.length === 0) {
    return undefined;
  } else {
    return documents[0];
  }
};
