import { Reducer } from 'redux';
import { AuthState, AuthActionTypes } from './types';

export const initialState: AuthState = {
  data: undefined,
  errors: undefined,
  loading: false,
  user: undefined,
  notification: undefined,
};

export const AuthReducer: Reducer<AuthState> = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.FETCH_REQUEST:
      return { ...state, loading: true };
    case AuthActionTypes.FETCH_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case AuthActionTypes.FETCH_ERROR:
      return { ...state, loading: false, errors: action.payload };
    case AuthActionTypes.USER_SELECT_REQUEST:
      return { ...state, loading: true };
    case AuthActionTypes.USER_SELECT_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case AuthActionTypes.USER_SELECT_ERROR:
      return { ...state, loading: false, errors: action.payload };
    case AuthActionTypes.CLEAR_USER:
      return { ...state, user: undefined };
    case AuthActionTypes.USER_NOTIFICAITON_UPDATE_REQUEST:
      return { ...state, loading: true };
    case AuthActionTypes.USER_NOTIFICAITON_UPDATE_SUCCESS:
      return { ...state, loading: false };
    case AuthActionTypes.USER_NOTIFICAITON_SUCCESS:
      return { ...state, loading: false, notification: action.payload };
    case AuthActionTypes.CLEAR_NOTIFICATION:
      return { ...state, notification: undefined };
    default:
      return state;
  }
};
