import { Size } from '@material-ui/core/Table';
import React from 'react';

export interface Header {
  keyOrGetter: string | Function;
  label: string;
  serverField?: string;
  path?: Function;
  filterDisabled?: boolean;
}

export interface EnhancedTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, header: Header) => void;
  headers: Header[];
  onFilter: Function;
  selectable: boolean;
}

export interface LPTableProps {
  endpoint: string;
  headers: Header[];
  detailHeaders?: Header[];
  supplementalViewEndpoint?: string;
  supplementalViewHeaders?: Header[];
  persistentFilterParams?: string[][];
  size?: Size;
  defaultOrdering?: Order;
  loadAll?: boolean;
  selectable?: boolean;
  selected?: any[];
  keyIdentifier: string;
  handleSelect?: (event: React.ChangeEvent, add: boolean, id) => void;
  maxHeight?: string;
  width?: string;
  metaHeader?: () => React.ReactNode;
  tableName?: string;
}

export interface Filter {
  text: string;
  field: string | Function;
  serverField?: string;
  triggerRequest?: boolean;
}

export const initialFilterState = {
  text: '',
  field: undefined,
  serverField: '',
  triggerRequest: false,
  cleared: false,
};

export interface Order {
  direction?: OrderDirection;
  field: string | Function;
  serverField?: string; // field name used in odata query. leaving blank will result in client-side sort
  label?: string;
}

export interface TableWrapperProps {
  tableMaxHeight: string;
  tableWidth: string;
}

export interface LPTableInnerProps extends LPTableProps {
  onFilter: Function[];
  onSort: (event: React.MouseEvent, header: Header) => void;
}

export type OrderDirection = 'asc' | 'desc';

export const INITIAL_LIMIT = 20;
export const SUBSEQUENT_LIMIT = 20;
export const SUPER_LARGE_LIMIT = 1000000;
