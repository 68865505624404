import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  IconButton,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp } from '@material-ui/icons';
import { QuestionsWidget } from 'components/Common/QuestionsWidget';
import FileDownloadButton from 'components/FileDownloadButton';
import { orderBy } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApplicationState } from 'store';
import { dueDiligenceRequest } from 'store/lps/actions';
import { LPState } from 'store/lps/types';
import { colors } from 'utils/theme';
import { v4 as uuid } from 'uuid';

const groupBy: (arr: object[], keyGetter: Function) => Map<string, object[]> = (arr, keyGetter) => {
  const map = new Map();
  arr.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

interface DDDocument {
  itemUniqueId: string;
  path: string;
}

const DueDiligenceDocuments = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [expanded, setExpanded] = React.useState<number | boolean>(false);
  const lp = useSelector<ApplicationState, LPState>(state => state.lp);
  const { id: lpNumber } = useParams();

  useEffect(() => {
    if (!lp.loading && !lp.errors && !lp.files) {
      dispatch(dueDiligenceRequest(lpNumber));
    }
  }, [dispatch, lp.errors, lp.files, lp.loading, lpNumber]);

  if (!lp.files) {
    return <CircularProgress size={24} className={classes.buttonProgress} />;
  }

  const grouped = groupBy(lp.files, file => file.section);

  const createSections = () => {
    let items = Array.from(grouped.keys()).filter(s => s);
    let count = items.length;

    const newArr = items.map(str => {
      let order = count;
      switch (str) {
        case 'Seller Financials': // Seller Financials
          order = 0;
          break;
        case 'Policies and Procedures': // Policies & Procedures
          order = 1;
          break;
        case 'Loan Servicing Data': // Loan Servicing Information
          order = 2;
          break;
        case 'Pool Details': // Loan Pool Data
          order = 3;
          break;
        case 'Loan Files': // Loan Files
          order = 4;
          break;
        case 'LP Agreement': // Participation Agreement
          order = 5;
          break;

        default:
          order = order + 1;
      }

      return { label: str, order };
    });

    return orderBy(newArr, o => o.order, 'asc');
  };

  const sections = createSections();

  const handleChange = (i: number) => (event, expanded: boolean) => {
    setExpanded(expanded ? i : false);
    setPage(0);
  };

  const handleClick = (e, i: number) => {
    setExpanded(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const labelDisplayedRows = ({ from, to, count }) => `${from}-${to} of ${count}`;

  return (
    <Fragment>
      <QuestionsWidget />
      <div>
        {sections.map(({ label }, i) => {
          const count = grouped.get(label).length;

          return (
            <ExpansionPanel
              square
              expanded={expanded === i}
              onChange={handleChange(i)}
              key={uuid()}
            >
              <ExpansionPanelSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                className={classes.expansionPanel}
              >
                <Typography style={{ fontSize: 18, color: '#444' }}>{label}</Typography>
                <Button>{expanded === i ? 'HIDE' : 'VIEW'} FILES</Button>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                <Table>
                  <TableBody>
                    {grouped
                      .get(label)
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((f: DDDocument, i) => (
                        <TableRow key={uuid()}>
                          <TableCell>
                            <Typography variant="h6" component="p">
                              {f.path.substr(f.path.lastIndexOf('/') + 1)}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <FileDownloadButton
                              downloadingLabel="loan file is being prepared for download"
                              url={`/documentcatalog/offering/${lpNumber}/files/${f.itemUniqueId}`}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <div className={classes.tablePagination}>
                  <IconButton
                    className={classes.firstOrLast}
                    onClick={e => handleChangePage(e, 0)}
                    disabled={page === 0 || count < rowsPerPage}
                    color="inherit"
                    aria-label="previous page"
                  >
                    <KeyboardArrowLeft />
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton
                    onClick={e => handleChangePage(e, page - 1)}
                    disabled={page === 0}
                    color="inherit"
                    aria-label="previous page"
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                  <Typography variant="body2">
                    {labelDisplayedRows({
                      from: grouped.get(label).length === 0 ? 0 : page * rowsPerPage + 1,
                      to: Math.min(grouped.get(label).length, (page + 1) * rowsPerPage),
                      count: grouped.get(label).length,
                    })}
                  </Typography>
                  <IconButton
                    onClick={e => handleChangePage(e, page + 1)}
                    disabled={page >= Math.ceil(grouped.get(label).length / rowsPerPage) - 1}
                    color="inherit"
                    aria-label="next page"
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                  <IconButton
                    className={classes.firstOrLast}
                    onClick={e => handleChangePage(e, Math.ceil(count / rowsPerPage) - 1)}
                    disabled={page === Math.ceil(count / rowsPerPage) - 1 || count < rowsPerPage}
                    color="inherit"
                    aria-label="previous page"
                  >
                    <KeyboardArrowRight />
                    <KeyboardArrowRight />
                  </IconButton>
                </div>
              </ExpansionPanelDetails>
              <div className={classes.footer}>
                <IconButton onClick={e => handleClick(e, i)} color="inherit">
                  <KeyboardArrowUp />
                </IconButton>
              </div>
            </ExpansionPanel>
          );
        })}
      </div>
    </Fragment>
  );
};

export default DueDiligenceDocuments;

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    marginTop: 40,
    marginBottom: 40,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      height: 'unset',
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 45,
    '&$expanded': {
      height: 'unset',
      minHeight: 45,
    },
  },
  content: {
    color: colors.navyBlue,
    '& p': {
      fontSize: 18,
      fontWeight: 600,
      marginTop: 10,
    },
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expansionPanel: {
      backgroundColor: theme.colors.white,
      display: 'flex',
      '& button': {
        marginLeft: 'auto',
        fontSize: 18,
        color: theme.colors.navyBlue,
        '&:hover': {
          backgroundColor: theme.colors.navyBlue,
          color: theme.colors.white,
        },
      },
    },
    buttonProgress: {
      color: theme.colors.lipstick,
      position: 'absolute',
      top: '50%',
      left: '54%',
      marginTop: -12,
      marginLeft: -12,
    },
    tableHeader: {
      backgroundColor: theme.colors.navyBlue,
      '& th': {
        fontSize: 16,
        color: theme.colors.white,
      },
    },
    expansionPanelDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '95%',
      [theme.breakpoints.only('xs')]: {
        width: '100%',
        '& td': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      margin: '0 auto',
    },
    tablePagination: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 15,
    },
    footer: {
      borderTop: `1px solid rgba(0, 0, 0, 0.125)`,
      borderBottom: `1px solid rgba(0, 0, 0, 0.125)`,
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      '& svg': {
        opacity: 0.3,
      },
    },
    firstOrLast: {
      padding: 0,
      '& svg': {
        '&:last-child': {
          marginLeft: -15,
        },
      },
    },
  })
);
