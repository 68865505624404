import { Grid, List, ListItem } from '@material-ui/core';
import useUserInfo from 'hooks/useUserInfo';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Dollar } from 'utils/formatters';
import { useFormContext } from './context';

type FormInfoProps = {};

const FormInfo: FC<FormInfoProps> = props => {
  const { offering, termsheet } = useFormContext();
  const { id: lpNumber } = useParams();
  const user = useUserInfo();

  const entries = [
    {
      'Loan Participation Number': lpNumber,
      'Collateral Type': offering.LoanType,
      'Recourse Type': termsheet.lpxSummary?.basis,
      [offering.TermSheetCategory === 'MBL' ? 'Loan Amount' : 'Pool Amount']: Dollar(offering.PoolBalance),
      'Minimum Purchase Amount': Dollar(offering.MinimumPurchaseAmount),
      'Remaining Balance': Dollar(offering.AmountRemaining),
    },
    {
      'Credit Union Name': user.creditUnionName,
      'RT Number': user.routingNumber,
      Name: user.name,
      Title: user.data?.JobTitle ?? '',
      'Phone Number': user.data?.PhoneNumber ?? user.phoneNumber,
      'Email Address': user.data?.Email ?? user.email,
    },
  ];

  if (offering.TransactionType === 'LLO'){
    delete entries[0]['Minimum Purchase Amount'];
    delete entries[0]['Remaining Balance'];
  }

  return (
    <Grid container>
      {entries.map((obj, index) => (
        <Grid key={`form-${index}`} item xs={6}>
          <List>
            {Object.entries(obj).map(([key, value]) => (
              <ListItem key={key}>
                <Grid container>
                  <Grid item xs={6}>
                    <strong>{key}:</strong>
                  </Grid>
                  <Grid item xs={6}>
                    {value}
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </Grid>
      ))}
    </Grid>
  );
};

export default FormInfo;
