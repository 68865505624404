import {
  Box,
  fade,
  FormControlLabel,
  IconButton,
  Popover,
  Radio as MuiRadio,
  TableCell as MuiTableCell,
  TablePagination as MuiTablePagination,
  TableSortLabel,
  TextField,
  Toolbar as MuiToolbar,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Close, FilterList } from '@material-ui/icons';
import clsx from 'clsx';
import LoadingSpinner from 'components/data/LoadingSpinner';
import SearchInput from 'components/SearchInput';
import { useTableContext } from 'components/Table/Table';
import React, { FC, Fragment } from 'react';
import { titleCase } from 'utils/formatters';
import { v4 as uuid } from 'uuid';

export const TableRowLoading: FC = () => {
  const config = useTableContext();
  const classes = useStyles(config);
  if (!config.loading) return null;
  return (
    <config.TableRow className={classes.tableRowLoading}>
      <config.TableCell colSpan={config.columns} align="center" className={classes.tableRowLoading}>
        <LoadingSpinner height="auto" />
      </config.TableCell>
    </config.TableRow>
  );
};

export const TableRowEmpty: FC = () => {
  const config = useTableContext();
  if (config.loading) return null;
  if (!config.empty) return null;
  return (
    <config.TableRow>
      <config.TableCell
        colSpan={config.columns}
        align="center"
        variant="footer"
        style={{ border: 'none' }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" height={config.height}>
          {config.emptyMessage}
        </Box>
      </config.TableCell>
    </config.TableRow>
  );
};

export const TableEntries = () => {
  const config = useTableContext();

  return (
    <Fragment>
      {config.createdData.map((drow: any, index) => {
        return <TableRow key={drow.id} {...drow} index={`${index}-${config.page}`} />;
      })}
    </Fragment>
  );
};

export const TableRow = props => {
  const config = useTableContext();

  switch (config.selectable) {
    case 'single':
      return (
        <config.TableRow hover>
          <config.TableCell padding="checkbox">
            <MuiRadio
              onClick={() => {
                config.setSelected(props);
                if (config.onSelect) config.onSelect(props);
              }}
              checked={config?.selected?.number === props.number}
            />
          </config.TableCell>

          {props.cells.map(({ align, ...item }) => {
            return (
              <config.TableCell key={uuid()} align={align} {...item.cellProps}>
                {item?.display}
              </config.TableCell>
            );
          })}
        </config.TableRow>
      );

    case 'multi':
      return <div>TODO</div>;

    default:
      return (
        <config.TableRow hover>
          {props.cells.map(({ align, ...item }) => {
            return (
              <config.TableCell key={uuid()} align={align} {...item.cellProps}>
                {item?.display}
              </config.TableCell>
            );
          })}
        </config.TableRow>
      );
  }
};

export const Toolbar = () => {
  const config = useTableContext();
  const classes = useStyles(config);

  if (config.hideToolbar) return null;

  return (
    <MuiToolbar className={classes.toolbar} disableGutters>
      <div>
        <Typography className={classes.title} variant="h6">
          {config.title}
          {config.subtitle && <Typography>{config.subtitle}</Typography>}
        </Typography>
      </div>

      <SearchInput />
    </MuiToolbar>
  );
};

export interface FilteredColumn {
  field: string;
  text: string;
  shouldBeDefault: boolean;
}

export const TableHeaders = () => {
  const config: any = useTableContext();
  const classes = useStyles(config);
  const [filteredColumns, setFilteredColumns] = React.useState<FilteredColumn[]>(
    config.tableData
      .filter(d => !!d.defaultFilter)
      .map(d => {
        var filteredColumn: FilteredColumn = {
          field: d.field,
          text: d.defaultFilter,
          shouldBeDefault: true,
        };
        return filteredColumn;
      })
  );

  React.useEffect(() => {
    config.createFilterHandler(filteredColumns);
  }, [filteredColumns]);

  //@ts-ignore
  const FilterButton = ({ column, i }) => {
    const currentFilter = filteredColumns.filter(f => f.field === column.field);
    const [showFilterBox, setShowFilterBox] = React.useState(false);
    const [anchorElement, setAnchorElement] = React.useState<EventTarget & HTMLButtonElement>();
    const [filterValue, setFilterValue] = React.useState(
      column.defaultFilter && currentFilter.length && currentFilter[0].shouldBeDefault
        ? column.defaultFilter
        : currentFilter.length
        ? currentFilter[0].text
        : ''
    );

    const submit = (value: string | null = null) => {
      const filters = filteredColumns.filter(c => c.field !== column.field);

      if (value) {
        filters.push({ field: column.field, text: value, shouldBeDefault: false });
      }

      setFilteredColumns(filters);
    };

    const handleFilterClose = () => {
      setShowFilterBox(false);
      const myColumn = filteredColumns.filter(c => c.field == column.field);
      if (!myColumn.length) {
        submit('');
      } else {
        submit(myColumn[0].text);
      }
    };

    return (
      <>
        <IconButton
          className={clsx(classes.filterListIcon, currentFilter.length ? classes.showFilter : null)}
          onClick={e => {
            setAnchorElement(e.currentTarget);
            setShowFilterBox(true);
          }}
        >
          <FilterList />
        </IconButton>
        <Popover
          id={column.field}
          open={showFilterBox}
          anchorEl={anchorElement}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <TextField
            id={column.field + '-filter'}
            variant="standard"
            value={filterValue}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                submit(filterValue);
              }
            }}
            onChange={e => {
              setFilterValue(e.target.value);
            }}
            InputLabelProps={{
              margin: 'dense',
              style: { color: 'black' },
            }}
          />
          <IconButton
            onClick={() => {
              setFilterValue('');
              submit('');
            }}
          >
            <Close />
          </IconButton>
        </Popover>
      </>
    );
  };

  return (
    <Fragment key={uuid()}>
      {config?.selectable && <MuiTableCell padding="checkbox"></MuiTableCell>}
      {config.tableData.map(({ align, ...h }: any, i: number) => {
        return h.field ? (
          <Fragment key={uuid()}>
            <config.TableCell
              className={classes.header}
              {...h?.cellProps}
              sortDirection={config.orderBy === h.field ? config.order : false}
              align={align}
            >
              <TableSortLabel
                active={config.orderBy === h.field}
                direction={config.orderBy === h.field ? config.order : ('asc' as any)}
                onClick={config.createSortHandler(h.field)}
              >
                {titleCase(h.header)}
                {config.orderBy === h.field && (
                  <span className={classes.visuallyHidden}>
                    {config.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                )}
              </TableSortLabel>
              {h.filterable && <FilterButton column={h} i={i} />}
            </config.TableCell>
          </Fragment>
        ) : (
          <config.TableCell key={uuid()} align={align} {...h.cellProps}>
            {titleCase(h.header)}
          </config.TableCell>
        );
      })}
    </Fragment>
  );
};

export const TableControls = () => {
  const config = useTableContext();
  const classes = useStyles(config);

  if (config.hideControls) return null;
  if (config.loading) return null;

  return (
    <MuiToolbar className={classes.toolbar}>
      <FormControlLabel {...config.toggleTableSizeProps} />

      <MuiTablePagination {...config.tablePaginationProps} />
    </MuiToolbar>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
    },
    tableContainer: {
      overFlowY: 'auto',
      maxHeight: (props: any) => props?.height,
    },
    table: {
      marginTop: 10,
      minWidth: '100%',
      padding: 0,
      '& th,td': {},
    },
    title: { flex: '1 1 100%' },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
    },
    buttonGroup: {
      alignSelf: 'center',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade('rgba(0,0,0,0.05)', 0.1),
      '&:hover': {
        backgroundColor: fade('rgba(0,0,0,0.05)', 0.125),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    tableRowLoading: {
      maxHeight: props => props.height,
      border: 'none',
    },
    filterListIcon: {
      width: 30,
      '& svg': {
        fontSize: 14,
        display: 'none',
      },
      padding: 5,
      '&:hover': {
        '& svg': {
          display: 'inline-block',
        },
        cursor: 'pointer',
        color: theme.colors.black,
        backgroundColor: 'transparent',
      },
    },
    showFilter: {
      '& svg': {
        display: 'inline-block',
        color: theme.colors.navyBlue,
        backgroundColor: 'transparent',
      },
    },
    header: {
      '&:hover': {
        '& button': {
          '& svg': {
            display: 'inline-block',
          },
        },
        '& .MuiTableSortLabel-icon': {
          opacity: 1,
        },
      },
    },
  })
);
