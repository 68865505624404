import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { SignupActionTypes } from './types';
import { signupError, signupSuccess } from './actions';
import { callApi } from '../../utils/api';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

function* handleSignup(action) {
  try {
    const res = yield call(callApi, 'post', API_ENDPOINT, '/signup', action.payload);
    yield res.error ? put(signupError(res.error)) : put(signupSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(signupError(err.stack!))
      : put(signupError('An unknown error occured.'));
  }
}

function* watchSignupRequest() {
  yield takeLatest(SignupActionTypes.SIGNUP_REQUEST, handleSignup);
}

function* signupSaga() {
  yield all([fork(watchSignupRequest)]);
}

export { signupSaga as SignupSaga };
