import {
  Box as MuiBox,
  BoxProps,
  Chip,
  PaperProps,
  Table as MuiTable,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { Done as DoneIcon } from '@material-ui/icons';
import Table from 'components/Table/Table';
import { compare } from 'fast-json-patch';
import useAxios from 'hooks/useAxios';
import Layout from 'layout/Layout';
import adminTables, { LpActions } from 'pages/admin/tables';
import React, { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Dollar, TableDate } from 'utils/formatters';
import { v4 as uuid } from 'uuid';
import Link from 'components/Link';
import Overview from './Overview';

let Box: FC<BoxProps & PaperProps>;
Box = MuiBox as any;

const AdminAcceptRejectPage: React.FC<any> = () => {
  const type = 'offerings';
  const { lpNumber, filter } = useParams();

  const breadcrumbs = [
    { to: `/admin/manage/${type}`, label: 'Offer Management' },
    { to: `/admin/manage/offerings/${filter}`, label: adminTables[type][filter].title },
    { to: `/admin/manage/offerings/${filter}/${lpNumber}`, label: `LP # ${lpNumber}` },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Typography variant="h4">
        <strong>{`LP Number  #${lpNumber}`}</strong>
      </Typography>

      <Overview lpNumber={lpNumber} />
      <LPEntries />
    </Layout>
  );
};

const LPEntries = () => {
  const { lpNumber } = useParams();
  return (
    <Table
      key={uuid()}
      tableProps={{ stickyHeader: true }}
      tableContainerProps={{ style: { maxHeight: '59vh' } }}
      title="Purchase Commitments"
      endpoint={`/marketplace/views/admin/offerings/${lpNumber}/icviews`}
      orderBy="purchaseDate"
      order="asc"
      tableData={[
        {
          field: 'name',
          header: 'Name',
          value: row => (
            <Link to={`/admin/user/profile/${row.userObjectId}`}>{row.contactName}</Link>
          ),
        },
        {
          field: 'creditUnion',
          header: 'Credit Union',
          value: row => row.buyer,
          display: row => (
            <Link to={`/admin/manage/users/credit-unions/${row.buyer}/${row.rtNumber}`}>
              {row.buyer}
            </Link>
          ),
          cellProps: {
            style: { whiteSpace: 'nowrap' },
          },
        },
        { field: 'rtNumber', header: 'RT Number', value: row => row?.rtNumber },
        {
          field: 'indicationAmount',
          header: 'Indication ($)',
          value: row => row?.indicationAmount,
          display: row => Dollar(row.indicationAmount),
        },
        {
          field: 'indicationDate',
          header: 'Indication Date',
          value: row => row.indicationDateSubmitted,
          display: row => TableDate(row.indicationDateSubmitted),
        },
        {
          field: 'purchaseAmount',
          header: 'Purchase ($)',
          value: row => (row?.commitmentAmount > 0 ? row.commitmentAmount : null),
          display: row => (row?.commitmentAmount > 0 ? Dollar(row.commitmentAmount) : null),
        },
        {
          field: 'purchaseDate',
          header: 'Purchase Date',
          value: row => (row?.commitmentAmount > 0 ? row.commitmentDateSubmitted : null),
          display: row =>
            row?.commitmentAmount > 0 ? TableDate(row.commitmentDateSubmitted) : null,
        },
        {
          header: 'Accept / Reject',
          display: row =>
            row?.commitmentAmount > 0 ? <AcceptOrRejectTableCellActions {...row} /> : null,
          align: 'center',
        },
        { header: 'Actions', display: () => <LpActions lpNumber={lpNumber} />, align: 'center' },
      ]}
    />
  );
};

const AcceptOrRejectTableCellActions: React.FC<ICView> = ({
  rtNumber,
  userObjectId,
  creditUnionAccountId,
  status,
  ...props
}) => {
  const { lpNumber } = useParams();
  const location = useLocation();
  const [{ loading, error }, refresh] = useAxios({}, { useCache: false, manual: true });

  const createPatch = (type: 'Accepted' | 'Rejected') =>
    compare({ ...props, userObjectId, Status: status }, { ...props, userObjectId, Status: type });

  const handleAccept = e => {
    const getD = async () =>
      refresh(
        {
          method: 'PATCH',
          url: `/marketplace/commitments/${creditUnionAccountId}-${lpNumber}-Commitment`,
          data: createPatch('Accepted'),
        },
        { useCache: false }
      ).then(() => window.location.assign(location.pathname));

    getD();
  };

  const handleReject = e => {
    const getD = async () =>
      refresh(
        {
          method: 'PATCH',
          url: `/marketplace/commitments/${creditUnionAccountId}-${lpNumber}-Commitment`,
          data: createPatch('Rejected'),
        },
        { useCache: false }
      ).then(() => window.location.assign(location.pathname));
    getD();
  };

  if (loading) return <div>loading..</div>;
  if (process.env.NODE_ENV === 'development' && error) return <div>{error.response.status}</div>;

  switch (status) {
    case 'Accepted':
      return (
        <Tooltip title="User has been accepted">
          <div>
            <Chip
              label="ACCEPTED"
              color="secondary"
              size="small"
              disabled
              style={{ width: '100%' }}
            />
          </div>
        </Tooltip>
      );

    case 'Rejected':
      return (
        <Tooltip title="User has been rejected">
          <div>
            <Chip
              label="REJECTED"
              color="primary"
              size="small"
              disabled
              style={{ width: '100%' }}
            />
          </div>
        </Tooltip>
      );

    case 'Withdrawn':
      return (
        <Tooltip title="Withdrawn">
          <div>
            <Chip label="WITHDRAWN" size="small" disabled style={{ width: '100%' }} />
          </div>
        </Tooltip>
      );

    default:
      return (
        <Box display="flex">
          <Tooltip title="Accept" placement="left-start" arrow>
            <div onClick={handleAccept}>
              <Chip
                label="ACCEPT"
                clickable
                color="secondary"
                onDelete={handleAccept}
                deleteIcon={<DoneIcon />}
                size="small"
                style={{ marginRight: 10 }}
              />
            </div>
          </Tooltip>

          <Tooltip title="Reject" arrow placement="right-end">
            <div onClick={handleReject}>
              <Chip size="small" label="REJECT" clickable color="primary" onDelete={handleReject} />
            </div>
          </Tooltip>
        </Box>
      );
  }
};

export default AdminAcceptRejectPage;

export enum TypeName {
  MarketplaceServiceViewsCommitmentsView = 'MarketplaceService.Views.CommitmentsView',
}

export type ICView = {
  partitionKey?: string;
  lpNumber?: string;
  buyer?: string;
  state?: string;
  rtNumber?: string;
  status?: string;
  indicationAmount?: string;
  indicationDateSubmitted?: string;
  creditUnionAccountId: string;
  commitmentAmount?: string;
  commitmentDateSubmitted?: string;
  contactName?: string;
  userObjectId?: string;
  id?: string;
  typeName?: string;
  _etag?: null;
};

// interface SummaryInfo {
//   type;
//   state: string;
//   forSaleAmount: string;
//   amountRemaining: string;
//   seller: string;
//   settlementDate: string;
//   dueDiligenceDeadline: string;
//   totalIndications: string;
//   totalPurchases: string;
// }
