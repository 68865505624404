import { Theme } from '../utils/theme';
import { useMediaQuery as muiUseMediaQuery } from '@material-ui/core';

export default function useMediaQuery() {
  const xs = muiUseMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));
  const sm = muiUseMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const md = muiUseMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const lg = muiUseMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const xl = muiUseMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

  return { xs, sm, md, lg, xl };
}
