import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const LPTableTitle = styled(Typography)`
  font-weight: 900 !important;
  color: ${({ theme }) => theme.colors.sectionTitle};
  text-transform: capitalize;
  line-height: 1.23;
  text-align: left;
  margin: 0;
  padding-left: 15px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a
`;

LPTableTitle.defaultProps = {
    variant: 'h4',
    style: { width: 'max-content' },
  };

export default LPTableTitle;
