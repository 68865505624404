import React, { FC, memo } from 'react';
import { ReportLpFile } from './index';
import { useTransition, animated } from 'react-spring';
import { List } from './styles';
import { Link } from '@material-ui/core';
import styled from 'styled-components';
import { fluidPadding } from '../../utils/styles';
import { v4 as uuid } from 'uuid';

interface Props {
  files: ReportLpFile[];
  open: boolean;
}

const FileList: FC<Props> = memo(({ files, open }) => {
  const transitions = useTransition(open, null, {
    config: { mass: 1, tension: 5000, friction: 250 },
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: files.length * 35 },
    leave: { opacity: 0, height: 0 },
  });

  return (
    <Wrapper>
      {transitions.map(
        ({ item, key, props: animation }) =>
          item && (
            <animated.div key={key} style={animation}>
              {files.map(file => (
                <List key={uuid()}>
                  <li>{file.filename}</li>
                  <li>{file.date}</li>
                  <li>
                    <Link href={file.path} download>
                      download
                    </Link>
                  </li>
                </List>
              ))}
            </animated.div>
          )
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  ${fluidPadding}
`;

export default FileList;
