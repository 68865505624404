import styled from 'styled-components';
import { fluidPadding, fluidMargin } from '../../utils/styles';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 200px auto;
  gap: 1em;
  padding: 2em;
  border-radius: 10px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(124deg, white, white);
  input,
  input[type='text'],
  input[type='password'] {
    outline: none;
  }
  h6 {
    font-weight: bold;
    align-self: flex-start;
  }
  .dropzone {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    border: 4px dashed rgba(50, 97, 149, 0.3);
    input {
      display: none;
    }
    &:focus {
      background-color: ${props => props.theme.colors.veryLightBlue};
      outline: none;
    }
  }
  button {
    justify-self: center;
  }

  .dropzone.accept-file {
    background-color: ${({ theme }) => theme.colors.veryLightBlue};
    border-color: ${({ theme }) => theme.colors.signupBlue};
  }

  .dropzone.reject-file {
    background-color: ${({ theme }) => theme.colors.pinkishRed}30;
    border-color: ${({ theme }) => theme.colors.pinkishRed}80;
  }

  .close-icon {
    float: left;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 3600;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(119, 126, 138, 0.75);
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  align-content: space-between;
  align-items: center;

  .box {
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
    ${fluidMargin};

    padding: 2em 0;
    border-radius: 10px;
    background: #fff;
    height: 400px;
    max-width: 450px;

    h2 {
      text-transform: uppercase;
      color: ${props => props.theme.colors.navyBlue};
      font-size: 2em;
    }

    p {
      font-size: 1.5em;
    }

    .upload-progress {
      ${fluidPadding};
      margin: 1em 0;
    }
  }

  .close {
    position: absolute;
    top: 0.25em;
    left: 0.25em;
  }

  .check {
    width: 80px;
    height: 80px;
    svg {
      font-size: 4em;
      color: ${props => props.theme.colors.signupBlue};
    }
  }

  .button {
    padding: 8px 44px;
  }

  .graphic {
    background: ${props => props.theme.colors.veryLightBlue};
    border-radius: 50%;
    font-size: 3em;
  }
`;
