import {
  Box as MuiBox,
  BoxProps,
  Button,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  PaperProps,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { PrintOutlined } from '@material-ui/icons';
import CurrencyInput from 'components/CurrencyInput';
import LoadingButton from 'components/LoadingButton';
import { useFormik } from 'formik';
import Layout from 'layout/Layout';
import React, { FC, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { pathReplace, paths } from 'store/routePaths';
import { AxiosInstance } from 'utils/api';
import { Dollar } from 'utils/formatters';
import * as yup from 'yup';
import { FormContextProvider, useFormContext } from './context';
import FormInfo from './FormInfo';

let Box: FC<PaperProps & BoxProps> = MuiBox as any;

const IndicationOfInterest: FC = () => {
  const classes = useStyles();

  const infoRef = useRef();

  return (
    <Layout padding={0} bgcolor="peacockBlue">
      <FormContextProvider>
        <Container maxWidth="lg" className={classes.container}>
          <Box marginX="auto" bgcolor="white" component={Paper}>
            <div>
              <Toolbar className={classes.toolbar}>
                <Typography variant="h5">Indication Of Interest Form</Typography>
                <ReactToPrint
                  trigger={() => (
                    <IconButton>
                      <PrintOutlined />
                    </IconButton>
                  )}
                  content={() => infoRef.current}
                />
              </Toolbar>
            </div>

            <Divider />

            <Box p={3} ref={infoRef} className={classes.info} component={Paper} elevation={0}>
              <FormInfo />
              <Divider />
              <IndicationOfInterestForm />
            </Box>
          </Box>
        </Container>
      </FormContextProvider>
    </Layout>
  );
};

const IndicationOfInterestForm: FC = () => {
  const [step, setStep] = useState(0);
  const classes = useStyles();
  const history = useHistory();
  const { offering } = useFormContext();
  const initialValidationState = {
    hasError: false,
    message: '',
  };
  const [validation, setValidation] = React.useState(initialValidationState);
  const setValidationError = errorMessage => {
    setValidation({
      ...validation,
      hasError: true,
      message: errorMessage,
    });
  };
  const Errors = () => {
    return <div className={classes.errors}>{validation.hasError ? validation.message : null}</div>;
  };
  let schema = yup.object().shape({
    value: yup
      .number()
      .typeError('Amount must be a number')
      .required('Indication Amount is Required')
      .positive('Cannot Be Negative')
      .moreThan(0)
      .max(
        Number(offering?.PoolBalance),
        `Amount must be less than or equal to the Pool Balance ${Dollar(offering?.PoolBalance)}`
      ),
  });

  const { id: lpNumber } = useParams();

  const formik = useFormik({
    initialValues: { amount: '0.00', value: '' },
    validationSchema: schema,
    onSubmit: values => {
      const run = async () =>
        await AxiosInstance({
          method: 'post',
          url: `/marketplace/offering/${lpNumber}/interests`,
          data: {
            Amount: values.amount,
          },
        })
          .then(() => setValidation(initialValidationState))
          .then(() => window.location.assign(pathReplace(paths.lpDueDiligence, lpNumber)))
          .catch(err => {
            setValidationError(
              err?.response?.data ?? 'An error occurred submitting the form, please try again.'
            );
          });

      run();
    },
  });

  const handleCancel = e => {
    history.goBack();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Collapse in={step === 0} unmountOnExit>
        <FormStepOne setStep={setStep}>
          <CurrencyInput label="Indication Amount" fullWidth formik={formik} />
        </FormStepOne>
        <Buttons
          buttons={[
            <Button variant="outlined" fullWidth onClick={handleCancel}>
              CANCEL
            </Button>,
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setStep(current => current + 1)}
              disabled={formik.errors?.value ? true : !formik.touched.value}
              loading={formik.isSubmitting}
            >
              SUBMIT
            </LoadingButton>,
          ]}
        />
      </Collapse>
      <Collapse in={step === 1} unmountOnExit>
        <FormStepTwo setStep={setStep}>
          <Box
            padding={2}
            className={classes.input}
            bgcolor="grey.80"
            borderColor="text.main"
            border={1}
            borderRadius={8}
          >
            <strong>{Dollar(formik.values.value)}</strong>
          </Box>
          <Errors />
          <Buttons
            buttons={[
              <Button variant="outlined" fullWidth onClick={() => setStep(current => current - 1)}>
                BACK
              </Button>,
              <Button variant="contained" color="primary" fullWidth type="submit">
                Proceed to MFA Authorization
              </Button>,
              <Button variant="text" onClick={handleCancel}>
                CANCEL
              </Button>,
            ]}
          />
        </FormStepTwo>
      </Collapse>
    </form>
  );
};
interface FormStepOneProps {
  setStep: any;
}

const Buttons: FC<{ buttons: any[] }> = ({ buttons }) => {
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center' }}>
      <Box m={3} p={3}>
        <Grid container>
          <Grid item xs={6}>
            <Box margin={2}>{buttons?.[0]}</Box>
          </Grid>

          <Grid item xs={6}>
            <Box margin={2}>{buttons?.[1]}</Box>
          </Grid>
        </Grid>
        {buttons?.[2]}
      </Box>
    </Container>
  );
};

const FormStepOne: FC<FormStepOneProps> = ({ setStep, children }) => {
  return (
    <div>
      <Grid container>
        <Grid item md={6}>
          <Typography>
            <strong>Please enter in the amount you are interested in.</strong>
          </Typography>
        </Grid>

        <Grid item md={6}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

interface FormStepTwoProps {
  setStep: any;
}

const FormStepTwo = ({ setStep, children }) => {
  const classes = useStyles();

  return (
    <div>
      <Toolbar className={classes.toolbar}>
        <Typography>
          Please Verify The Amount And Press Continue To Proceed To The Due Diligence Information.
        </Typography>

        <Typography>Please Print A Copy For Your Records.</Typography>
      </Toolbar>

      <Typography>
        Please indicate the amount you would like to express interest in purchasing and submit.
        After submitting your indication of interest you will have access to the due diligence
        information. As this contains confidential information, you will be directed through a
        multi-factor authentication process. The amount entered as your level of interest is not
        binding. Once you have reviewed the due diligence information you can submit your purchase
        commitment amount.
      </Typography>

      <Container maxWidth="sm" className={classes.form}>
        <Box p={3} m={3}>
          {children}
        </Box>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      justifyContent: 'space-evenly',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '90vh',
      justifyContent: 'space-evenly',
    },
    buttons: {
      textAlign: 'center',
      maxWidth: 375,
      alignSelf: 'center',
      display: 'flex',
      justifyContent: 'center',

      '& button': {
        margin: theme.spacing(1),
      },
    },
    step: { minHeight: '55vh' },
    form: {},
    input: {
      minWidth: 50,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    errors: {
      color: theme.colors.peacockBlue,
      fontWeight: 'bold',
      margin: theme.spacing(1),
    },
  })
);

export default IndicationOfInterest;

export interface Offering {
  partitionKey?: string;
  LPIdentifier?: string;
  BlacklistedFinancialEntities?: null;
  WhitelistedFinancialEntities?: null;
  State?: string;
  SoldOut?: null;
  DateAdded?: Date;
  DueDiligenceStart?: Date;
  DueDiligenceDeadline?: Date;
  CutOffDate?: Date;
  SettlementDate?: Date;
  LoanType?: string;
  PoolBalance?: string;
  AmountAvailableForSalePercent?: string;
  RetainedBySeller?: string;
  AmountRemaining?: string;
  SellerName?: string;
  WtdAvgCreditScore?: string;
  WtdAvgLoanRate?: string;
  WtdAverageLtv?: string;
  Basis?: string;
  Restrictions?: boolean;
  LastUpdateEventTime?: Date;
  LastStateChangeDate?: Date;
  id?: string;
  _etag?: string;
  TypeName?: string;
  Version?: number;
}
