import Link from 'components/Link';
import Table from 'components/Table/Table';
import Layout from 'layout/Layout';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { DaysInactive } from 'utils/formatters';

const AdminCreditUnionsPage: FC = () => {
  const type = 'users';
  const { creditUnionName, rtNumber } = useParams();

  return (
    <Layout
      breadcrumbs={[
        {
          to: `/admin/manage/${type}`,
          label: 'Offer Management',
        },
        {
          to: `/admin/manage/users/credit-unions`,
          label: 'Credit Unions',
        },
      ]}
    >
      <Table
        title={`${creditUnionName} - ${rtNumber}`}
        endpoint="/usermanagement/views/admin/users"
        tableProps={{ stickyHeader: true }}
        orderBy="creditUnionName"
        order="asc"
        oData={{
          $filter: [
            `creditUnionName eq '${creditUnionName}'`,
            `rtNumber eq '${rtNumber}'`,
            `disabled eq false`,
          ],
        }}
        tableData={[
          {
            field: 'name',
            header: 'Name',
            value: row => row.name,
            display: row => <Link to={`/admin/user/profile/${row.id}`}>{row.name}</Link>,
          },
          { field: 'jobTitle', header: 'Job Title', value: row => row.jobTitle },

          { field: 'emailAddress', header: 'Email', value: row => row.emailAddress },
          { field: 'phoneNumber', header: 'Phone Number', value: row => row.phoneNumber },
          { field: 'userType', header: 'User Type', value: row => row.userType },
          {
            field: 'lastActiveDate',
            header: 'Days Inactive',
            value: row => row.lastActiveDate,
            display: row => DaysInactive(row),
          },
        ]}
      />
    </Layout>
  );
};

export default AdminCreditUnionsPage;
