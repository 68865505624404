// Import dependencies
import { setupCache } from 'axios-cache-adapter';
import Axios from 'axios';

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

// Create `axios` instance passing the newly created `cache.adapter`
export const api = Axios.create({
  adapter: cache.adapter,
});
