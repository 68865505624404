import { Reducer } from 'redux';
import { OfferingActionTypes, OfferingState } from '../offerings/types';

export const initialState: OfferingState = {
  data: undefined,
  errors: undefined,
  loading: false,
};

export const OfferingReducer: Reducer<OfferingState> = (state = initialState, action) => {
  switch (action.type) {
    case OfferingActionTypes.FETCH_REQUEST:
      return { ...state, loading: true };
    case OfferingActionTypes.FETCH_SUCCESS:
      const combinedData = state.data ? [...state.data, ...action.payload] : action.payload;
      return { ...state, loading: false, data: combinedData };
    case OfferingActionTypes.FETCH_ERROR:
      return { ...state, loading: false, errors: action.payload };

    case OfferingActionTypes.FILTER_REQUEST:
      return { ...state, loading: true, data: [] };
    case OfferingActionTypes.FILTER_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case OfferingActionTypes.FILTER_ERROR:
      return { ...state, loading: false, errors: action.payload };

    case OfferingActionTypes.SELECT_REQUEST:
      return { ...state, loading: true };
    case OfferingActionTypes.SELECT_SUCCESS:
      return { ...state, loading: false, errors: undefined, data: action.payload };
    case OfferingActionTypes.SELECT_ERROR:
      return { ...state, loading: false, errors: action.payload };

    case OfferingActionTypes.FETCH_LP_REQUEST:
      return { ...state, loading: true };
    case OfferingActionTypes.FETCH_LP_SUCCESS:
      // merge objects with the same LP Identifier
      let newData = state.data.map(offering => {
        let result = offering;
        let lpId = offering.LPIdentifier;
        let lp = action.payload.find(lp => lp.LPIdentifier === lpId);
        if (lp) {
          result = Object.assign(offering, lp);
        }
        return result;
      });
      newData = newData.filter(offering => offering.State.toLowerCase() === 'draft');
      return { ...state, loading: false, data: newData };
    case OfferingActionTypes.FETCH_LP_ERROR:
      return { ...state, loading: false, errors: action.payload };

    case OfferingActionTypes.PUBLISH_REQUEST:
      return { ...state, loading: true };
    case OfferingActionTypes.PUBLISH_SUCCESS:
      return { ...state, loading: true, data: action.payload };
    case OfferingActionTypes.PUBLISH_ERROR:
      return { ...state, loading: false, errors: action.payload };

    case OfferingActionTypes.UNPUBLISH_REQUEST:
      return { ...state, loading: true };
    case OfferingActionTypes.UNPUBLISH_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case OfferingActionTypes.UNPUBLISH_ERROR:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};
