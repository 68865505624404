import React, { FunctionComponent, useEffect } from 'react';
import Loading from '../../components/data/Loading';
import LoadingInner from '../../components/data/LoadingInner';
import LoadingSpinner from '../../components/data/LoadingSpinner';
import queryString from 'query-string';

const DocusignCallback: FunctionComponent = props => {
  const parsed = queryString.parse(window.location.search);
  useEffect(() => {
    window.parent.postMessage('sync', '*');
    window.parent.postMessage(`docusign_event:${parsed.event}`, '*');
  }, []);

  return (
    <Loading>
      <LoadingInner>
        <LoadingSpinner />
      </LoadingInner>
    </Loading>
  );
};

export default DocusignCallback;
