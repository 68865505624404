import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { OfferingActionTypes } from './types';
import {
  fetchError,
  fetchLPError,
  fetchLPSuccess,
  fetchRequest,
  fetchSuccess,
  filterError,
  filterRequest,
  filterSuccess,
  publishError,
  publishRequest,
  publishSuccess,
  selectError,
  selectRequest,
  selectSuccess,
  unpublishError,
  unpublishSuccess,
  updateOfferingError,
  updateOfferingRequest,
  updateOfferingSuccess,
} from './actions';

import { callApi } from '../../utils/api';
import { pathReplace, paths } from '../routePaths';
import { push, replace } from 'connected-react-router';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

function* handleFetch(action: ReturnType<typeof fetchRequest>) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/marketplace/views/admin/offerings?${action.payload}`
    );
    yield res.error ? put(fetchError(res.error)) : all([put(fetchSuccess(res))]);
  } catch (err) {
    yield err instanceof Error
      ? put(fetchError(err.stack!))
      : put(fetchError('An unknown error occured.'));
  }
}

function* handleFilter(action: ReturnType<typeof filterRequest>) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/marketplace/views/admin/offerings/?${action.payload}`
    );

    yield res.error ? put(filterError(res.error)) : put(filterSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(filterError(err.stack!))
      : put(filterError('An unknown error occured.'));
  }
}

function* handleSelect(action: ReturnType<typeof selectRequest>) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/marketplace/offerings/${action.payload}`
    );

    yield res.error ? put(selectError(res.error)) : put(selectSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(selectError(err.stack!))
      : put(selectError('An unknown error occured.'));
  }
}

function* handleLPFetch() {
  try {
    const res = yield call(callApi, 'get', API_ENDPOINT, '/termsheets');
    yield res.error ? put(fetchLPError(res.error)) : put(fetchLPSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(fetchLPError(err.stack!))
      : put(fetchLPError('An unknown error occured.'));
  }
}

function* handlePublish(action: ReturnType<typeof publishRequest>) {
  try {
    const res = yield call(
      callApi,
      'post',
      API_ENDPOINT,
      `/marketplace/offering/${action.payload}/publishings`
    );
    if (res.error) {
      yield put(publishError(res.error));
    } else {
      yield put(publishSuccess(res));
      yield put(push('/empty'));
      yield put(replace(pathReplace(paths.lp, action.payload)));
    }
  } catch (err) {
    yield err instanceof Error
      ? put(publishError(err.stack!))
      : put(publishError('An unknown error occured.'));
  }
}

function* handleUnpublish(action: ReturnType<typeof publishRequest>) {
  try {
    const res = yield call(
      callApi,
      'delete',
      API_ENDPOINT,
      `/marketplace/offering/${action.payload}/publishings`
    );

    if (res.error) {
      yield put(unpublishError(res.error));
    } else {
      yield put(unpublishSuccess(res));
      yield put(push('/empty'));
      yield put(replace(pathReplace(paths.lp, action.payload)));
    }
  } catch (err) {
    yield err instanceof Error
      ? put(unpublishError(err.stack!))
      : put(unpublishError('An unknown error occured.'));
  }
}

function* handleUpdateOffering(action: ReturnType<typeof updateOfferingRequest>) {
  try {
    const res = yield call(
      callApi,
      'patch',
      API_ENDPOINT,
      `/marketplace/offerings/${action.payload.id}`,
      action.payload.data
    );
    yield res.error
      ? put(updateOfferingError(res.error))
      : all([put(updateOfferingSuccess(res)), put(selectRequest(action.payload.id))]);
  } catch (err) {
    yield err instanceof Error
      ? put(updateOfferingError(err.stack!))
      : put(updateOfferingError('An unknown error occured.'));
  }
}

function* watchFetchRequest() {
  yield takeEvery(OfferingActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchFilterRequest() {
  yield takeEvery(OfferingActionTypes.FILTER_REQUEST, handleFilter);
}

function* watchSelectRequest() {
  yield takeEvery(OfferingActionTypes.SELECT_REQUEST, handleSelect);
}

function* watchLPFetchRequest() {
  yield takeEvery(OfferingActionTypes.FETCH_LP_REQUEST, handleLPFetch);
}

function* watchPublishRequest() {
  yield takeEvery(OfferingActionTypes.PUBLISH_REQUEST, handlePublish);
}

function* watchUnpublishRequest() {
  yield takeEvery(OfferingActionTypes.UNPUBLISH_REQUEST, handleUnpublish);
}

function* watchUpdateOfferingRequest() {
  yield takeLatest(OfferingActionTypes.UPDATE_OFFERING_REQUEST, handleUpdateOffering);
}

function* offeringSaga() {
  yield all([
    fork(watchFetchRequest),
    fork(watchFilterRequest),
    fork(watchLPFetchRequest),
    fork(watchPublishRequest),
    fork(watchUnpublishRequest),
    fork(watchSelectRequest),
    fork(watchUpdateOfferingRequest),
  ]);
}

export { offeringSaga as LPOfferingSaga };
