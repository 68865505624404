import { EnhancedTableHeadProps, Header } from './types';
import { useTable } from './table-context';
import React, { useState } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LPTableCell from '../LPTableCell';
import {
  createStyles,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  TableSortLabel,
  Theme,
} from '@material-ui/core';
import { Close, FilterList } from '@material-ui/icons';
import { darken, lighten } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionItemIcons: {
      marginLeft: 'auto',
      marginRight: 1,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      maxWidth: 350,
    },
    tableCell: {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.colors.white,
      boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.4)',
      padding: '5px 0px 5px 15px',
      height: 50,
      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },
      '&:hover': {
        '& button': {
          '& svg': {
            display: 'inline-block',
          },
        },
        '& .MuiTableSortLabel-icon': {
          color: lighten(theme.colors.fadedBlack, 0.4),
          opacity: 1,
        },
      },
      '& .MuiTableSortLabel-icon': {
        transition: 'none',
        color: theme.colors.fadedBlack,
      },
      '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
        color: darken(theme.colors.fadedBlack, 0.3),
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 40,
      opacity: 0,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      top: 20,
      width: 1,
    },
    filterListIcon: {
      width: 30,
      '& svg': {
        display: 'none',
      },
      padding: 5,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.colors.blackTwo,
      },
    },
    input: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '& svg': {
        color: theme.colors.peacockBlue,
        opacity: 0.5,
        '&:hover': {
          cursor: 'pointer',
          opacity: 1,
        },
      },
      '& input': {
        '&::placeholder': {
          font: '18px Montserrat',
          color: lighten(theme.colors.blackTwo, 0),
          fontWeight: 'bold',
        },
      },
    },
  })
);

const EnhancedFilterInput = props => {
  const classes = useStyles(props);
  const [{ filter: filterState }] = useTable();
  const { index, showing, onRequestClose, onFilter, header } = props;

  if (!showing) {
    return null;
  }

  return (
    <Input
      placeholder={`Filter ${header.serverField ? '' : 'locally '}by ${
        header.serverField ? header.serverField : header.label
      }`}
      className={classes.input}
      autoFocus={true}
      onChange={e => onFilter(e, header.label)}
      defaultValue={filterState.text}
      endAdornment={
        <InputAdornment position={'end'}>
          <Close onClick={() => onRequestClose(index)} />
        </InputAdornment>
      }
      inputProps={{
        'aria-label': 'description',
      }}
    />
  );
};

export const LPTableHead = (props: EnhancedTableHeadProps) => {
  const classes = useStyles(props);
  const [{ filter: filterState, order: orderState }] = useTable();
  const { onRequestSort, onFilter, selectable } = props;

  const [filterInput, setFilterInput] = useState(-1);

  const createSortHandler = (header: Header) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, header);
  };

  const handleRequestCloseFilterInput = (index: number) => {
    setFilterInput(-1);
    onFilter(undefined, '', true);
  };

  const FilterIcon = ({ header, i }) => {
    if (header.filterDisabled) return null;

    return (
      <IconButton
        className={classes.filterListIcon}
        disableRipple
        onClick={() => setFilterInput(filterInput < 0 ? i : -1)}
      >
        <FilterList />
      </IconButton>
    );
  };

  return (
    <TableHead>
      <TableRow>
        {selectable && <LPTableCell className={classes.tableCell}>&nbsp;</LPTableCell>}
        {props.headers.map((header, i) => (
          <LPTableCell
            key={header.label}
            sortDirection={orderState.label === header.label ? orderState.direction : false}
            className={classes.tableCell}
            style={{ paddingLeft: filterInput === i ? 0 : 'auto' }}
          >
            <div>
              <div style={{ display: filterInput === i ? 'none' : 'block' }}>{header.label}</div>
              <div
                className={classes.actionItemIcons}
                style={{ width: filterInput === i ? '100%' : 'auto' }}
              >
                {filterInput === i ? null : (
                  <React.Fragment>
                    <TableSortLabel
                      active={orderState.label === header.label}
                      direction={orderState.direction}
                      onClick={createSortHandler(header)}
                    >
                      {orderState.label === header.label ? (
                        <span className={classes.visuallyHidden}>
                          {orderState.direction === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                    <FilterIcon header={header} i={i} />
                  </React.Fragment>
                )}
                <EnhancedFilterInput
                  onFilter={onFilter}
                  showing={filterInput === i || header.keyOrGetter === filterState.field}
                  header={header}
                  onRequestClose={handleRequestCloseFilterInput}
                />
              </div>
            </div>
          </LPTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
