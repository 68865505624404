import { Reducer } from 'redux';
import { DownloadActionTypes, DownloadsState } from './types';

export const initialState: DownloadsState = {
  data: undefined,
  errors: undefined,
  loading: false,
};

export const DownloadsReducer: Reducer<DownloadsState> = (state = initialState, action) => {
  switch (action.type) {
    case DownloadActionTypes.FETCH_REQUEST:
      return { ...state, loading: true };
    case DownloadActionTypes.FETCH_SUCCESS:
      const combinedData = state.data ? [...state.data, ...action.payload] : action.payload;
      return { ...state, loading: false, data: combinedData };
    case DownloadActionTypes.FETCH_ERROR:
      return { ...state, loading: false, errors: action.payload };

    case DownloadActionTypes.FETCH_DOWNLOAD_REQUEST:
      return { ...state, loading: true };
    case DownloadActionTypes.FETCH_DOWNLOAD_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case DownloadActionTypes.FETCH_DOWNLOAD_ERROR:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};
