import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';

export const LPButton = props => {
  const { loading, disabled, spinnerColor, ...other } = props;
  const loadingSpinnerColor = props.spinnerColor
    ? props.spinnerColor
    : props.color === 'primary'
    ? 'secondary'
    : 'primary';

  return (
    <Button {...other} disabled={loading || disabled}>
      {loading && <CircularProgress size={24} color={loadingSpinnerColor} />}
      {!loading && props.children}
    </Button>
  );
};
