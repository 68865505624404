import styled from 'styled-components';

const Loading = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: 'transparent';
`;
export default Loading;
