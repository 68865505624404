export interface DocusignCreateCA {
  embedded: boolean;
  userObjectId: string;
  salesforceAccountId: string;
  rtNumber: string;
  userEmailAddress: string;
  recipientEmailAddress: string;
  recipientName: string;
  embeddedViewReturnUrl: string;
}

export interface DocusignCAStatus {
  rejected: boolean;
  envelopeId: string;
  status: string;
  userObjectId: string;
  dateSigned: Date;
}

export enum DocusignActionTypes {
  DOCUSIGN_CREATE_CA_REQUEST = '@@docusign/DOCUSIGN_CREATE_CA_REQUEST',
  DOCUSIGN_CREATE_CA_SUCCESS = '@@docusign/DOCUSIGN_CREATE_CA_SUCCESS',
  DOCUSIGN_CREATE_CA_ERROR = '@@docusign/DOCUSIGN_CREATE_CA_ERROR',
  DOCUSIGN_SYNC_REQUEST = '@@docusign/DOCUSIGN_SYNC_REQUEST',
  DOCUSIGN_SYNC_SUCCESS = '@@docusign/DOCUSIGN_SYNC_SUCCESS',
  DOCUSIGN_SYNC_ERROR = '@@docusign/DOCUSIGN_SYNC_ERROR',
  DOCUSIGN_LATEST_CA_STATUS_REQUEST = '@@docusign/DOCUSIGN_LATEST_CA_STATUS_REQUEST',
  DOCUSIGN_LATEST_CA_STATUS_SUCCESS = '@@docusign/DOCUSIGN_LATEST_CA_STATUS_SUCCESS',
  DOCUSIGN_LATEST_CA_STATUS_ERROR = '@@docusign/DOCUSIGN_LATEST_CA_STATUS_ERROR',
  DOCUSIGN_REJECT_CA_REQUEST = '@@docusign/DOCUSIGN_REJECT_CA_REQUEST',
  DOCUSIGN_REJECT_CA_SUCCESS = '@@docusign/DOCUSIGN_REJECT_CA_SUCCESS',
  DOCUSIGN_REJECT_CA_ERROR = '@@docusign/DOCUSIGN_REJECT_CA_ERROR',
  CLEAR_BULK_CA_STATUS = '@@docusign/CLEAR_BULK_CA_STATUS',
}

export interface DocusignState {
  readonly loading: boolean;
  readonly syncing: boolean;
  readonly data?: any;
  readonly envelopeId: string;
  readonly viewUrl: string;
  readonly errors?: string;
  readonly caStatus?: DocusignCAStatus[];
  readonly newestCAStatus?: DocusignCAStatus;
  readonly rejected?: boolean;
  readonly rejectLoading?: boolean;
}
