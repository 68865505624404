// helper to extract data from termsheet api (/api/termsheets)
import { UserInfo } from 'store/user-management/types';

export const MAX_TIME_IDLE = 420000;
export const MAX_TIME_TO_CONTINUE = 30;

export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production';

export const logUserIn = () => {
  window.location.assign('/api/auth/signin');
};

export const logUserOut = () => {
  //Note, if you call logUserIn right after this, you will prevent the redirect that actually logs out a user.
  //And no, don't use a timer either.
  window.location.assign('/api/auth/signout');
};

export const getHeaderData = (row, value: string) => {
  if (row && row.HeaderData && row.HeaderData[value]) {
    return row.HeaderData[value];
  }
  if (row && row.Data) {
    const additionalHeaderData = row.Data.find(el => el.TableTitle.toLowerCase() === 'lpx summary');

    return selectData(additionalHeaderData, value);
  }
  return '';
};

// termsheet/lp page helper
export const selectData = (data, value: string) => {
  if (data) {
    const obj = data.TableDataAsObject.find(obj => obj['Description'] === value);
    if (obj) {
      return obj.Value;
    }
  }
  return '';
};

// termsheet/lp page helper
export const getData = (data, key: string, value: string, fieldToReturn: string) => {
  if (data) {
    const obj = data.TableDataAsObject.find(obj => obj[key] === value);
    if (obj) {
      return obj[fieldToReturn];
    }
  }
  return '';
};

export const capitalize = string => {
  if (!string) return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getName = (user: UserInfo) => {
  if (user.displayName === 'unknown') {
    return `${user.givenName || ''} ${user.surname || ''}`;
  }

  return user.displayName;
};

export const getFormattedDate = date => {
  return new Date(date).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

export const getFormattedDateTime = date => {
  if (date) {
    return new Date(date).toLocaleDateString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  } else {
    return '';
  }
};

export const FormatDateTime = (dateString: string | Date) => {
  const date = new Date(dateString);
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  let militaryHours = date.getHours();
  const ampm = militaryHours > 11 ? 'PM' : 'AM';

  let hour = militaryHours > 12 ? (militaryHours - 12).toString() : '0' + militaryHours;

  let minute = date.getMinutes().toString();
  minute = minute.length > 1 ? minute : '0' + minute;

  day = day.length > 1 ? day : '0' + day;

  return `${month}/${day}/${year} ${hour}:${minute} ${ampm}`;
};

// Create our number formatter.
export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
export const roundToTwo = num => {
  return Math.round((num + 0.00001) * 100) / 100;
};

export const lpToFixed = num => {
  if (num || num === 0) {
    return num.toFixed(2);
  }
};


export const GetRoundedPercentage = (value) => {
  if (value !== null || value !== undefined) {
      if (!isNaN(value)) {
          return Math.round(value * 100) + '%';
      } else {
          return value;
      }
  } else {
      return 'N/A';
  }
};

export const GetPercentage = (num, round = false) => {
  if (num !== null || num !== undefined) {
    if (!isNaN(num)) {
      let roundedValue = Math.round(num * 100);
      let fixedValue = parseFloat((num * 100).toFixed(3));

      //Only allows a maximum of 3 decimal places. If the fractional part is less than a thousandth, it will display as a whole number. Ex: 82.0008 => 82%
      if (round || roundedValue === fixedValue){
        return roundedValue + '%';
      } else{
        return fixedValue + '%';
      }
    } else {
      return num;
    }
  } else {
    return 'N/A';
  }
}