import { ApiResponse } from '../auth/types';

export enum InterestActionTypes {
  FETCH_REQUEST = '@@interest/FETCH_REQUEST',
  FETCH_SUCCESS = '@@interest/FETCH_SUCCESS',
  FETCH_ERROR = '@@interest/FETCH_ERROR',
  CLEAR_REQUEST = '@@interest/CLEAR_REQUEST',
  FETCH_INTEREST_REQUEST = '@@interest/FETCH_INTEREST_REQUEST',
  FETCH_INTEREST_SUCCESS = '@@interest/FETCH_INTEREST_SUCCESS',
  FETCH_INTEREST_ERROR = '@@interest/FETCH_INTEREST_ERROR',
}

export interface InterestState {
  readonly loading: boolean;
  readonly data?: any;
  readonly errors?: string;
}

export interface InterestInfo extends ApiResponse {
  RelatedLPOffering: string;
  TimeIndicated: string;
  UserObjectId: string;
  RtNumber: string;
  CreditUnionAccountId: string;
  Amount: string;
  id: string;
  partitionKey: string;
  AggregateRootTypeName: string;
  AggregateRootId: string;
  Snapshot: boolean;
  DisposalPolicy: number;
  TimeStamp: string;
  TypeName: string;
  SequenceNumber: number;
  _rid: string;
  _self: string;
  _etag: string;
  _attachments: string;
  _ts: number;
}

/*
*
"RelatedLPOffering": "12-123",
"TimeIndicated": "2019-10-10T21:30:43.9543912Z",
"UserObjectId": "92b7648a-2ccb-426c-89d7-463531b9bb61",
"RtNumber": "12345678901",
"CreditUnionAccountId": "0011N00001i3MvoQAE",
"Amount": "4000",
"id": "MarketplaceService.Domain.IndicationOfInterest-1044471d3bfc4cf68e5fc2765282e276-2",
"partitionKey": "MarketplaceService.Domain.IndicationOfInterest-1044471d3bfc4cf68e5fc2765282e276",
"AggregateRootTypeName": "MarketplaceService.Domain.IndicationOfInterest",
"AggregateRootId": "1044471d3bfc4cf68e5fc2765282e276",
"Snapshot": false,
"DisposalPolicy": 1,
"TimeStamp": "2019-10-10T21:30:43.9543912Z",
"TypeName": "MarketplaceService.Events.InterestIndicatedEvent",
"SequenceNumber": 2,
"_rid": "EW8LAJj+kQ4XAAAAAAAAAA==",
"_self": "dbs/EW8LAA==/colls/EW8LAJj+kQ4=/docs/EW8LAJj+kQ4XAAAAAAAAAA==/",
"_etag": "\"00000000-0000-0000-7fb1-f8fc181001d5\"",
"_attachments": "attachments/",
"_ts": 1570743044
* */
