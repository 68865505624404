import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { BackButton } from '../Common/BackButton';
import { lighten } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { docusignSyncRequest } from '../../store/docusign/actions';
import { useDispatch } from 'react-redux';

interface DocusignEmbeddedCAProps {
  docusign: any;
  displayOverlay?: boolean;
  onClose?: Function;
}

const eventMessages = {
  cancel: 'Finish Later',
  decline: 'declined to sign',
  exception: 'A processing error occured during the signing session',
  fax_pending: 'print, sign and fax back',
  id_check_failed: 'failed authentication',
  session_timeout: 'session timed out. Please reload the page and try again',
  signing_complete: 'Completed Signing',
  ttl_expired: 'token timed out or already accessed',
  viewing_complete: 'viewing completed',
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
    height: '90vh',
    display: (props: DocusignEmbeddedCAProps) => (props.docusign.envelopeId ? 'block' : 'none'),
  },
  backButton: {
    marginTop: 10,
    '& .MuiIcon-root': {
      color: theme.colors.white,
    },
  },
  overlay: {
    display: (props: DocusignEmbeddedCAProps) => (props.displayOverlay ? 'flex' : 'none'),
    position: 'fixed',
    height: '100vh',
    width: '100%',
    top: 0,
    backgroundColor: theme.colors.navyBlue,
    zIndex: 1000,
  },
  wrapper: {
    width: '90%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: theme.colors.white,
    fontSize: 30,
    marginTop: 25,
    marginBottom: 35,
    textAlign: 'center',
  },
  responseMessage: {
    width: '90%',
    textAlign: 'center',
    color: theme.colors.white,
    margin: 50,
  },
  responseMessageTitle: {
    '& span': {
      color: lighten(theme.colors.darkGrey, 0.5),
    },
  },
}));

const Overlay = props => {
  const classes = useStyles(props);
  const { docusign, onClose, displayOverlay, event } = props;

  if (!displayOverlay) {
    return null;
  }

  return (
    <div className={classes.overlay}>
      <BackButton
        classes={{ root: classes.backButton }}
        onClick={() => {
          onClose();
        }}
      />
      <div className={classes.wrapper}>
        <h2 className={classes.title}>Please Sign the User Agreement and Submit</h2>
        {event && (
          <div className={classes.responseMessage}>
            <h2 className={classes.responseMessageTitle}>
              Status: &nbsp; <span>{eventMessages[event]} </span>
            </h2>
            <Button variant="contained" color="secondary" onClick={onClose}>
              <Icon>chevron_left</Icon> Go Back
            </Button>
          </div>
        )}
        {!docusign.syncing && (
          <iframe
            title="docusign"
            src={docusign.viewUrl}
            className={classes.root}
            name="docusignIFrame"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export const DocusignEmbeddedCA: FunctionComponent<DocusignEmbeddedCAProps> = props => {
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [event, setEvent] = useState(undefined);
  const { docusign } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const handleIFrameMessage = (docusign, e) => {
      if (e.data === 'sync') {
        dispatch(docusignSyncRequest(docusign.envelopeId));
      }
    };

    window.addEventListener('message', e => handleIFrameMessage(docusign, e));
    return () => {
      window.removeEventListener('message', e => handleIFrameMessage(docusign, e));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docusign.envelopeId]);

  useEffect(() => {
    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, []);

  const handleEvent = e => {
    if (typeof e.data === 'string' && e.data.startsWith('docusign_event')) {
      const event = e.data.split(':')[1];
      setEvent(event);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  useEffect(() => {
    if (props.docusign.envelopeId && props.docusign.viewUrl) {
      setDisplayOverlay(true);
    }
  }, [props.docusign.envelopeId, props.docusign.viewUrl]);

  const handleClose = () => {
    setEvent(undefined);
    setDisplayOverlay(false);
  };

  return <Overlay {...props} displayOverlay={displayOverlay} onClose={handleClose} event={event} />;
};
