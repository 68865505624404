import { Reducer } from 'redux';
import { InterestActionTypes, InterestState } from './types';

export const initialState: InterestState = {
  data: undefined,
  errors: undefined,
  loading: false,
};

export const InterestReducer: Reducer<InterestState> = (state = initialState, action) => {
  switch (action.type) {
    case InterestActionTypes.FETCH_REQUEST:
      return { ...state, loading: true };
    case InterestActionTypes.FETCH_SUCCESS:
      const combinedData = state.data ? [...state.data, ...action.payload] : action.payload;
      return { ...state, loading: false, data: combinedData };
    case InterestActionTypes.FETCH_ERROR:
      return { ...state, loading: false, errors: action.payload };
    case InterestActionTypes.CLEAR_REQUEST:
      return { ...state, loading: true, data: undefined };

    case InterestActionTypes.FETCH_INTEREST_REQUEST:
      return { ...state, loading: true };
    case InterestActionTypes.FETCH_INTEREST_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case InterestActionTypes.FETCH_INTEREST_ERROR:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};
