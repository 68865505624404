import React from 'react';

export interface UserContextShape {
  authenticated?: boolean;
  verified?: boolean;
  admin?: boolean;
  completedMFA?: boolean;
  id?: string;
  creditUnionAccountId?: string;
  creditUnionName?: string;
  phoneNumber?: string;
  routingNumber?: string;
  loading?: boolean;
  name?: string;
  email?: string;
  restrictions: { disableUpload: boolean; disableReports: boolean };
  data?: AuthInfo;
}
export interface AuthInfo {
  IsAuthenticated?: boolean;
  UserType?: string;
  Roles?: string[];
  Claims?: Claim[];
  UserObjectId?: string;
  Name?: string;
  AuthenticationType?: string;
  AuthenticationTime?: null;
  RoutingNumber?: string;
  Email?: string;
  CreditUnionAccountId?: string;
  Partner?: Partner;
  CreditUnionState?: string;
  CreditUnionName?: string;
  DisableUpload?: boolean;
  DisableReports?: boolean;
  PhoneNumber?: string;
  JobTitle?: string;
}

export interface Claim {
  Issuer?: string;
  OriginalIssuer?: string;
  Type?: string;
  ValueType?: string;
  Value?: string;
}

export interface Partner {
  RTNumbers?: string[];
  Id?: string;
  Name?: string;
  SalesforceAccountId?: string;
  State?: string;
}

export const UserContext = React.createContext<UserContextShape>({
  authenticated: false,
  verified: false,
  admin: false,
  completedMFA: false,
  id: undefined,
  creditUnionAccountId: undefined,
  creditUnionName: undefined,
  phoneNumber: undefined,
  routingNumber: undefined,
  loading: false,
  name: '',
  email: '',
  restrictions: { disableUpload: false, disableReports: false },
});
