import {
  Box as MuiBox,
  BoxProps,
  Button,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { PrintOutlined } from '@material-ui/icons';
import CurrencyInput from 'components/CurrencyInput';
import LoadingButton from 'components/LoadingButton';
import { compare } from 'fast-json-patch';
import { useFormik } from 'formik';
import Layout from 'layout/Layout';
import React, { FC, useRef, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { AxiosInstance } from 'utils/api';
import { Dollar } from 'utils/formatters';
import * as yup from 'yup';
import { FormContextProvider, useFormContext } from './context';
import FormInfo from './FormInfo';
import { UserContext } from 'components/data/context';
let Box: FC<BoxProps & any> = MuiBox as any;

interface Props {
  lpNumber?: string;
}

const confirmStep: number = 1;
const confirmWithdrawlStep: number = 2;

const PurchaseCommitment: React.FC<Props> = () => {
  const classes = useStyles();
  const infoRef = useRef();

  return (
    <Layout padding={0} bgcolor="peacockBlue">
      <FormContextProvider>
        <Container maxWidth="lg" className={classes.container}>
          <Box marginX="auto" bgcolor="white" component={Paper}>
            <div>
              <Toolbar className={classes.toolbar}>
                <Typography variant="h5">Purchase Commitment Form</Typography>
                <ReactToPrint
                  trigger={() => (
                    <IconButton>
                      <PrintOutlined />
                    </IconButton>
                  )}
                  content={() => infoRef.current}
                />
              </Toolbar>
            </div>
            <Divider />
            <Box p={3} ref={infoRef} className={classes.info} component={Paper} elevation={0}>
              <FormInfo />
              <Divider />
              <PurchaseCommitmentForm />
            </Box>
          </Box>
        </Container>
      </FormContextProvider>
    </Layout>
  );
};

const PurchaseCommitmentForm: React.FC = () => {
  const [step, setStep] = useState(0);
  const { offering, commitment } = useFormContext();
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);
  const { id: lpNumber } = useParams();

  let schema = yup.object().shape({
    value: yup
      .number()
      .typeError('Purchase Amount must be a number')
      .required('Purchase Amount is Required')
      .positive('Cannot Be Negative')
      .moreThan(0)
      .max(
        Number(offering?.PoolBalance),
        `Amount must be less than or equal to the Pool Balance ${Dollar(offering?.PoolBalance)}`
      ),
  });
  const handleOnSubmit = (amount, userId) => {
    const run = async () => {
      if (commitment?.activeCommitment) {
        if (commitment?.hasAcceptedCommitment) {
          return;
        }
        await AxiosInstance({
          method: 'patch',
          url: `/marketplace/commitments/${commitment?.activeCommitment?.id}`,
          data: [
            { op: 'replace', path: '/Amount', value: amount },
            { op: 'replace', path: '/Status', value: 'Pending' },
            { op: 'replace', path: '/UserObjectId', value: userId },
          ],
        }).finally(() => history.goBack());
      } else {
        await AxiosInstance({
          method: 'post',
          url: `/marketplace/offering/${lpNumber}/commitments`,
          data: {
            Amount: amount,
          },
        }).finally(() => history.goBack());
      }
    };
    run();
  };

  const formik = useFormik({
    initialValues: {
      amount: commitment?.activeCommitment?.amount
        ? Dollar(commitment?.activeCommitment?.amount).substring(1)
        : '',
      value: commitment?.activeCommitment?.amount || '',
    },
    enableReinitialize: true,
    validationSchema: schema,

    onSubmit: values => {
      handleOnSubmit(values.amount, user.id);
    },
  });

  const handleCancel = e => {
    history.goBack();
  };

  const createPatch = (type: 'Withdrawn') =>
    compare(
      { id: commitment?.activeCommitment?.id, Status: commitment?.activeCommitment?.status },
      { id: commitment?.activeCommitment.id, Status: type }
    );

  const handleWithdraw = e => {
    AxiosInstance({
      method: 'PATCH',
      url: `/marketplace/commitments/${commitment?.activeCommitment?.id}`,
      data: createPatch('Withdrawn'),
    }).then(res => {
      history.goBack();
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {commitment?.hasAcceptedCommitment && (
        <Typography className={classes.red}>
          <strong>You already have an accepted Purchase Commitment for this LP.</strong>
          <br />
          <br />
        </Typography>
      )}
      <Collapse in={step === 0} unmountOnExit>
        <FormStepOne setStep={setStep}>
          <CurrencyInput label={'Purchase Amount'} fullWidth formik={formik} />
        </FormStepOne>
        <Buttons
          buttons={[
            <Button
              disabled={
                commitment?.hasAcceptedCommitment || !commitment?.activeCommitment ? true : false
              }
              variant="outlined"
              fullWidth
              onClick={() => setStep(current => current + 2)}
            >
              WITHDRAW
            </Button>,
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setStep(current => current + 1)}
              disabled={
                formik.errors?.value || commitment?.hasAcceptedCommitment
                  ? true
                  : !formik.touched.value
              }
            >
              SUBMIT
            </Button>,
            <Button variant="text" fullWidth onClick={handleCancel}>
              CANCEL
            </Button>,
          ]}
        />
      </Collapse>

      <Collapse in={step === confirmStep} unmountOnExit>
        <FormStepTwo setStep={setStep}>
          <Box
            padding={2}
            className={classes.input}
            bgcolor="grey.80"
            borderColor="text.main"
            border={1}
            borderRadius={8}
          >
            <strong>{Dollar(formik.values.value)}</strong>
          </Box>
          <Buttons
            buttons={[
              <Button variant="outlined" fullWidth onClick={() => setStep(current => current - 1)}>
                BACK
              </Button>,
              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                loading={formik.isSubmitting}
              >
                CONTINUE
              </LoadingButton>,
              <Button variant="text" onClick={handleCancel}>
                CANCEL
              </Button>,
            ]}
          />
        </FormStepTwo>
      </Collapse>

      <Collapse in={step === confirmWithdrawlStep} unmountOnExit>
        <FormStepWithdraw setStep={setStep}>
          <Buttons
            buttons={[
              <Button variant="outlined" fullWidth onClick={() => setStep(current => current - 2)}>
                BACK
              </Button>,
              <Button variant="contained" onClick={handleWithdraw} color="primary" fullWidth>
                CONTINUE
              </Button>,
              <Button variant="text" onClick={handleCancel}>
                CANCEL
              </Button>,
            ]}
          />
        </FormStepWithdraw>
      </Collapse>
    </form>
  );
};
interface FormStepOneProps {
  setStep: any;
}

const Buttons: React.FC<{ buttons: any[] }> = ({ buttons }) => {
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center' }}>
      <Box m={3} p={3}>
        <Grid container>
          <Grid xs={6}>
            <Box margin={2}>{buttons?.[0]}</Box>
          </Grid>

          <Grid item xs={6}>
            <Box margin={2}>{buttons?.[1]}</Box>
          </Grid>
        </Grid>
        {buttons?.[2]}
      </Box>
    </Container>
  );
};

const FormStepOne: React.FC<FormStepOneProps> = ({ setStep, children }) => {
  return (
    <div>
      <Grid container>
        <Grid item md={6}>
          <Typography>
            <strong>Please enter in the amount you would like to purchase</strong>
          </Typography>
        </Grid>

        <Grid item md={6}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

interface FormStepTwoProps {
  setStep: any;
}

const FormStepTwo = ({ setStep, children }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.label}>
        Please Verify The Amount And Press Continue.
      </Typography>
      <Typography>Please Print A Copy For Your Records.</Typography>
      <Typography className={classes.label}>
        A confirmation email will be sent to you once you click "Continue" and we receive your
        purchase commitment.
      </Typography>
      <Container maxWidth="sm" className={classes.form}>
        <Box p={3} m={3}>
          {children}
        </Box>
      </Container>
    </div>
  );
};

const FormStepWithdraw = ({ setStep, children }) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Toolbar className={classes.toolbar}>
          <Typography>
            You have requested to withdraw your purchase commitment. Please press continue to
            finalize your decision.
          </Typography>
        </Toolbar>
        <Grid item md={12}>
          {children}
        </Grid>
      </Grid>
      {/* <Container maxWidth="sm" className={classes.form}> */}

      {/* <Box p={3} m={3}>
          {children}
        </Box> */}
      {/* </Container> */}
    </div>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      justifyContent: 'space-evenly',
    },
    label: {
      textAlign: 'left',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '90vh',
      justifyContent: 'space-evenly',
    },
    buttons: {
      textAlign: 'center',
      maxWidth: 500,
      alignSelf: 'center',
      display: 'flex',
      justifyContent: 'center',

      '& button': {
        margin: theme.spacing(1),
      },
    },
    step: { minHeight: '55vh' },
    form: {},
    input: {
      minWidth: 50,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    red: {
      color: 'red',
    },
  })
);

export default PurchaseCommitment;

export interface IPurchaseCommitmentViewModel {
  activeCommitment: IPurchaseCommitment;
  hasAcceptedCommitment: boolean;
}

export interface IPurchaseCommitment {
  partitionKey?: string;
  relatedLPOffering?: string;
  timeSubmitted?: string;
  creditUnionAccountId?: string;
  userObjectId?: string;
  rtNumber?: string;
  amount?: string;
  status?: string;
  lastUpdateEventTime?: null;
  lastStatusChangeDate?: null;
  id?: string;
  _etag?: string;
  typeName?: string;
  version?: number;
}

export interface Offering {
  partitionKey?: string;
  LPIdentifier?: string;
  BlacklistedFinancialEntities?: null;
  WhitelistedFinancialEntities?: null;
  State?: string;
  SoldOut?: null;
  DateAdded?: Date;
  DueDiligenceStart?: Date;
  DueDiligenceDeadline?: Date;
  CutOffDate?: Date;
  SettlementDate?: Date;
  LoanType?: string;
  TermSheetCategory?: string;
  TransactionType?: string;
  PoolBalance?: string;
  AmountAvailableForSalePercent?: string;
  RetainedBySeller?: string;
  AmountRemaining?: string;
  SellerName?: string;
  WtdAvgCreditScore?: string;
  WtdAvgLoanRate?: string;
  WtdAverageLtv?: string;
  Basis?: string;
  Restrictions?: boolean;
  LastUpdateEventTime?: Date;
  LastStateChangeDate?: Date;
  MinimumPurchaseAmount?: string;
  id?: string;
  _etag?: string;
  TypeName?: string;
  Version?: number;
}
