export const seoDefaultKeyWords = [
  'Loan Participations',
  'Catalyst Corporate',
  'Credit Union',
  'Loan Participation Exchange',
  'LPExchange',
  'Loan Participations',
];
export const seoHomePageKeyWords = [
  'Loan Pools',
  'Credit Union Participations',
  'Credit Union Loan Participations',
  'Buying Loan Participations',
  'Selling Loan Participations',
  'Selling Loans',
  'Buying Loans',
  'Auto Loan Participations',
  'Buying Auto Loan Participations',
  'Buying Auto Participations',
  'Selling Auto Participations',
  'Mortgage Loan Participations',
  'Buying Mortgage Loan Participations',
  'Selling Mortgage Loan Participations',
  'Real Estate Loan Participations',
  'Consumer Loan Participations',
  'Buying Consumer Loan Participations',
  'Selling Consumer Loan Participations',
  'Commercial Loan Participations',
  'Buying Commercial Loan Participations',
  'Selling Commercial Loan Participations',
];

export const seoBuyPageKeyWords = [
  'Buy Loan Participations',
  'Purchase Loan Participations',
  'Buy Loan Pools',
  'Loan Pools',
  'Credit Union Loan Participations',
  'Buying Loan Pools',
  'Buying Loan Participations',
  'Buying Participations',
  'Auto Loan Participations',
  'Buying Auto Loan Participations',
  'Buying Auto Participations',
  'Mortgage Loan Participations',
  'Buying Mortgage Loan Participations',
  'Buying Mortgage Participations',
  'Mortgage Participations',
  'Home Equity Loan Participations',
  'Buying Home Equity Loan Participations',
  'Buying Home Equity Participations',
  'Real Estate Participations',
  'Consumer Loan Participations',
  'Buying Consumer Loan Participations',
  'Buying Commercial Loan Participations',
  'Buying Commercial Participations',
  'Commercial Participations',
];

export const seoSellPageKeyWords = [
  'Sell Loan Participations',
  'Sell Loan Pools',
  'Purchase Loan Participations',
  'Credit Union Loan Participations',
  'Selling Loan Pools',
  'Selling Loan Participations',
  'Selling Participations',
  'Loan Pool Sales',
  'Credit Union Loan Pool Sales',
  'Credit Union Loan Sales',
  'Auto Loan Participations',
  'Selling Auto Participations',
  'Selling Mortgage Loan Participations',
  'Selling Mortgage Participations',
  'Mortgage Participations',
  'Real Estate Loan Participations',
  'Selling Real Estate Loan Participations',
  'Selling Real Estate Participations',
  'Real Estate Participations',
  'Consumer Loan Participations',
  'Selling Consumer Loan Participations',
  'Selling Consumer Participations',
  'Consumer Participations',
  'Selling Commercial Participations',
  'Commercial Participations',
];

export const seoTermSheetPageKeyWords = [
  'Loan Participation Offering',
  'LP Offering',
  'Loan Participation Term Sheet',
  'Loan Participations available for sale',
  'Loan Participations for sale',
];

export const seoLearnPageKeyWords = [
  'Loan Participation Training',
  'Loan Participation Education',
  'Learn Loan Participations',
  'Credit Union Loan Participations',
  'Buying Loan Pools',
  'Selling Loan Pools',
  'Buying Loan Participations',
  'Selling Loan Participations',
  'Buying Participations',
  'Loan Participation Information',
  'Selling Participations',
];
