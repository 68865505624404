import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from 'utils/theme';
import Button from '@material-ui/core/Button';

const LPTextField = styled('input')`
  background-color: ${colors.white};
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #6192d1;
  width: 300px;

  border-width: 2px;
  border-color: transparent;
  border-style: inset;

  outline: 0;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactForm: {
      // display: 'flex',
      // flexDirection: 'column',
      //  maxWidth: (props: SignAutorityFormProps) => (props.maxWidth ? props.maxWidth + 'px' : '100%'),
      //  position: 'relative',
      //   '& button': {
      //     marginTop: 40,
      //     alignSelf: 'center',
      //     fontWeight: 600,
      //     boxShadow: '0 3px 10px 0 rgba(111, 11, 19, 0.62)',
      //    },
    },
    formFields: {
      // display: 'flex',
      justifyContent: 'center',
      //   position: 'relative',
      //  flexWrap: 'wrap',
      //    width: '400px',
      //  '& > div': {
      //    display: 'flex',
      //    flexDirection: 'column',
      //    flex: '1 0 auto',
      //  },
    },
    inputLabel: {
      color: theme.colors.white,
      fontSize: 14,
      textAlign: 'center',
    },
    buttons: {
      textAlign: 'center',
      width: '100%',
    },
    noButton: {
      color: theme.colors.white,

      padding: '5px 35px',
      fontWeight: 600,
    },
    yesButton: {
      color: theme.colors.white,

      padding: '5px 35px',
      fontWeight: 600,
      marginLeft: '50px',
    },
  })
);

export const SignAutorityForm = props => {
  const classes = useStyles(props);
  const [values, setValues] = useState<React.ComponentState>({
    Recipient: '',
    RecipientEmail: '',
  });

  const handleChange = (name: keyof React.ComponentState) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = e => {
    // TODO: dispatch api request to POST endpoint
    // notify user of error/success
    e.preventDefault();
    const { Recipient, RecipientEmail } = values;
    const { ForwardSigningRequest } = props;

    if (Recipient && RecipientEmail) {
      ForwardSigningRequest(Recipient, RecipientEmail);
    }
  };

  const { backOnClick } = props;
  return (
    <form className={classes.contactForm} onSubmit={handleSubmit}>
      <div className={classes.buttons}>
        <h2 className={classes.inputLabel}>Name of Recipient:</h2>
        <LPTextField
          type="text"
          id="recipient"
          placeholder="First and Last Name"
          value={values.recipient}
          onChange={handleChange('Recipient')}
        />
        <br />
        <br />
        <h2 className={classes.inputLabel}>Forwarding Email:</h2>
        <LPTextField
          type="email"
          id="recipient_email"
          placeholder="Email"
          value={values.recipientEmail}
          onChange={handleChange('RecipientEmail')}
        />
      </div>
      <br />
      <div className={classes.buttons}>
        <Button
          variant="outlined"
          color="inherit"
          className={classes.noButton}
          onClick={backOnClick}
        >
          Back
        </Button>
        <Button
          variant="contained"
          className={classes.yesButton}
          color="primary"
          onClick={handleSubmit}
        >
          SEND
        </Button>
      </div>
    </form>
  );
};
