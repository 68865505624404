import React, { FC } from 'react';
import {
  Container,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core';
import JSONPretty from 'react-json-pretty';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useLocalStorage } from 'react-use';

interface CodeHelperProps {
  name?: string;
  [key: string]: any;
}

const CodeHelper: FC<CodeHelperProps> = ({ name, ...props }) => {
  const [isExpanded, setIsExpanded] = useLocalStorage('code-helper', false);
  const toggle = e => {
    setIsExpanded(val => !val);
  };
  return (
    <ExpansionPanel expanded={isExpanded}>
      <ExpansionPanelSummary
        onClick={toggle}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${name}-controls`}
        id={name}
      >
        <Typography>{name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <JSONPretty
          id="json-pretty"
          data={props}
          theme={
            {
              main:
                'font-size: 1rem;font-weight:500;line-height:1.3;color:#709775;overflow:auto;padding:1em;',
              error: 'line-height:1.3;color:#f5bb12;background:#1e1e1e;overflow:auto;',
              key: 'color:#F0544F;font-weight:bold;',
              string: 'color:#020202;',
              value: 'color:#058C42;',
              boolean: 'color:#4bae16;',
            } as any
          }
          replacer={function(key, value) {
            if (key === 'cccc') {
              value += '~~~abc';
            }
            if (key === 'gggg') {
              value *= 10;
            }
            return value;
          }}
          space="4"
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

CodeHelper.defaultProps = {
  name: 'Code',
};

export default CodeHelper;
