import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { InterestActionTypes } from './types';
import {
  fetchError,
  fetchInterestError,
  fetchInterestRequest,
  fetchInterestSuccess,
  fetchRequest,
  fetchSuccess,
} from './actions';
import { callApi } from '../../utils/api';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

function* handleFetch(action: ReturnType<typeof fetchRequest>) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/marketplace/interests?${action.payload}`
    );
    yield res.error ? put(fetchError(res.error)) : all([put(fetchSuccess(res))]);
  } catch (err) {
    yield err instanceof Error
      ? put(fetchError(err.stack!))
      : put(fetchError('An unknown error occured.'));
  }
}

function* handleInterestFetch(action: ReturnType<typeof fetchInterestRequest>) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/marketplace/offering/${action.payload}/interests`
    );
    yield res.error ? put(fetchInterestError(res.error)) : all([put(fetchInterestSuccess(res))]);
  } catch (err) {
    yield err instanceof Error
      ? put(fetchInterestError(err.stack!))
      : put(fetchInterestError('An unknown error occured.'));
  }
}

function* watchFetchRequest() {
  yield takeEvery(InterestActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchInterestFetchRequest() {
  yield takeEvery(InterestActionTypes.FETCH_INTEREST_REQUEST, handleInterestFetch);
}

function* interestSaga() {
  yield all([fork(watchFetchRequest), fork(watchInterestFetchRequest)]);
}

export { interestSaga as InterestSaga };
