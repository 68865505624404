import { action } from 'typesafe-actions';
import { AuthActionTypes, AuthInfo } from './types';
import { UserInfo } from '../../store/user-management/types';
import { Notification } from '../../pages/profile/useUserProfileReducer';

export const fetchRequest = () => action(AuthActionTypes.FETCH_REQUEST);
export const fetchSuccess = (data: AuthInfo) => action(AuthActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(AuthActionTypes.FETCH_ERROR, message);
export const userSelectRequest = (userObjectId: string) =>
  action(AuthActionTypes.USER_SELECT_REQUEST, userObjectId);
export const userSelectSuccess = (data: UserInfo) =>
  action(AuthActionTypes.USER_SELECT_SUCCESS, data);
export const userSelectError = (message: string) =>
  action(AuthActionTypes.USER_SELECT_ERROR, message);
export const clearUser = () => action(AuthActionTypes.CLEAR_USER);
export const userNotificationsRequest = (userObjectId: string) =>
  action(AuthActionTypes.USER_NOTIFICAITON_REQUEST, userObjectId);
export const userNotificationsSuccess = (data: any) =>
  action(AuthActionTypes.USER_NOTIFICAITON_SUCCESS, data);

export const userNotificationUpdateRequest = (userId: string, notificationObj: Notification) =>
  action(AuthActionTypes.USER_NOTIFICAITON_UPDATE_REQUEST, {
    userId: userId,
    notification: notificationObj,
  });
export const userNotificationUpdateSuccess = (data: any) =>
  action(AuthActionTypes.USER_NOTIFICAITON_UPDATE_SUCCESS, data);
export const clearNotification = () => action(AuthActionTypes.CLEAR_NOTIFICATION);
