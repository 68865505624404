// useFetch.js
import { useState, useEffect } from 'react';
import { axiosConfig, apiUrl } from '../../utils/api';
import { api } from './cachedAxios';

// custom hook for performing GET request
export interface UseFetchResponse<Type> {
  data: Type;
  loading: boolean;
}

export default function useCMS<T>(path: string) {
  const [data, setData] = useState<T>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await api
          .get<T>(`${apiUrl}api/sitefinity/content${path}`, axiosConfig)
          .then(res => {
            setData(res.data);
          })
          .catch(err => setError(err));
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.response) {
          setError('Error: ' + e.response.status + ' ' + e.response.statusText);
        } else {
          setError('An unknown error occurred');
        }
      }
    };

    fetchData();
  }, [path]);

  return { data, loading, error };
}
