import React, { Fragment, useState } from 'react';
import { ReportLp } from './index';
import { LP } from './styles';
import { Button, Typography } from '@material-ui/core';
import FileList from './FileList';
import { v4 as uuid } from 'uuid';
interface Props {
  lps: ReportLp[];
}

const LpList: React.FC<Props> = ({ lps }) => {
  const [open, setOpen] = useState(-1);
  const toggleFiles: (index: number) => void = index => {
    if (open === index) setOpen(-1);
    else setOpen(index);
  };
  if (lps.length === 0) return null;

  return (
    <>
      {lps.map((lp, index) => {
        if (lps[index].files.length === 0) return null;
        return (
          <Fragment key={uuid()}>
            <LP>
              <li>
                <Typography variant="h4">LP# {lp.lpNumber}</Typography>
              </li>
              <li>
                <span style={{ opacity: open === index ? 1 : 0 }}>date</span>
              </li>
              <li>
                <Button onClick={() => toggleFiles(index)}>
                  {open !== index ? 'open' : 'close'}
                </Button>
              </li>
            </LP>

            <FileList files={lps[index].files} open={open === index} />
          </Fragment>
        );
      })}
    </>
  );
};

export default LpList;
