import React from 'react';
import { SiteFinityContentTypes, CMSContent } from './data/CMSContent';
import { Card as MuiCard, CardContent, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {
  page: string;
  type?: SiteFinityContentTypes;
  name?: string;
  classes: any;
}
const ReadMoreWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1em;
`;

const CardContentWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

const Card: React.FC<Props> = ({ page, type, name, classes, history }) => {
  const handleClick = () => {
    history.push(`/${page}`);
  };

  return (
    <MuiCard className={classes.card} onClick={handleClick}>
      <CardContent className={classes.cardContent}>
        <CardContentWrapper>
          <Typography className={classes.cardTitle} variant="h3" component="h3">
            {page.toUpperCase()}
          </Typography>
          <CMSContent type={type} name={name} />
        </CardContentWrapper>
        <ReadMoreWrapper>
          <p>Read More></p>
        </ReadMoreWrapper>
      </CardContent>
    </MuiCard>
  );
};

export default withRouter(Card);
