import React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/theme';
import { Link } from 'react-router-dom';

// can use NavLink or Link here
const LPLinkPlain = props => {
  const { component: LinkComponent = Link, hitServer, to } = props;

  const StyledLink = styled(LinkComponent)`
    color: ${colors.blackTwo};
    text-decoration: none;
  `;

  const handleClick = e => {
    if (props.onClick) {
      props.onClick(e);
      return false;
    }
    if (hitServer) {
      e.preventDefault();
      window.location.assign(to);
    } else {
      return true;
    }
  };

  return <StyledLink {...props} onClick={handleClick}></StyledLink>;
};

export default LPLinkPlain;
