export interface Signup extends ApiResponse {
  id: number;
  firstName: string;
  lastName: string;
  title: string;
  creditUnion: string;
  contactInfo: string;
  phoneNumber: string;
  routingNumber: string;
  username: string;
  password: string;
  confirmPassword: string;
}

export type ApiResponse = Record<string, any>;

export enum SignupActionTypes {
  SIGNUP_REQUEST = '@@signup/SIGNUP_REQUEST',
  SIGNUP_SUCCESS = '@@signup/SIGNUP_SUCCESS',
  SIGNUP_ERROR = '@@signup/SIGNUP_ERROR',
}

export interface SignupState {
  readonly loading: boolean;
  readonly data: Signup;
  readonly errors?: string;
}
