import { Button, Drawer, IconButton, useMediaQuery } from '@material-ui/core';
import { createStyles, darken, makeStyles } from '@material-ui/core/styles';
import { AccountCircle } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { useState } from 'react';
import styled from 'styled-components';
import { muiTheme } from 'utils/theme';
import { LogInOrAccountIconButton, LogInOrOutButton } from '../../components/AccountButtons';
import LPLinkPlain from '../../components/Common/LPLinkPlain';
import { UserContext } from '../../components/data/context';
let location = window.location;

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      margin: `0 ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
      marginLeft: 40,
      fontWeight: 'bold',
    },
    activeButton: {
      backgroundColor: theme.colors.veryLightBlue,
    },
    list: {
      width: 250,
      '& button:hover': {
        backgroundColor: 'transparent',
      },
      display: 'flex',
      flexDirection: 'column',
    },
    fullList: {
      width: 'auto',
    },
    buttonLogo: {
      [theme.breakpoints.between('xs', 'sm')]: {},
    },
    buttonLogoImage: {
      height: 'auto',
      width: '100%',
    },
    listItemMobile: {
      padding: 30,
      fontWeight: 600,
      fontSize: '1.25em',
      '&:hover': {
        backgroundColor: darken(theme.colors.paleGrey, 0.05),
      },
    },
    loginButton: { width: '90%', margin: '0 auto', fontWeight: 600, fontSize: 18, marginTop: 8 },
  })
);

const navLinks = [
  { text: 'buy', path: '/buy' },
  { text: 'sell', path: '/sell' },
  { text: 'learn', path: '/learn' },
  { text: 'contact', path: '/contact' },
];

const NavLinks = () => {
  const classes = useStyles();
  return (
    <>
      {navLinks.map(({ text, path }) => (
        <div key={`${text}-${path}`}>
          <LPLinkPlain to={path}>
            <Button
              className={clsx(classes.button, {
                [classes.activeButton]: location.pathname === path,
              })}
            >
              {text}
            </Button>
          </LPLinkPlain>
        </div>
      ))}
    </>
  );
};

const DefaultNav = () => {
  return (
    <DefaultNavigation>
      <NavLinks />
      <LogInOrAccountIconButton />
    </DefaultNavigation>
  );
};

const SideList = props => {
  const classes = useStyles(props);
  return (
    <div className={classes.list} role="presentation">
      {navLinks.map(({ text, path }) => (
        <LPLinkPlain
          key={`${text}-${path}`}
          to={path}
          className={clsx(classes.listItemMobile, {
            [classes.activeButton]: location.pathname === path,
          })}
        >
          {text}
        </LPLinkPlain>
      ))}
      <LogInOrOutButton />
    </div>
  );
};

const MobileNav: React.FC<{}> = () => {
  const [open, setOpen] = useState(false);
  const user = React.useContext(UserContext);
  const loggedIn = user.authenticated;

  const openNav = () => {
    setOpen(true);
  };
  const closeNav = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={openNav}>
        <MenuIcon fontSize={'large'} />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={closeNav}>
        <MobileNavigation>
          <nav>
            {loggedIn && (
              <div className="user-nav-item">
                <AccountCircle />
                <span>{user.name}</span>
              </div>
            )}
            <SideList />
          </nav>
        </MobileNavigation>
      </Drawer>
    </>
  );
};

export const HeaderNavBarMenu: React.FC = () => {
  const isMobile = useMediaQuery(muiTheme.breakpoints.down(muiTheme.breakpoints.values.md));

  return isMobile ? <MobileNav /> : <DefaultNav />;
};

const DefaultNavigation = styled.nav`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(${navLinks.length + 1}, max-content);
  gap: 2em;
  justify-content: flex-end;
  justify-items: flex-end;
  align-items: center;
  align-content: center;

  button {
    margin: 0;
    align-self: flex-end;
  }
`;

const MobileNavigation = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  z-index: 20000 !important;
  button {
    margin: 0.5em;
  }

  .user-nav-item {
    padding: 1em 1em;
    display: flex;
    align-content: center;
    align-items: center;
    span {
      margin-left: 1em;
    }
  }
`;
