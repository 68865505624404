import React from 'react';
import { Checkbox, makeStyles, TableRow, Theme } from '@material-ui/core';
import clsx from 'clsx';
import LPTableCell from '../LPTableCell';
import LPLink from '../LPLink';
import { colors } from 'utils/theme';
import { lighten } from '@material-ui/core/styles';
import shortid from 'shortid';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      borderBottom: (props: any) => (props.isDetailView ? 'none' : '1px solid #114fa7'),
      '&:hover': {
        backgroundColor: colors.paleGrey,
        cursor: 'pointer',
      },
    },
    active: {
      backgroundColor: colors.paleGrey,
    },
    highlighted: {
      backgroundColor: lighten(colors.paleGrey, 0.4),
    },
    transitionEffect: {
      animation: '$highlight 2000ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    body: {
      border: 'none',
    },
    '@keyframes highlight': {
      '0%': {
        backgroundColor: lighten(theme.colors.peacockBlue, 0.4),
      },
      '100%': {
        backgroundColor: 'none',
      },
    },
  };
});

export const LPTableRowMain = props => {
  const classes = useStyles(props);
  const {
    row,
    headers,
    isDetailView = false,
    isNew,
    selectable = false,
    showDetailView,
    keyIdentifier,
    handleClick,
    checked,
    onSelect,
  } = props;

  return (
    <TableRow
      className={clsx(classes.root, {
        [classes.active]: showDetailView,
        [classes.highlighted]: isDetailView,
        [classes.transitionEffect]: isNew,
      })}
      onClick={e => handleClick(e)}
    >
      {selectable && (
        <LPTableCell showBorder={showDetailView}>
          <Checkbox checked={checked} onChange={e => onSelect(e, row[keyIdentifier])} />
        </LPTableCell>
      )}

      {headers
        .map(h => h.keyOrGetter)
        .map((attribute, i) => {
          const value =
            typeof attribute === 'function'
              ? attribute(row)
              : attribute === ''
              ? ''
              : row[attribute];

          const CustomComponent = () => (headers[i].Component ? headers[i].Component(row) : null);

          return (
            <LPTableCell showBorder={showDetailView} key={shortid.generate()}>
              {headers[i].Component ? (
                <CustomComponent />
              ) : headers[i].path ? (
                <LPLink to={headers[i].path(row)}>{value}</LPLink>
              ) : value ? (
                value.toString()
              ) : (
                ''
              )}
            </LPTableCell>
          );
        })}
    </TableRow>
  );
};
