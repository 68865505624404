import Button from '@material-ui/core/Button';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { paths } from '../store/routePaths';
import { fluidPadding, media } from '../utils/styles';
import styled from 'styled-components';

export const Footer = withRouter(({ location, history }) => {
  const home = location.pathname === '/';
  return (
    <Wrapper>
      <nav>
        <Button component="a" onClick={() => history.push('/')}>
          Home
        </Button>

        <Button component="a" onClick={() => history.push(paths.buy)}>
          Buy
        </Button>

        <Button component="a" onClick={() => history.push(paths.sell)}>
          Sell
        </Button>
        <Button component="a" onClick={() => history.push(paths.learn)}>
          Learn
        </Button>
        <Button component="a" onClick={() => history.push(paths.faqs)}>
          FAQs
        </Button>
        <Button component="a" onClick={() => history.push(paths.contact)}>
          Contact
        </Button>
      </nav>
      <Button component="a" onClick={() => history.push(paths.termsofuse)}>
        Terms Of Use
      </Button>
      <Button component="a" onClick={() => history.push(paths.privacy)} className="privacy">
        Privacy Policy
      </Button>

      {home && (
        <Button className="top" onClick={() => window.location.assign('/#')}>
          <ArrowUpwardIcon />
        </Button>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.footer`
  ${fluidPadding};
  padding-top: 1em;
  padding-bottom: 1em;
  display: grid;
  z-index: 1000;
  height: 350px;
  min-height: 350px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  ${media.greaterThan('sm')`
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  `};
  justify-content: space-between;
  text-align: center;
  gap: 2em 0;
  background-color: #dadbdd;
  button {
    font-weight: 400;
  }

  nav {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .top {
    border: 1px solid #ccc;
    justify-self: center;
    button {
      border-radius: 0;
      margin: 0;
    }
    & svg {
      color: ${props => props.theme.colors.navyBlue};
    }
  }

  button {
    align-self: center;
    justify-self: center;
  }

  .privacy {
    ${media.greaterThan('sm')`
    justify-self: center;
    `};
    :hover {
      text-decoration: underline;
      text-decoration-color: ${props => props.theme.colors.lipstick};
    }
  }

  ${media.greaterThan('md')`
    grid-template-columns: 4fr repeat(2, auto);
    grid-template-rows: 1fr;
    height: 75px;
    min-height: 75px;
    justify-content: space-between;
    align-items: center;
    nav {
      flex-direction: row;
    }
    .top {
      margin-left: 2em;
    }
  `}
`;
