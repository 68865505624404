import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { Fragment, useEffect, useRef, useCallback } from 'react';
import usePrevious from 'react-use/lib/usePrevious';
import styled from 'styled-components';
import { action } from 'typesafe-actions';
import _ from 'underscore';
import { LPTableInner } from './LPTableInner';
import LPTableShowAll from './LPTableShowAll';
import { filter, loadMore, TableActionTypes, useTable } from './table-context';
import {
  Filter,
  Header,
  INITIAL_LIMIT,
  LPTableProps,
  Order,
  SUBSEQUENT_LIMIT,
  SUPER_LARGE_LIMIT,
  TableWrapperProps,
} from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    button: {
      color: theme.colors.white,
      backgroundColor: theme.colors.loadMoreBlue,
      padding: '5px 35px',
      fontWeight: 600,
    },
    loadMoreButton: {
      '&:hover': {
        backgroundColor: darken(theme.colors.loadMoreBlue, 0.2),
      },
    },
    loadMoreProgress: {
      position: 'absolute',
      top: '30%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    loadMoreInstigator: {
      height: 100,
      position: 'relative',
    },
  })
);

const TableWrapper = styled('div')<TableWrapperProps>`
  position: relative;
  max-height: ${props => (props.tableMaxHeight ? props.tableMaxHeight : 'auto')};
  min-height: 250px;
  width: ${props => (props.tableWidth ? props.tableWidth : 'auto')};
  overflow-y: ${props => (props.tableMaxHeight ? 'auto' : 'auto')};
`;

const addFilterParams = (filterState: Filter, queryParams: URLSearchParams) => {
  if (filterState.text && filterState.serverField && filterState.triggerRequest) {
    queryParams.append('$filter', `${filterState.serverField} ne null`);
    queryParams.append(
      '$filter',
      `contains(tolower(${filterState.serverField}), '${filterState.text.replace("'", "''")}')`
    );
  }
};

const addOrderParams = (orderState: Order, queryParams: URLSearchParams) => {
  if (orderState.serverField) {
    queryParams.append('$orderby', `${orderState.serverField} ${orderState.direction}`);
  }
};

export const LPTableContainer: React.FC<LPTableProps> = props => {
  const {
    endpoint,
    headers,
    persistentFilterParams = undefined,
    loadAll = false,
    maxHeight = '600px',
    width,
    metaHeader,
  } = props;
  const classes = useStyles(undefined);
  const [tableState, dispatch] = useTable();
  const {
    data,
    limit,
    offset,
    filter: filterState,
    order,
    loading,
    noMoreToLoad,
    filterLoading,
    showAllLoading,
  } = tableState;
  const firstRender = useRef(true);
  const tableWrapperRef = useRef(null);
  const loadMoreInstigatorRef = useRef(null);

  const prevLimit = usePrevious(limit) || 0;
  const prevFilterState = usePrevious(filterState) || {};

  const doLoadMore = useCallback(() => {
    loadMore(dispatch, endpoint, buildQueryParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, endpoint]);

  const doFilter = useCallback(() => {
    filter(dispatch, endpoint, buildQueryParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, endpoint]);

  const isLoadMoreInstigatorVisible = () => {
    let el = loadMoreInstigatorRef.current;
    if (!el) return;
    let holder = tableWrapperRef.current;
    const { top, bottom, height } = el.getBoundingClientRect();
    const holderRect = holder.getBoundingClientRect();
    let isVisible =
      top <= holderRect.top ? holderRect.top - top <= height : bottom - holderRect.bottom <= height;
    if (isVisible && !loading && !noMoreToLoad) {
      handleLoadMore();
    }
  };

  const isLoadMoreInstigatorVisibleThrottled = _.throttle(isLoadMoreInstigatorVisible, 500);

  const handleLoadMore = () => {
    dispatch(
      action(TableActionTypes.UPDATE_OFFSET, offset + (prevLimit ? prevLimit : INITIAL_LIMIT))
    );
    dispatch(action(TableActionTypes.UPDATE_LIMIT, SUBSEQUENT_LIMIT));
  };

  // scroll event listener for LoadMoreInstigator
  useEffect(() => {
    const wrapperRef = tableWrapperRef.current;
    wrapperRef.addEventListener('scroll', isLoadMoreInstigatorVisibleThrottled);
    return () => {
      wrapperRef.removeEventListener('scroll', isLoadMoreInstigatorVisibleThrottled);
    };
  }, [offset, limit, loading, noMoreToLoad, isLoadMoreInstigatorVisibleThrottled]);

  // initial load
  useEffect(() => {
    doFilter();
  }, [doFilter]);

  // scroll table to top after filter or order
  useEffect(() => {
    const wrapperRef = tableWrapperRef.current;
    wrapperRef.scrollTop = 0;
  }, [filterLoading]);

  // load more
  useEffect(() => {
    if (!firstRender.current && offset !== 0) {
      doLoadMore();
    }
  }, [offset, limit, doLoadMore]);

  // filter
  useEffect(() => {
    // we don't want to make extraneous requests, so only run if:
    // it's not the first render and there is an associated server field OR
    // the filter was cleared
    if (
      (!firstRender.current && filterState.serverField) ||
      (filterState.cleared && prevFilterState.serverField)
    ) {
      doFilter();
    }
  }, [doFilter, filterState, prevFilterState.serverField]);

  // change persistent filter params
  const sPersistentFilterParams = JSON.stringify(persistentFilterParams);
  useEffect(() => {
    if (!firstRender.current) {
      doFilter();
    }
  }, [doFilter, sPersistentFilterParams]);

  // sort
  useEffect(() => {
    if (!firstRender.current && order.serverField) {
      doFilter();
    }
    firstRender.current = false;
  }, [order.serverField, order.direction, doFilter]);

  const buildQueryParams = useCallback(() => {
    const top = limit === Number.POSITIVE_INFINITY || loadAll ? SUPER_LARGE_LIMIT : limit;
    let queryParams = new URLSearchParams([
      ['$skip', offset],
      ['$top', top],
    ]);
    persistentFilterParams &&
      persistentFilterParams.forEach(keyValPair => {
        queryParams.append(keyValPair[0], keyValPair[1]);
      });
    addFilterParams(filterState, queryParams);
    addOrderParams(order, queryParams);
    return queryParams.toString();
  }, [filterState, limit, loadAll, offset, order, persistentFilterParams]);

  function handleSort(event: React.MouseEvent<unknown>, header: Header) {
    const isDesc = order.direction === 'desc';
    dispatch(
      action(TableActionTypes.UPDATE_ORDER, {
        direction: isDesc ? 'asc' : 'desc',
        field: header.keyOrGetter,
        serverField: header.serverField,
        label: header.label,
      })
    );
  }

  const handleFilter = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: Order['field'],
    reset = false
  ) => {
    if (reset) {
      dispatch(action(TableActionTypes.CLEAR_FILTER));
    } else {
      const header = headers.find(h => h.label === field);
      dispatch(
        action(TableActionTypes.UPDATE_FILTER, {
          text: e.target.value.toLowerCase(),
          field: header.keyOrGetter,
          serverField: header.serverField,
          triggerRequest: header.serverField !== undefined,
          cleared: false,
        })
      );
    }
  };

  const handleFilterDebounced = _.debounce((e, field, reset) => handleFilter(e, field, reset), 400);

  function handleShowAll(event) {
    dispatch(action(TableActionTypes.UPDATE_OFFSET, offset + prevLimit));
    dispatch(action(TableActionTypes.UPDATE_LIMIT, SUPER_LARGE_LIMIT));
  }

  const LoadMoreInstigator = () => {
    let content;
    if (data.length > 0 && loading) {
      content = <CircularProgress className={classes.loadMoreProgress} color="secondary" />;
    } else if (data.length > 0 && noMoreToLoad) {
      content = (
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            paddingTop: 35,
          }}
        >
          No more records in database.
        </p>
      );
    }
    if (loadAll || filterLoading || showAllLoading) {
      return null;
    }
    return (
      <div className={classes.loadMoreInstigator} ref={loadMoreInstigatorRef}>
        {content}
      </div>
    );
  };

  return (
    <Fragment>
      {metaHeader ? metaHeader() : null}
      <TableWrapper
        tableMaxHeight={maxHeight}
        tableWidth={width}
        className={clsx(classes.root, 'LPTableWrapper')}
        ref={tableWrapperRef}
      >
        <LPTableInner
          onFilter={[handleFilter, handleFilterDebounced]}
          onSort={handleSort}
          {...props}
        />
        <LoadMoreInstigator />
      </TableWrapper>
      {!loadAll && <LPTableShowAll onShowAll={handleShowAll} />}
    </Fragment>
  );
};
