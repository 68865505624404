import { action } from 'typesafe-actions';
import { InterestActionTypes } from './types';

export const fetchRequest = (queryParams: Object) =>
  action(InterestActionTypes.FETCH_REQUEST, queryParams);
export const fetchSuccess = (data: any[]) => action(InterestActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(InterestActionTypes.FETCH_ERROR, message);
export const clearRequest = () => action(InterestActionTypes.CLEAR_REQUEST);
export const fetchInterestRequest = (id: string) =>
  action(InterestActionTypes.FETCH_INTEREST_REQUEST, id);
export const fetchInterestSuccess = (data: any[]) =>
  action(InterestActionTypes.FETCH_INTEREST_SUCCESS, data);
export const fetchInterestError = (message: string) =>
  action(InterestActionTypes.FETCH_INTEREST_ERROR, message);
