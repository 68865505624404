import { TextField, TextFieldProps } from '@material-ui/core';
import React, { Fragment } from 'react';
import styled from 'styled-components';

const sameProps: TextFieldProps = {
  variant: 'filled',
  InputProps: {
    disableUnderline: true,
  },
  InputLabelProps: {
    shrink: true,
  },
};
export const UserInfoInputFields = ({
  user,
  notification,
  usePhoneForSMS,
  toggleUsePhoneForSMS,
  handleInputChange,
}) => {
  return (
    <Fragment>
      <Grouped>
        <TextField
          type="text"
          label="First Name"
          name="givenName"
          value={user.givenName}
          onChange={handleInputChange}
          {...sameProps}
        />

        <TextField
          type="text"
          label="Last Name"
          name="surname"
          value={user.surname}
          onChange={handleInputChange}
          {...sameProps}
        />
      </Grouped>

      <TextField
        type="text"
        label="Job Title"
        name="jobTitle"
        value={user.jobTitle}
        onChange={handleInputChange}
        {...sameProps}
      />

      <TextField
        type="text"
        label="Credit Union"
        name="creditUnionName"
        value={user.creditUnionName}
        disabled
        onChange={handleInputChange}
        {...sameProps}
      />

      <TextField
        type="number"
        label="RT Number"
        name="rtNumber"
        value={user.rtNumber}
        disabled
        onChange={handleInputChange}
        {...sameProps}
      />

      <TextField
        type="email"
        label="Email Address"
        name="emailAddress"
        value={user.emailAddress}
        disabled
        onChange={handleInputChange}
        {...sameProps}
      />

      <Grouped>
        <TextField
          id="phone"
          type="phone"
          label="Phone Number"
          name="phoneNumber"
          value={user.phoneNumber}
          onChange={handleInputChange}
          {...sameProps}
        />

        <UsePhoneForSMSForm>
          <TextField
            id="smsNumber"
            type="phone"
            label="Cell Phone"
            name="cellPhone"
            value={user.cellPhone}
            onChange={handleInputChange}
            {...sameProps}
          />
        </UsePhoneForSMSForm>
      </Grouped>
    </Fragment>
  );
};

const UsePhoneForSMSForm = styled.div`
  display: flex;
  flex-direction: column;
`;
const Grouped = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > * {
    flex: 1 1 auto;
  }

  & > *:first-child {
    margin-right: 20px;
  }
`;
