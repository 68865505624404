import { action } from 'typesafe-actions';
import { DownloadActionTypes } from './types';

export const fetchRequest = (queryParams: Object) =>
  action(DownloadActionTypes.FETCH_REQUEST, queryParams);
export const fetchSuccess = (data: any[]) => action(DownloadActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(DownloadActionTypes.FETCH_ERROR, message);

export const fetchDownloadRequest = (id: string) =>
  action(DownloadActionTypes.FETCH_DOWNLOAD_REQUEST, id);
export const fetchDownloadSuccess = (data: any[]) =>
  action(DownloadActionTypes.FETCH_DOWNLOAD_SUCCESS, data);
export const fetchDownloadError = (message: string) =>
  action(DownloadActionTypes.FETCH_DOWNLOAD_ERROR, message);
