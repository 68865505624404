import { makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { BackButton } from 'components/Common/BackButton';
import { UserContext } from 'components/data/context';
import DueDiligenceDocuments from 'pages/lp/due-dilligence/DueDiligenceDocuments';
import { AdminActionBar } from 'pages/lp/show/AdminActionBar';
import React, { Fragment, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { muiTheme } from 'utils/theme';

const AdminDueDiligencePage = props => {
  const classes = useStyles();
  const isMobile = useMediaQuery(muiTheme.breakpoints.between('xs', 'md'));
  const user = useContext(UserContext);
  const { id: lpNumber } = useParams();
  if (!user.completedMFA) {
    window.location.assign(`/api/auth/pii/lp/${lpNumber}`);
    return;
  }

  return (
    <Fragment>
      <div className={classes.header}>
        {!isMobile ? <BackButton classes={{ icon: classes.icon }} {...props} /> : null}
        <div className={classes.headerContent}>
          <div className={classes.title}>
            {isMobile ? 'LP' : 'LOAN PARTICIPATION PORTFOLIO'} #{lpNumber}
          </div>
          <p className={classes.descriptiveText}>
            Here you can look through the due diligence documents a user will see.
          </p>
        </div>
      </div>

      <div className={classes.root}>
        <Typography variant="h4" style={{ textAlign: 'center', fontWeight: 600 }}>
          Due Diligence Documents
        </Typography>
        <DueDiligenceDocuments {...props} classes={{ root: classes.root }} />
      </div>
      <AdminActionBar {...props} />
    </Fragment>
  );
};

export default AdminDueDiligencePage;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.colors.white,
    margin: '50px 150px',
    [theme.breakpoints.between('xs', 'md')]: {
      margin: '50px 10px',
    },
  },
  header: {
    marginTop: 45,
    display: 'flex',
    marginBottom: 45,
    paddingTop: 25,
    paddingRight: 50,
    [theme.breakpoints.between('xs', 'md')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  icon: {
    fontSize: 40,
    color: theme.colors.blackTwo,
    lineHeight: 1.23,
    marginTop: -5,
  },
  title: {
    fontSize: 50,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    lineHeight: 1.22,
    color: theme.colors.blackTwo,
    wordBreak: 'break-word',
    [theme.breakpoints.between('xs', 'md')]: {
      textAlign: 'center',
    },
    '& span': {
      fontSize: 30,
      color: darken(theme.colors.paleGrey, 0.2),
      display: 'inline-block',
      verticalAlign: 'top',
      marginTop: 10,
      marginLeft: 10,
    },
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  descriptiveText: {
    fontWeight: 600,
  },
}));
