export enum OfferingActionTypes {
  FETCH_REQUEST = '@@offering/FETCH_REQUEST',
  FETCH_SUCCESS = '@@offering/FETCH_SUCCESS',
  FETCH_ERROR = '@@offering/FETCH_ERROR',
  FILTER_REQUEST = '@@offering/FILTER_REQUEST',
  FILTER_SUCCESS = '@@offering/FILTER_SUCCESS',
  FILTER_ERROR = '@@offering/FILTER_ERROR',
  SELECT_REQUEST = '@@offering/SELECT_REQUEST',
  SELECT_SUCCESS = '@@offering/SELECT_SUCCESS',
  SELECT_ERROR = '@@offering/SELECT_ERROR',
  FETCH_LP_REQUEST = '@@offering/FETCH_LP_REQUEST',
  FETCH_LP_SUCCESS = '@@offering/FETCH_LP_SUCCESS',
  FETCH_LP_ERROR = '@@offering/FETCH_LP_ERROR',
  PUBLISH_REQUEST = '@@offering/PUBLISH_REQUEST',
  PUBLISH_SUCCESS = '@@offering/PUBLISH_SUCCESS',
  PUBLISH_ERROR = '@@offering/PUBLISH_ERROR',
  UNPUBLISH_REQUEST = '@@offering/UNPUBLISH_REQUEST',
  UNPUBLISH_SUCCESS = '@@offering/UNPUBLISH_SUCCESS',
  UNPUBLISH_ERROR = '@@offering/UNPUBLISH_ERROR',
  UPDATE_OFFERING_REQUEST = '@@offering/UPDATE_OFFERING_REQUEST',
  UPDATE_OFFERING_SUCCESS = '@@offering/UPDATE_OFFERING_SUCCESS',
  UPDATE_OFFERING_ERROR = '@@offering/UPDATE_OFFERING_ERROR',
}

export interface OfferingState {
  readonly loading: boolean;
  readonly data?: any;
  readonly errors?: string;
}
