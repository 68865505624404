import { action } from 'typesafe-actions';
import {
  UserManagementActionTypes,
  UserInfo,
  AccountNumber,
  SalesforceAccountQuery,
  AcceptUser,
  VerifyUserSubmission,
} from './types';

export const fetchRequest = (queryParams: Object) =>
  action(UserManagementActionTypes.FETCH_REQUEST, queryParams);
export const fetchSuccess = (data: UserInfo[]) =>
  action(UserManagementActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) =>
  action(UserManagementActionTypes.FETCH_ERROR, message);
export const clearRequested = () => action(UserManagementActionTypes.CLEAR_REQUESTED);
export const fetchResourceRequest = (pathAndQueryParams: string) =>
  action(UserManagementActionTypes.FETCH_RESOURCE_REQUEST, pathAndQueryParams);
export const fetchResourceSuccess = (data: UserInfo[]) =>
  action(UserManagementActionTypes.FETCH_RESOURCE_SUCCESS, data);
export const fetchResourceError = (message: string) =>
  action(UserManagementActionTypes.FETCH_RESOURCE_ERROR, message);
export const clearFetchedResource = () => action(UserManagementActionTypes.CLEAR_FETCH_RESOURCE);
export const filterRequest = (queryParams: Object) =>
  action(UserManagementActionTypes.FILTER_REQUEST, queryParams);
export const filterSuccess = (data: UserInfo[]) =>
  action(UserManagementActionTypes.FILTER_SUCCESS, data);
export const filterError = (message: string) =>
  action(UserManagementActionTypes.FILTER_ERROR, message);
export const clearSelected = () => action(UserManagementActionTypes.CLEAR_SELECTED);
export const selectUser = (userId: string) => action(UserManagementActionTypes.SELECT_USER, userId);
export const userSelected = (user: UserInfo) => action(UserManagementActionTypes.SELECTED, user);
export const sfAccountNumberRequest = (query: SalesforceAccountQuery) =>
  action(UserManagementActionTypes.SF_ACCOUNTNUMBER_REQUEST, query);
export const sfAccountNumberSuccess = (data: AccountNumber[]) =>
  action(UserManagementActionTypes.SF_ACCOUNTNUMBER_SUCCESS, data);
export const sfAccountNumberSelect = (routingNumber: string) =>
  action(UserManagementActionTypes.SF_ACCOUNTNUMBER_SELECT, routingNumber);
export const sfAccountNumberError = (message: string) =>
  action(UserManagementActionTypes.SF_ACCOUNTNUMBER_ERROR, message);
export const clearSfAccountNumbers = () =>
  action(UserManagementActionTypes.CLEAR_SF_ACCOUNTNUMBERS);
export const updateUserRequest = (
  id: string,
  patch: any,
  verifyUserSubmission?: VerifyUserSubmission
) =>
  action(UserManagementActionTypes.UPDATE_USER_REQUEST, {
    id: id,
    data: patch,
    verifyUserSubmission: verifyUserSubmission,
  });
export const updateUserSuccess = (data: UserInfo) =>
  action(UserManagementActionTypes.UPDATE_USER_SUCCESS, data);
export const updateUserError = (message: string) =>
  action(UserManagementActionTypes.UPDATE_USER_ERROR, message);

export const acceptUserRequest = (request: AcceptUser) =>
  action(UserManagementActionTypes.ACCEPT_USER_REQUEST, request);
export const acceptUserSuccess = (id: string) =>
  action(UserManagementActionTypes.ACCEPT_USER_SUCCESS, id);
export const acceptUserError = (message: string) =>
  action(UserManagementActionTypes.ACCEPT_USER_ERROR, message);
export const clearAccepted = () => action(UserManagementActionTypes.CLEAR_ACCEPTED);
export const updateUserSigningAuthorityRequest = (id: string, patch: any) =>
  action(UserManagementActionTypes.UPDATE_USER_SIGN_AUTHORITY_REQUEST, {
    id: id,
    data: patch,
  });
export const updateUserSigningAuthoritySuccess = (data: UserInfo) =>
  action(UserManagementActionTypes.UPDATE_USER_SIGN_AUTHORITY_SUCCESS, data);
