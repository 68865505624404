/* eslint-disable react-hooks/exhaustive-deps */
import * as jsonpatch from 'fast-json-patch';
import React, { ChangeEvent, useContext, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { UserContext } from 'components/data/context';
import { userNotificationsRequest, userNotificationUpdateRequest } from 'store/auth/actions';
import { AuthState } from 'store/auth/types';
import { updateUserRequest } from 'store/user-management/actions';
import { UserInfo } from 'store/user-management/types';
import { get } from 'utils/api';
import { useParams, useLocation } from 'react-router-dom';

export interface NotificationPreference {
  category: string;
  name: string;
  availableDestinations: string[];
  emailEnabled: boolean;
  smsEnabled: boolean;
  description: string;
}

export interface Notification {
  partitionKey: string;
  lastUpdate?: any;
  userId: string;
  smsNumber?: any;
  NotificationPreferences: NotificationPreference[];
  id: string;
  _etag?: any;
  typeName: string;
  version: number;
}

const initialState = {
  initialUser: undefined,
  initialNotifications: undefined,
  user: undefined,
  userRoles: undefined,
  notification: undefined,
  mounted: false,
  userPatch: (initialUser, currentUser) => jsonpatch.compare(initialUser, currentUser),
  notificationPatch: (initialUser, currentUser) => jsonpatch.compare(initialUser, currentUser),
  usePhoneForSMS: undefined,
  submitting: false,
};

type State = {
  initialUser?: UserInfo;
  initialNotifications?: Notification;
  user?: UserInfo;
  userRoles?: any[];
  notification?: Notification;
  notifications?: NotificationPreference[];
  mounted: boolean;
  userPatch: (initialUser: UserInfo, currentUser: UserInfo) => jsonpatch.Operation[];
  notificationPatch: (
    initialUser: Notification,
    currentUser: Notification
  ) => jsonpatch.Operation[];
  usePhoneForSMS: boolean;
  submitting: boolean;
};

export const actions = {
  INPUT_CHANGE: 'INPUT_CHANGE',
  TOGGLE_NOTIFICATION: 'TOGGLE_NOTIFICATION',
  TOGGLE_USE_PHONE_FOR_SMS: 'TOGGLE_USE_PHONE_FOR_SMS',
  SET_INITIAL_USE_PHONE_FOR_SMS: 'SET_INITIAL_USE_PHONE_FOR_SMS',
  TOGGLE_PERMISSION: 'TOGGLE_PERMISSION',
  SET_USER: 'SET_USER',
  SET_USER_ROLES: 'SET_USER_ROLES',
  SET_INITIAL_NOTIFICATIONS: 'SET_INITIAL_NOTIFICATIONS',
  MOUNT: 'MOUNT',
  TOGGLE_SUBMITTING: 'TOGGLE_SUBMITTING',
  UNMOUNT: 'UNMOUNT',
};

type Action = {
  type: typeof actions[keyof typeof actions];
  payload?: any;
};

export const toBool: (pred: any) => boolean = pred => {
  if (typeof pred === 'string') {
    if (pred === 'False') {
      return false;
    }
    if (pred === 'True') {
      return true;
    }
  }

  if (typeof pred === 'boolean') {
    return pred;
  }
  if (pred === null || pred === undefined) {
    return false;
  }
  return false;
};

const reducer: (state: State, action: Action) => State = (state, action) => {
  switch (action.type) {
    /*
    ? Set initial User
    ** This is the default redux state for "auth.user"
    */
    case actions.SET_USER: {
      return { ...state, initialUser: action.payload, user: action.payload };
    }

    case actions.SET_USER_ROLES: {
      return { ...state, userRoles: action.payload };
    }

    /*
    ? Set initial notifications
    ** These are filtered to display each option that is not marked "Hidden"
    */
    case actions.SET_INITIAL_NOTIFICATIONS: {
      let payload: Notification;
      payload = action.payload;
      return {
        ...state,
        initialNotifications: payload,
        notification: payload,
      };
    }

    /*
    ? Toggle Notification Checkbox
    ** payload needs to be an object of
    {
      index: index of the notification
      type: used for object key - either "smsEnabled" or "emailEnabled"
    }
    finds the notification from index and key and sets it to the opposite value
    */
    case actions.TOGGLE_NOTIFICATION: {
      let payload: { type: string; index: number };
      payload = action.payload;
      return {
        ...state,
        notification: {
          ...state.notification,
          NotificationPreferences: state.notification.NotificationPreferences.map((pref, index) =>
            index !== payload.index
              ? pref
              : {
                  ...pref,
                  [payload.type]: !pref[payload.type],
                }
          ),
        },
      };
    }

    case actions.TOGGLE_USE_PHONE_FOR_SMS: {
      return {
        ...state,
        notification: {
          ...state.notification,
        },
      };
    }

    case actions.TOGGLE_PERMISSION: {
      let payload: { field: string };
      payload = action.payload;

      // need to set disableReports and disableUpload to null if it is false instead of actually false
      // in order to be consistent with what comes back from the API
      let value: any = !toBool(state.user[payload.field]);
      if (payload.field === 'disableReports' || payload.field === 'disableUpload') {
        if (value === false) {
          if (state.initialUser[payload.field] === null) {
            value = null;
          } else {
            value = 'False';
          }
        } else {
          value = 'True';
        }
      }
      return {
        ...state,
        user: {
          ...state.user,
          [payload.field]: value,
        },
      };
    }

    /*
    ? Handle Input Change
    ** Changes user info on input change
    */
    case actions.INPUT_CHANGE: {
      let payload: { name: string; value: string };
      payload = action.payload;
      if (payload.name === 'smsNumber') {
      }
      return {
        ...state,

        user: { ...state.user, ...state.notification.smsNumber, [payload.name]: payload.value },
        notification: { ...state.notification, [payload.name]: payload.value },
      };
    }

    /*
    ? Sets mounted to true
    ** Set this to true once you have all your data you need for component to render
    */
    case actions.MOUNT: {
      return { ...state, mounted: true };
    }

    case actions.UNMOUNT: {
      return { ...state, mounted: false };
    }

    case actions.TOGGLE_SUBMITTING: {
      return { ...state, submitting: !state.submitting };
    }
    default:
      return state;
  }
};

const useUserProfileReducer = () => {
  const { id: userId } = useParams();
  const { pathname } = useLocation();
  const dis = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const auth = useSelector<ApplicationState, AuthState>(state => state.auth);
  const currentUser = useContext(UserContext);

  const setUser = user => {
    dispatch({ type: actions.SET_USER, payload: user });
  };

  const setUserRoles = roles => {
    dispatch({ type: actions.SET_USER_ROLES, payload: roles });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.currentTarget;

    dispatch({
      type: actions.INPUT_CHANGE,
      payload: { name, value },
    });
  };

  const toggleNotification = (index: number, type: string) =>
    dispatch({ type: actions.TOGGLE_NOTIFICATION, payload: { index, type } });

  const toggleUsePhoneForSMS = () => dispatch({ type: actions.TOGGLE_USE_PHONE_FOR_SMS });

  const toggleSubmitting = () => dispatch({ type: actions.TOGGLE_SUBMITTING });

  const togglePermission = (field: string) =>
    dispatch({ type: actions.TOGGLE_PERMISSION, payload: { field } });

  const updateUser = () =>
    dis(updateUserRequest(state.user.objectId, state.userPatch(state.initialUser, state.user)));

  const updateUserNotifications = () => {
    dis(
      userNotificationUpdateRequest(state.user.objectId, {
        ...state.notification,
        smsNumber: state?.user?.smsNumber,
      })
    );
  };

  const handleSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void = event => {
    event.preventDefault();
    toggleSubmitting();
    Promise.all([updateUser(), updateUserNotifications()]).then(() =>
      window.location.assign(pathname)
    );
  };

  const edited = () => {
    if (state?.initialUser && state?.initialNotifications)
      return (
        state.userPatch(state.initialUser, state.user).length !== 0 ||
        state.notificationPatch(state.initialNotifications, state.notification).length !== 0
      );
    return false;
  };

  const mount = () => dispatch({ type: actions.MOUNT });

  useEffect(() => {
    if (auth && !state.submitting) {
      if (auth.user && !auth.loading) {
        if (currentUser.admin && userId !== undefined && state.user === undefined) {
          get(`/usermanagement/users/${userId}`)
            .then(data => {
              setUser(data);
            })
            .catch(error => console.log('There was an error fetching the user', error));
          get(`/usermanagement/users/${userId}/roles`)
            .then(data => setUserRoles(data))
            .catch(error => console.log('There was an error fetching the user roles', error));
        } else if (state.user === undefined) {
          setUser(auth.user);
          setUserRoles(auth.data.Roles);
        }
        if (!auth.notification && state.user) dis(userNotificationsRequest(state.user.objectId));
      }

      if (auth.notification && !auth.loading) {
        if (!state.initialNotifications) {
          dispatch({
            type: actions.SET_INITIAL_NOTIFICATIONS,
            payload: auth.notification,
          });
        }
      }
    }

    if (!auth?.loading && auth?.notification && state?.user && state?.userRoles) {
      mount();
    }
  }, [auth.user, auth.notification, state.user, state.userRoles, state.mounted, dis]);

  return {
    ...state,
    toggleNotification,
    handleInputChange,
    handleSubmit,
    togglePermission,
    toggleUsePhoneForSMS,
    dispatch,
    edited: edited(),
  };
};

export default useUserProfileReducer;
