import { UserInfo } from '../../store/user-management/types';

export interface AuthInfo extends ApiResponse {
  Name: string;
  AuthenticationType: string;
  IsAuthenticated: boolean;
  UserType: UserType;
  Roles: string[];
  Claims: any;
  UserObjectId: string;
  PhoneNumber: string;
  Email: string;
  RoutingNumber: string;
  CreditUnionAccountId: string;
  CreditUnionName: string;
}

type UserType = 'Partner' | 'Unverified' | 'Anonymous';

export type ApiResponse = Record<string, any>;

export enum AuthActionTypes {
  FETCH_REQUEST = '@@auth/FETCH_REQUEST',
  FETCH_SUCCESS = '@@auth/FETCH_SUCCESS',
  FETCH_ERROR = '@@auth/FETCH_ERROR',
  USER_SELECT_REQUEST = '@@auth/USER_SELECT_REQUEST',
  USER_SELECT_SUCCESS = '@@auth/USER_SELECT_SUCCESS',
  USER_SELECT_ERROR = '@@auth/USER_SELECT_ERROR',
  CLEAR_USER = '@@auth/CLEAR_USER',
  USER_NOTIFICAITON_REQUEST = '@@/USER_NOTIFICAITON_REQUEST',
  USER_NOTIFICAITON_SUCCESS = '@@/USER_NOTIFICAITON_SUCCESS',
  USER_NOTIFICAITON_UPDATE_REQUEST = '@@/USER_NOTIFICAITON_UPDATE_REQUEST',
  USER_NOTIFICAITON_UPDATE_SUCCESS = '@@/USER_NOTIFICAITON_UPDATE_SUCCESS',
  CLEAR_NOTIFICATION = '@@auth/CLEAR_NOTIFICATION',
}

export interface AuthState {
  readonly loading: boolean;
  readonly data?: AuthInfo;
  readonly errors?: string;
  readonly user?: UserInfo;
  readonly notification?: any;
}
