import { action } from 'typesafe-actions';
import { DocusignActionTypes, DocusignCreateCA, DocusignCAStatus } from './types';

export const docusignCreateCARequest = (query: DocusignCreateCA) =>
  action(DocusignActionTypes.DOCUSIGN_CREATE_CA_REQUEST, query);
export const docusignCreateCASuccess = (data: any) =>
  action(DocusignActionTypes.DOCUSIGN_CREATE_CA_SUCCESS, data);
export const docusignCreateCAError = (message: string) =>
  action(DocusignActionTypes.DOCUSIGN_CREATE_CA_ERROR, message);
export const docusignSyncRequest = (id: string) =>
  action(DocusignActionTypes.DOCUSIGN_SYNC_REQUEST, id);
export const docusignSyncSuccess = () => action(DocusignActionTypes.DOCUSIGN_SYNC_SUCCESS);
export const docusignSyncError = (message: string) =>
  action(DocusignActionTypes.DOCUSIGN_SYNC_ERROR, message);
export const docusignLatestCAStatusRequest = (userId: string) =>
  action(DocusignActionTypes.DOCUSIGN_LATEST_CA_STATUS_REQUEST, userId);
export const docusignLatestCAStatusSuccess = (data: DocusignCAStatus[]) =>
  action(DocusignActionTypes.DOCUSIGN_LATEST_CA_STATUS_SUCCESS, data);
export const docusignLatestCAStatusError = (message: string) =>
  action(DocusignActionTypes.DOCUSIGN_LATEST_CA_STATUS_ERROR, message);
export const docusignRejectCARequest = (envelopeId: string) =>
  action(DocusignActionTypes.DOCUSIGN_REJECT_CA_REQUEST, envelopeId);
export const docusignRejectCASuccess = () => action(DocusignActionTypes.DOCUSIGN_REJECT_CA_SUCCESS);
export const docusignRejectCAError = (message: string) =>
  action(DocusignActionTypes.DOCUSIGN_REJECT_CA_ERROR, message);
export const clearBulkCAStatus = () => action(DocusignActionTypes.CLEAR_BULK_CA_STATUS);
