import { Container, Icon, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'underscore';
import CC_LP_Exchange_Logo_Icon_Where from '../assets/images/CC_LP_Exchange_Logo_Icon_Where.svg';
import LPCard from '../components/Card';
import CMSIFrame from '../components/cms/iFrame';
import { AdapterLink } from '../components/Common/AdapterLink';
import { ContactForm } from '../components/ContactForm';
import { CMSContent } from '../components/data/CMSContent';
import useMediaQuery from '../hooks/useMediaQuery';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    blue: {
      backgroundColor: theme.colors.signupBlue,
      color: theme.colors.white,
      textShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    },
    lightBlue: {
      backgroundColor: '#eaf1f8',
      color: theme.colors.navyBlue,
    },
    button: {
      margin: theme.spacing(1),
      fontWeight: 600,
    },
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: theme.colors.lipstick,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    aboveFold: {
      display: 'flex',
      flexDirection: 'column',
    },
    aboveFoldInfo: {
      display: 'flex',
      color: theme.colors.black,
      [theme.breakpoints.between('xs', 'md')]: {
        flexWrap: 'wrap',
      },
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        margin: 20,
        padding: 50,
        fontSize: 18,
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1 1 500px',
        [theme.breakpoints.between('xs', 'md')]: {
          padding: 10,
        },
      },
      '& > div:nth-child(1)': {
        '& > p:first-child': {
          textAlign: 'left',
          alignSelf: 'flex-start',
        },
      },
      '& p': {
        fontStyle: 'italic',
      },
      '& video': {
        width: '100%',
        height: 'auto',
      },
    },
    cards: {
      display: 'flex',
      width: '90%',
      margin: '50px auto 0px auto',
      alignItems: 'center',
      [theme.breakpoints.between('xs', 'md')]: {
        flexWrap: 'wrap',
      },
    },
    card: {
      backgroundColor: theme.colors.darkSkyBlue,
      margin: 20,
      borderRadius: 10,
      boxShadow: '0 3px 20px 0 rgba(0, 0, 0, 0.16)',
      flex: '1 1 350px',
      padding: 20,
      height: 300,
      position: 'relative',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.colors.signupBlue,
        color: theme.colors.white,
        boxShadow: '0 3px 10px 0 #fff',
        WebkitBackdropFilter: 'blur(30px)',
        backdropFilter: 'blur(30px)',
      },
    },   
    cardContent: {
      height: '100%',
    },
    cardTitle: {
      textAlign: 'center',
      fontSize: 30,
      fontWeight: 600,
      marginTop: 5,
      marginBottom: 20,
    },
    scrollDown: {
      display: 'flex',
      justifyContent: 'center',
      opacity: 1,
      transition: 'opacity 0.3s ease',
      // make larger
      '& span': {
        width: '2em',
        height: '2em',
      },
      '& svg': {
        color: '#9a9a9a',
        fontSize: '2.5rem',
      },
    },
    paragraph: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 100,
      minHeight: 500,
      [theme.breakpoints.only('xs')]: {
        padding: 30,
      },
    },
    readyToStart: {
      textAlign: 'center',
      padding: 100,
      '& h2': {
        fontSize: 30,
        color: theme.colors.peacockBlue,
      },
    },
    valueDetail: {
      '& > div': {
        maxWidth: 800,
        margin: '0 auto',
      },
    },
    scrollHidden: {
      opacity: 0,
      transition: 'opacity 0.5s ease',
    },
    getInTouch: {
      minHeight: 500,
      textAlign: 'center',

      padding: 100,
      textShadow: 'none',
      '& h2': {
        fontWeight: 600,
        marginBottom: 15,
      },
      '& .MuiTypography-subtitle1': {
        fontWeight: 600,
        marginBottom: 50,
      },
      '& .lPContactForm': {
        display: 'block',
        justifyContent: 'center',
        margin: '0 auto',
      },
    },
    navLink: {
      color: theme.colors.blackTwo,
      textDecoration: 'none',
    },
  })
);

export const HomePage = () => {
  const classes = useStyles();
  const scrollArrow = useRef(null);
  const [scrollArrowHidden, setScrollArrowHidden] = useState(false);
  const { lg } = useMediaQuery();

  const testimonialsHeight = !lg ? '1450px' : '575px';

  const handleSignUpClick = () => {
    window.location.assign('/api/auth/signup');
  };

  const headerAnimation = () => {
    setScrollArrowHidden(
      scrollArrow.current && scrollArrow.current.offsetTop - window.pageYOffset < 350
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', _.throttle(headerAnimation, 100));
    return () => {
      window.removeEventListener('scroll', headerAnimation);
    };
  });

  return (
    <div>
      <section className={clsx(classes.aboveFold, classes.lightBlue)}>
        <div className={classes.aboveFoldInfo}>
          <div>
            <p>Welcome to the</p>
            <img src={CC_LP_Exchange_Logo_Icon_Where} alt="cc-lp-exchange-logo" />
            <CMSContent type="contentitem" name="home-welcome" />
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              component={AdapterLink}
              to={'/dashboard'}
            >
              START BROWSING
            </Button>
          </div>
          <div style={{ position: 'relative' }}>
            <CMSContent type="video" name="home-video" />
          </div>
        </div>
        <div className={classes.cards}>
          <LPCard page="buy" classes={classes} type="contentitem" name="home-buy" />
          <LPCard page="sell" classes={classes} type="contentitem" name="home-sell" />
          <LPCard page="learn" classes={classes} type="contentitem" name="home-learn" />
        </div>
        <div
          className={clsx(classes.scrollDown, {
            [classes.scrollHidden]: scrollArrowHidden,
          })}
          ref={scrollArrow}
        >
          <Icon>
            <KeyboardArrowDownSharpIcon />
          </Icon>
        </div>
      </section>
      <section className={clsx(classes.paragraph, classes.blue)}>
        <CMSContent type="contentitem" name="home-buyers-sellers" />
      </section>
      <section className={clsx(classes.paragraph, classes.lightBlue)}>
        <CMSContent type="contentitem" name="home-transparent-trusted" />
      </section>
      <section className={clsx(classes.paragraph, classes.blue)}>
        <CMSContent type="contentitem" name="home-service-excellence" />
      </section>
      <section className={clsx(classes.paragraph, classes.lightBlue)}>
        <CMSContent type="contentitem" name="home-end-to-end-solution" />
      </section>
      <section>
        <Container disableGutters>
          <CMSIFrame name="home-testimonials" height={testimonialsHeight} scrolling="no" />
        </Container>
      </section>
      <section className={clsx(classes.paragraph, classes.lightBlue)}>
        <CMSContent type="contentitem" name="home-why-us" />
      </section>
      <section className={clsx(classes.getInTouch, classes.blue)}>
        <Typography variant="h4" component="h2">
          Get in Touch
        </Typography>
        <Typography variant="subtitle1">
          Fill out the form below and we will have a representative call you to talk about how you
          can benefit from using our services
        </Typography>
        <ContactForm maxWidth={800} />
        <br />
        <br />
        <br />
        <br />
        <Typography variant="h4" component="h2">
          Contact Us
        </Typography>
        <br />
        <Typography variant="body1">Catalyst Corporate Federal Credit Union</Typography>
        <Typography variant="body1">
          214.703.7500 | 800.442.5763 <br />
        </Typography>
        <br />
        <Typography variant="body1">CU Business Group</Typography>
        <Typography variant="body1">
          503.232.2876 | 866.484.2876 <br />
        </Typography>
      </section>

      <section
        className={clsx(classes.readyToStart, classes.valueDetail, classes.lightBlue)}
        style={{ backgroundColor: 'white' }}
      >
        <h2>Ready to Start?</h2>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={handleSignUpClick}
          style={{ padding: '5px 50px' }}
        >
          SIGN UP
        </Button>
      </section>
    </div>
  );
};
