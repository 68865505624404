import React from 'react';
import { Link as RouteLink, LinkProps as RouteLinkProps } from 'react-router-dom';
import { Link as MuiLink, LinkProps as MuiLinkProps, LinkTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

const Link: OverridableComponent<LinkTypeMap<RouteLinkProps & MuiLinkProps, typeof MuiLink>> = ({
  component = RouteLink,
  children,
  ...props
}) => {
  return (
    <MuiLink component={component} {...props}>
      {children}
    </MuiLink>
  );
};

export default Link;
