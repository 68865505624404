import { ButtonGroup, IconButton, Tooltip } from '@material-ui/core';
import { Description, Edit, PlaylistAddCheck, Schedule, Sms } from '@material-ui/icons';
import Link from 'components/Link';
import { TableDataEntry, TableProps } from 'components/Table/Table';
import _ from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  NumberOfDaysToBeInactive,
  DaysInactive,
  DaysRemaining,
  Dollar,
  ForSalePercent,
  LatestCaStatus,
  LatestCASubmissionDate,
  LpData,
  LpRestrictions,
  TableDate,
} from 'utils/formatters';

export const TermsheetActions: React.FC<{ id: string }> = ({ id }) => {
  const location = useLocation();
  return (
    <ButtonGroup variant="text" aria-label="text primary button group" size="small">
      <IconButton component={Link} to={location.pathname + '/' + id}>
        <Tooltip title="Accept / Reject Purchase Commitments">
          <PlaylistAddCheck />
        </Tooltip>
      </IconButton>

      <IconButton component={Link} to={`/admin/lp/history/${id}`} size="small">
        <Tooltip title="History Page">
          <Schedule />
        </Tooltip>
      </IconButton>

      <IconButton component={Link} to={`/lp/${id}`} size="small">
        <Tooltip title="Termsheet">
          <Description color="inherit" />
        </Tooltip>
      </IconButton>

      <IconButton component={Link} to={`/admin/lp/${id}/edit?tab=0`} size="small">
        <Tooltip title="Create Notification">
          <Sms color="inherit" />
        </Tooltip>
      </IconButton>

      <IconButton component={Link} to={`/admin/lp/${id}/edit?tab=1`} size="small">
        <Tooltip title="Edit Restrictions">
          <Edit color="inherit" />
        </Tooltip>
      </IconButton>
    </ButtonGroup>
  );
};

export const LpActions = ({ lpNumber }) => (
  <ButtonGroup variant="text" aria-label="text primary button group" size="small" fullWidth>
    <IconButton component={Link} to={`/admin/lp/history/${lpNumber}`} size="small">
      <Tooltip title={`LP ${lpNumber} History`}>
        <Schedule />
      </Tooltip>
    </IconButton>

    <IconButton component={Link} to={`/admin/lp/${lpNumber}/edit?tab=1`} size="small">
      <Tooltip title="Edit Restrictions">
        <Sms color="inherit" />
      </Tooltip>
    </IconButton>
  </ButtonGroup>
);

type FlatValue = number | string;

const PurchaseCommitmentDefaults = {
  LPNumber: () => ({
    field: 'LPNumber',
    header: 'LP Number',
    value: row => row.LPNumber,
    display: row => <Link to={`/lp/${row.LPNumber}`}>{row.LPNumber}</Link>,
  }),
  CreditUnionName: () => ({
    field: 'CreditUnionName',
    header: 'Credit Union Name',
    value: row =>
      _.lowerCase(row.CreditUnionName)
        .split('-')
        .join(''),
  }),
  RtNumber: () => ({ field: 'RtNumber', header: 'RT Number', value: row => row.RtNumber }),
  Name: () => ({
    field: 'Name',
    header: 'Name',
    value: row => row.Name,
    display: row => <Link to={`/admin/user/profile/${row.UserObjectId}`}>{row.Name}</Link>,
  }),
  DateSubmitted: () => ({
    field: 'DateSubmitted',
    header: 'Date Submitted',
    value: row => row.DateSubmitted,
    display: row => TableDate(row.DateSubmitted),
  }),
  Amount: () => ({
    field: 'Amount',
    header: 'Puchase Amount',
    value: row => row.Amount,
    display: row => Dollar(row.Amount),
  }),
  Actions: () => ({
    field: 'Actions',
    header: 'Actions',
    value: row => <TermsheetActions id={row.LPNumber} />,
    cellProps: { align: 'center' },
  }),
};

export const offeringDefaults: {
  [key: string]: (
    header?: string,
    value?: (LpData) => FlatValue,
    display?: (LpData) => any
  ) => TableDataEntry<LpData>;
} = {
  id: (
    header = 'Lp Number',
    value = row => row.id.split('-').join(''),
    display = row => <Link to={`/lp/${row.id}`}>{row.id}</Link>
  ) => ({
    field: 'id',
    header,
    value,
    display,
  }),
  SellerName: (header = 'Seller', value = row => row.SellerName) => ({
    field: 'SellerName',
    header,
    value,
  }),
  PoolBalance: (
    header = 'Pool Balance',
    value = row => row.PoolBalance,
    display = row => Dollar(row.PoolBalance)
  ) => ({
    field: 'PoolBalance',
    header,
    value,
    display,
  }),
  AmountAvailableForSalePercent: (
    header = 'For Sale (%)',
    value = row => row.AmountAvailableForSalePercent,
    display = row => ForSalePercent(row.AmountAvailableForSalePercent)
  ) => ({
    field: 'AmountAvailableForSalePercent',
    header,
    value,
    display,
  }),
  PurchaseCommitmentsTotal: (
    header = 'Buyer Commitments',
    value = row => row.PurchaseCommitmentsTotal,
    display = row => Dollar(row.PurchaseCommitmentsTotal)
  ) => ({
    field: 'PoolBalance',
    header,
    value,
    display,
  }),
  AmountRemaining: (
    header = 'Amount Remaining',
    value = row => row.AmountRemaining,
    display = row => Dollar(row.AmountRemaining)
  ) => ({
    field: 'AmountRemaining',
    header,
    value,
    display,
  }),
  DueDiligenceDeadline: (
    header = 'Days Remaining',
    value = row => row.DueDiligenceDeadline,
    display = row => DaysRemaining(row.DueDiligenceDeadline)
  ) => ({
    field: 'DueDiligenceDeadline',
    header,
    value,
    display,
  }),
  LastStateChangeDate: (
    header = 'Days Remaining',
    value = row => TableDate(row.LastStateChangeDate),
    display = row => TableDate(row.LastStateChangeDate)
  ) => ({
    field: 'LastStateChangeDate',
    header,
    value,
    display,
  }),

  CutOffDate: () => ({
    field: 'CutOffDate',
    header: 'Cut off date',
    value: row => row.CutOffDate,
    display: row => TableDate(row.CutOffDate),
  }),
  LoanType: () => ({
    field: 'LoanType',
    header: 'Type',
    value: row => row.LoanType,
    display: row => row.LoanType,
  }),
  Restrictions: () => ({
    field: 'Restrictions',
    header: 'Restrictions',
    value: row => LpRestrictions(row),
    display: row => LpRestrictions(row),
  }),
  DateAdded: () => ({
    field: 'DateAdded',
    header: 'Date Added',
    value: row => row.DateAdded,
    display: row => TableDate(row.DateAdded),
  }),
  State: (
    header = 'Status',
    value,
    display = row => (row.State === 'Published' ? 'Open' : row.State)
  ) => ({
    field: 'State',
    header,
    value: ({ State }) => {
      if (_.lowerCase(State) === 'open') return `a${State}`;
      if (_.lowerCase(State) === 'published') return `a${State}`;
      if (_.lowerCase(State) === 'draft') return `b${State}`;
      if (_.lowerCase(State) === 'unpublished') return `b${State}`;
      if (_.lowerCase(State) === 'full') return `c${State}`;
      if (_.lowerCase(State) === 'sold') return `d${State}`;
      if (_.lowerCase(State) === 'retention') return `e${State}`;
      if (_.lowerCase(State) === 'archive') return `f${State}`;
      if (_.lowerCase(State) === 'archived') return `f${State}`;
      if (_.lowerCase(State) === 'withdrawn') return `g${State}`;
      if (_.lowerCase(State) === 'withdraw') return `g${State}`;
      else return `z${State}`;
    },
    display,
  }),
  Actions: () => ({
    header: 'Actions',
    display: row => <TermsheetActions {...row} />,
    cellProps: { align: 'center' },
  }),
};

const usersReadyToBeVerifiedTable: TableProps = {
  title: 'Users Ready To Be Verified',
  subtitle: 'Select a user to view their profile and verify them',
  endpoint: '/usermanagement/views/admin/readytoverify',
  orderBy: 'firstActiveDate',
  order: 'desc',
  tableData: [
    {
      field: 'name',
      header: 'Name',
      value: row => row.name,
      display: row => <Link to={`/admin/manage/users/ready-to-verify/${row.id}`}>{row.name}</Link>,
    },
    { field: 'jobTitle', header: 'Job Title', value: row => row.jobTitle },
    {
      field: 'signingAuthority',
      header: 'Signing Authority',
      value: row => String(row.signingAuthority),
      display: row => (row.signingAuthority ? 'Yes' : 'No'),
    },
    {
      field: 'creditUnionName',
      header: 'Credit Union',
      value: row => row.creditUnionName,
    },
    { field: 'emailAddress', header: 'Email', value: row => row.emailAddress },
    { field: 'rtNumber', header: 'RT Number', value: row => row.rtNumber },
    {
      field: 'firstActiveDate',
      header: 'Date Joined',
      value: row => row.firstActiveDate,
      display: row => TableDate(row.firstActiveDate),
    },
    { field: 'phoneNumber', header: 'Phone Number', value: row => row.phoneNumber },
    {
      field: 'latestCaStatus',
      header: 'CA Submission',
      value: row => LatestCASubmissionDate(row.agreementStatuses),
    },
    {
      field: 'originatingApplication',
      header: 'Originating Application',
      value: row => row.originatingApplication,
      filterable: true,
      defaultFilter: 'LPX',
    },
  ],
};

const newUsersTable: TableProps = {
  title: 'All New Users',
  subtitle: 'Select a user to view their profile',
  endpoint: '/usermanagement/views/admin/users',
  orderBy: 'firstActiveDate',
  order: 'desc',
  oData: {
    $filter: [
      `not agreementStatuses/any() or latestCaStatus ne 'completed'`,
      `roles/any(s: s eq 'Verified') ne true`,
    ],
  },
  tableData: [
    {
      field: 'name',
      header: 'Name',
      value: row => row?.name || 'name',
      display: row => <Link to={`/admin/manage/users/ready-to-verify/${row.id}`}>{row.name}</Link>,
    },
    {
      field: 'jobTitle',
      header: 'Job Title',
      value: row => row?.jobTitle || 'None',
    },
    {
      field: 'signingAuthority',
      header: 'Signing Authority',
      value: row => row?.signingAuthority || 'No',
      display: row => (row.signingAuthority ? 'Yes' : 'No'),
    },
    {
      field: 'creditUnionName',
      header: 'Credit Union',
      value: row => row?.creditUnionName,
    },
    { field: 'rtNumber', header: 'RT Number', value: row => row.rtNumber },
    { field: 'emailAddress', header: 'Email', value: row => row.emailAddress },
    { field: 'phoneNumber', header: 'Phone Number', value: row => row.phoneNumber },
    {
      field: 'latestCaStatus',
      header: 'CA Status',
      value: row => LatestCaStatus(row.latestCaStatus),
    },
    {
      field: 'firstActiveDate',
      header: 'Date Joined',
      value: row => row.firstActiveDate,
      display: row => TableDate(row.firstActiveDate),
    },
    {
      field: 'originatingApplication',
      header: 'Originating Application',
      value: row => row.originatingApplication,
      filterable: true,
      defaultFilter: 'LPX',
    },
  ],
};

const usersInactiveUsersTable: TableProps = {
  title: 'Inactive Users',
  subtitle: 'Select a user to view their profile',
  endpoint: '/usermanagement/views/admin/users',
  orderBy: 'lastActiveDate',
  order: 'asc',
  oData: {
    $filter: ['disabled ne true'],
  },
  transformResponse: data =>
    data.filter(
      row =>
        new Date(row.lastActiveDate) <
        new Date(new Date().setDate(new Date().getDate() - NumberOfDaysToBeInactive))
    ),
  tableData: [
    {
      field: 'name',
      header: 'Name',
      value: row => row.name,
      display: row => <Link to={`/admin/user/profile/${row.id}`}>{row.name}</Link>,
    },
    { field: 'jobTitle', header: 'Job Title', value: row => row.jobTitle },
    {
      field: 'signingAuthority',
      header: 'Signing Authority',
      value: row => (row.signingAuthority ? 'Yes' : 'No'),
    },
    {
      field: 'creditUnionName',
      header: 'Credit Union',
      value: row => row.creditUnionName,
    },
    { field: 'emailAddress', header: 'Email Address', value: row => row.emailAddress },
    { field: 'rtNumber', header: 'RT Number', value: row => row.rtNumber },
    { field: 'userType', header: 'User Type', value: row => row.userType },
    {
      field: 'lastActiveDate',
      header: 'Days Inactive',
      value: row => row.lastActiveDate,
      display: row => DaysInactive(row),
    },
    {
      field: 'originatingApplication',
      header: 'Originating Application',
      value: row => row.originatingApplication,
      filterable: true,
      defaultFilter: 'LPX',
    },
  ],
};

export const adminTables: { [key: string]: { [key: string]: TableProps } } = {
  users: {
    'ready-to-verify': usersReadyToBeVerifiedTable,
    new: newUsersTable,
    inactive: usersInactiveUsersTable,
    disabled: {
      title: 'Disabled Users',
      subtitle: 'Re-enable users from their profile page',
      endpoint: '/usermanagement/views/admin/users',
      orderBy: 'name',
      order: 'asc',
      oData: { $filter: ['disabled eq true'] },
      tableData: [
        {
          field: 'name',
          header: 'Name',
          value: row => row.name,
          display: row => <Link to={`/admin/user/profile/${row.id}`}>{row.name}</Link>,
        },
        { field: 'jobTitle', header: 'Job Title', value: row => row.jobTitle },
        {
          field: 'creditUnionName',
          header: 'Credit Union',
          value: row => row.creditUnionName,
        },
        { field: 'emailAddress', header: 'Email', value: row => row.emailAddress },
        { field: 'rtNumber', header: 'RT Number', value: row => row.rtNumber },
        { field: 'phoneNumber', header: 'Phone Number', value: row => row.phoneNumber },
        { field: 'userType', header: 'User Type', value: row => row.userType },
        {
          field: 'lastActiveDate',
          header: 'Days Inactive',
          value: row => row.lastActiveDate,
          display: row => DaysInactive(row),
        },
        {
          field: 'originatingApplication',
          header: 'Originating Application',
          value: row => row.originatingApplication,
          filterable: true,
          defaultFilter: 'LPX',
        },
      ],
    },
    'credit-unions': {
      title: 'Credit Unions',
      endpoint: '/usermanagement/views/admin/users',
      orderBy: 'creditUnionName',
      order: 'asc',
      oData: { $filter: ['partnerCu eq true', `disabled eq false`] },
      tableData: [
        {
          field: 'creditUnionName',
          header: 'Name',
          value: row =>
            row.creditUnionName
              .split(' ')
              .join('')
              .split('-')
              .join(''),
          display: row => (
            <Link to={`/admin/manage/users/credit-unions/${row.creditUnionName}/${row.rtNumber}`}>
              {row.creditUnionName}
            </Link>
          ),
        },
        {
          field: 'rtNumber',
          header: 'RT Number',
          value: row => row.rtNumber,
        },
        {
          field: 'name',
          header: 'Contact',
          value: row => row.name,
        },
        {
          field: 'phoneNumber',
          header: 'Phone Number',

          value: row => row.phoneNumber,
        },
        {
          field: 'emailAddress',
          header: 'Email Address',
          value: row => row.emailAddress,
        },
        {
          field: 'originatingApplication',
          header: 'Originating Application',
          value: row => row.originatingApplication,
          filterable: true,
          defaultFilter: 'LPX',
        },
      ],
    },
    all: {
      title: 'All Users',
      subtitle: 'Select a user to view their profile',
      endpoint: '/usermanagement/views/admin/users',
      orderBy: 'name',
      order: 'asc',
      tableData: [
        {
          field: 'name',
          header: 'Name',
          value: row => row.name,
          display: row => <Link to={`/admin/user/profile/${row.id}`}>{row.name}</Link>,
        },
        { field: 'jobTitle', header: 'Job Title', value: row => row.jobTitle },
        {
          field: 'creditUnionName',
          header: 'Credit Union',
          value: row => row.creditUnionName,
        },
        { field: 'emailAddress', header: 'Email', value: row => row.emailAddress },
        { field: 'rtNumber', header: 'RT Number', value: row => row.rtNumber },
        { field: 'phoneNumber', header: 'Phone Number', value: row => row.phoneNumber },
        {
          field: 'userType',
          header: 'User Type',
          value: row => {
            if (row.userType === 'Verified' && row.partnerCu) return 'Partner';
            if (row.userType === 'Partner' && row.partnerCu) return 'Partner';
            return row.userType;
          },
        },
        {
          field: 'lastActiveDate',
          header: 'Days Inactive',
          value: row => row.lastActiveDate,
          display: row => DaysInactive(row),
        },
        {
          field: 'firstActiveDate',
          header: 'Date Joined',
          value: row => row.firstActiveDate,
          display: row => TableDate(row.firstActiveDate),
        },
        {
          field: 'userStatus',
          header: 'User Status',
          value: row => {
            if (row.disabled) {
              return 'Disabled';
            } else if (parseInt(DaysInactive(row)) > NumberOfDaysToBeInactive) {
              return 'Inactive';
            }
            return 'Active';
          },
        },
        {
          field: 'originatingApplication',
          header: 'Originating Application',
          value: row => row.originatingApplication,
          filterable: true,
          defaultFilter: 'LPX',
        },
      ],
    },
  },
  offerings: {
    all: {
      title: `All LP's`,
      subtitle: 'Select the row of an offering to view more information.',
      endpoint: '/marketplace/views/admin/offerings',
      orderBy: 'State',
      order: 'asc',
      tableData: [
        {
          field: 'id',
          header: 'Lp Number',
          value: row => row.id,
          display: row => <Link to={`/lp/${row.id}`}>{row.id}</Link>,
        },
        offeringDefaults.SellerName(),
        offeringDefaults.PoolBalance(),
        offeringDefaults.AmountAvailableForSalePercent(),
        offeringDefaults.PurchaseCommitmentsTotal(),
        offeringDefaults.AmountRemaining(),
        offeringDefaults.State(),
        offeringDefaults.Actions(),
      ],
    },
    purchases: {
      title: 'Purchase Commitments',
      endpoint: '/marketplace/views/admin/commitments',
      orderBy: 'DateSubmitted',
      order: 'desc',
      tableData: [
        PurchaseCommitmentDefaults.LPNumber(),
        PurchaseCommitmentDefaults.CreditUnionName(),
        PurchaseCommitmentDefaults.RtNumber(),
        PurchaseCommitmentDefaults.Name(),
        PurchaseCommitmentDefaults.DateSubmitted(),
        PurchaseCommitmentDefaults.Amount(),
        PurchaseCommitmentDefaults.Actions(),
      ],
    },
    indications: {
      title: 'Indications of Interest',
      endpoint: '/marketplace/views/admin/interests',
      orderBy: 'DateSubmitted',
      order: 'desc',
      tableData: [
        PurchaseCommitmentDefaults.LPNumber(),
        PurchaseCommitmentDefaults.CreditUnionName(),
        PurchaseCommitmentDefaults.RtNumber(),
        PurchaseCommitmentDefaults.Name(),
        PurchaseCommitmentDefaults.DateSubmitted(),
        {
          field: 'Amount',
          header: 'Indication Amount',
          value: row => row.Amount,
          display: row => Dollar(row.Amount),
        },
      ],
    },
    open: {
      title: "Open LP's",
      endpoint: '/marketplace/views/admin/offerings',
      oData: {
        $filter: [
          'State ne null',
          `State eq 'Open' or State eq 'Published' or State eq 'Allocated'`,
        ],
      },
      orderBy: 'LastStateChangeDate',
      order: 'asc',
      tableData: [
        offeringDefaults.id(),
        offeringDefaults.SellerName(),
        offeringDefaults.AmountAvailableForSalePercent(),
        offeringDefaults.PurchaseCommitmentsTotal(),
        offeringDefaults.AmountRemaining(),
        offeringDefaults.DueDiligenceDeadline(),
        offeringDefaults.Actions(),
      ],
    },
    drafts: {
      title: `Draft LP's`,
      subtitle: 'Select a listing to view an LP and make comments or changes.',
      endpoint: '/marketplace/views/admin/offerings',
      orderBy: 'DateAdded',
      order: 'desc',
      oData: { $filter: ['State ne null', "State eq 'Draft'"] },
      tableData: [
        offeringDefaults.id(),
        offeringDefaults.SellerName(),
        offeringDefaults.PoolBalance(),
        offeringDefaults.AmountAvailableForSalePercent(),
        offeringDefaults.LoanType(),
        offeringDefaults.Restrictions(),
        offeringDefaults.DateAdded(),
        offeringDefaults.Actions(),
      ],
    },
    sold: {
      title: "Sold LP's",
      endpoint: '/marketplace/views/admin/offerings',
      orderBy: 'LastStateChangeDate',
      order: 'asc',
      oData: { $filter: ['State ne null', `State eq 'Sold'`] },
      tableData: [
        offeringDefaults.id(),
        offeringDefaults.SellerName(),
        offeringDefaults.PoolBalance(),
        offeringDefaults.AmountAvailableForSalePercent(),
        offeringDefaults.PurchaseCommitmentsTotal(),
        offeringDefaults.AmountRemaining(),
        {
          field: 'LastStateChangeDate',
          header: `Sold Date`,
          value: row => TableDate(row.LastStateChangeDate),
          display: row => TableDate(row.LastStateChangeDate),
        },
        offeringDefaults.Actions(),
      ],
    },
    full: {
      title: `Full LP's`,
      endpoint: '/marketplace/views/admin/offerings',
      orderBy: 'LastStateChangeDate',
      order: 'desc',
      oData: { $filter: ['State ne null', `State eq 'Full'`] },
      tableData: [
        offeringDefaults.id(),
        offeringDefaults.SellerName(),
        offeringDefaults.PoolBalance(),
        offeringDefaults.PurchaseCommitmentsTotal(),
        {
          field: 'LastStateChangeDate',
          header: 'Date Full',
          value: row => TableDate(row.LastStateChangeDate),
          display: row => TableDate(row.LastStateChangeDate),
        },
        offeringDefaults.Actions(),
      ],
    },
    retention: {
      title: `LP's in Retention`,
      endpoint: '/marketplace/views/admin/offerings',
      orderBy: 'LastStateChangeDate',
      order: 'desc',
      oData: { $filter: ['State ne null', `State eq 'Retention'`] },
      tableData: [
        offeringDefaults.id(),
        offeringDefaults.SellerName(),
        offeringDefaults.PoolBalance(),
        offeringDefaults.PurchaseCommitmentsTotal(),
        {
          field: 'LastStateChangeDate',
          header: 'Retention Date',
          value: row => TableDate(row.LastStateChangeDate),
          display: row => TableDate(row.LastStateChangeDate),
        },
        offeringDefaults.Actions(),
      ],
    },
    archived: {
      title: `Archived LP's`,
      endpoint: '/marketplace/views/admin/offerings',
      orderBy: 'LastStateChangeDate',
      order: 'asc',
      oData: { $filter: ['State ne null', `State eq 'Archived'`] },
      tableData: [
        offeringDefaults.id(),
        offeringDefaults.SellerName(),
        offeringDefaults.PoolBalance(),
        offeringDefaults.PurchaseCommitmentsTotal(),
        {
          field: 'LastStateChangeDate',
          header: 'Archived Date',
          value: row => TableDate(row.LastStateChangeDate),
          display: row => TableDate(row.LastStateChangeDate),
        },
        offeringDefaults.Actions(),
      ],
    },
    withdrawn: {
      title: `Withdrawn LP's`,
      endpoint: '/marketplace/views/admin/offerings',
      orderBy: 'LastStateChangeDate',
      order: 'asc',
      oData: { $filter: ['State ne null', `State eq 'Withdrawn'`] },
      tableData: [
        offeringDefaults.id(),
        offeringDefaults.SellerName(),
        offeringDefaults.PoolBalance(),
        offeringDefaults.PurchaseCommitmentsTotal(),
        {
          field: 'LastStateChangeDate',
          header: 'Withdrawn Date',
          value: row => TableDate(row.LastStateChangeDate),
          display: row => TableDate(row.LastStateChangeDate),
        },
        offeringDefaults.Actions(),
      ],
    },
  },
};

export default adminTables;
