import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LPLinkPlain from '../../../components/Common/LPLinkPlain';
import { UserContext } from '../../../components/data/context';
import { pathReplace, paths } from '../../../store/routePaths';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    right: 0,
    top: 0,
    marginTop: 60,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    '& ul': {
      listStyleType: 'none',
      paddingRight: 0,
      '& li': {
        width: 70,
        height: 70,
        backgroundColor: theme.colors.white,
        opacity: 0.5,
        backdropFilter: 'blur(50px)',
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        marginTop: 10,
        '& a': {
          height: '100%',
          width: '100%',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'space-evenly',
          display: 'flex',
          flexDirection: 'column',
        },
        '&:hover': {
          cursor: 'pointer',
          opacity: 0.8,
          boxShadow: '0 3px 20px 0 rgba(0, 0, 0, 0.16)',
        },
      },
    },
  },
}));

export const AdminActionBar = React.memo((props: any) => {
  const classes = useStyles(props);
  const user = useContext(UserContext);

  if (!user.admin) {
    return null;
  }

  return (
    <div className={classes.root}>
      <ul>
        <li>
          <LPLinkPlain to={`/admin/lp/${props.match.params.id}/edit`}>
            <small>LP Admin</small>
            <EditIcon />
          </LPLinkPlain>
        </li>
        <li>
          <LPLinkPlain
            hitServer={true}
            to={pathReplace(paths.adminLpDueDiligence, props.match.params.id)}
          >
            <small>Due Diligence</small>
            <FormatListBulletedIcon />
          </LPLinkPlain>
        </li>
      </ul>
    </div>
  );
});
