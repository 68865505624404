import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import ContextProvider from 'ContextProvider';
import configureStore from 'store/configureStore';
import mainTheme, { GlobalStyle } from 'utils/styles';
import { CssBaseline } from '@material-ui/core';
import typography from 'utils/typography';

export type History = typeof history;
export type Store = typeof store;

typography.injectStyles();

const history = createBrowserHistory();
const store = configureStore(history, undefined);

ReactDOM.render(
  <ContextProvider store={store} history={history} theme={mainTheme}>
    <App history={history} />
    <CssBaseline />
    <GlobalStyle />
  </ContextProvider>,
  document.getElementById('root')
);
