import React, { createContext, FC, useContext, Dispatch, SetStateAction } from 'react';
import { useLocalStorage } from 'react-use';

type LayoutState = {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

const LayoutContext = createContext<LayoutState | undefined>(undefined);

export function useLayout() {
  const ctx = useContext(LayoutContext);
  return ctx;
}

export const LayoutProvider: FC = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useLocalStorage('sidebar', true);

  const state = { sidebarOpen, setSidebarOpen };

  return <LayoutContext.Provider value={state}>{children}</LayoutContext.Provider>;
};
