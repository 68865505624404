import React from 'react';
import { TableCell, makeStyles } from '@material-ui/core';
import { colors } from 'utils/theme';
import { TableCellProps } from '@material-ui/core/TableCell';

//       NOT USED IN SCROLL GRID RIGHT NOW

interface Props extends TableCellProps {
  showBorder?: boolean;
}

const useStyles = makeStyles(() => ({
  head: {
    backgroundColor: 'transparent',
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.peacockBlue,
    borderWidth: 2,
    // borderColor: colors.navyBlue,
  },
  body: {
    fontSize: 18,
    borderColor: colors.navyBlue,
    borderBottom: (props: Props) => (props.showBorder ? '1px solid' : 'none'),
    maxWidth: 300,
  },
}));

const LPTableCell: React.FC<Props> = props => {
  const { showBorder, ...rest } = props;
  const classes = useStyles({ showBorder });

  return (
    <TableCell classes={{ head: classes.head, body: classes.body }} {...rest}>
      {props.children}
    </TableCell>
  );
};

export default LPTableCell;
