import {
  Box,
  BoxProps,
  fade,
  Paper,
  PaperProps as MuiPaperProps,
  SortDirection,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableCellProps,
  TableContainer as MuiTableContainer,
  TableContainerProps,
  TableFooter as MuiTableFooter,
  TableHead as MuiTableHead,
  TableProps as MuiTableProps,
  TableRow as MuiTableRow,
  TableRowProps,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { AxiosRequestConfig, AxiosTransformer } from 'axios';
import * as TC from 'components/Table/components';
import React, { createContext, FC, ReactNode, useContext } from 'react';
import { UseAxioConfig } from 'hooks/useAxios';
import useTable, { UseTableResponse } from './useTable';

type DisplayValue = Function | number | string | JSX.Element;

export type TableDataEntryValue<T = any> = (row: T) => any;

type TextTransformOptions = 'uppercase' | 'lowercase' | 'title';

export type TableDataEntry<T = any> = {
  field?: string;
  header: any;
  value?: TableDataEntryValue<T> & any;
  display?: any;
  align?: TableCellProps['align'];
  cellProps?: TableCellProps;
  textTransform?: TextTransformOptions;
  filterable?: boolean;
  defaultFilter?: string;
};

type PaperProps = MuiPaperProps & BoxProps;
export type TableProps = {
  title?: any;
  subtitle?: string;
  endpoint?: string;
  orderBy?: string;
  order?: SortDirection;
  tableData: TableDataEntry<any>[];
  height?: number | string;
  rowProps?: TableRowProps;
  hideSearch?: boolean;
  hideToolbar?: boolean;
  hideControls?: boolean;
  oData?: any;
  paperProps?: PaperProps;
  tableContainerProps?: TableContainerProps;
  tableProps?: MuiTableProps;
  useCache?: boolean;
  manual?: boolean;
  search?: string;
  transformResponse?: AxiosTransformer;
  requestConfig?: AxiosRequestConfig;
  useAxiosConfig?: UseAxioConfig;
  selectable?: boolean | 'single' | 'multi';
  onSelect?: (row: any) => void;
  emptyMessage?: string;
  type?: 'manual';
} & TableComponents;

type TableComponents = {
  Table?: any | FC | ReactNode;
  TableContainer?: any | FC | ReactNode;
  TableHead?: any;
  TableBody?: any;
  TableFooter?: any;
  TableRow?: any;
  TableCell?: any;
  Toolbar?: any | FC | ReactNode;
  TableHeaders?: any | FC | ReactNode;
  TableEntries?: any | FC | ReactNode;
  TableControls?: any | FC | ReactNode;
  TableRowEmpty?: FC;
  TableRowLoading?: FC;
};

const defaultComponents: TableComponents = {
  Table: MuiTable,
  TableContainer: MuiTableContainer,
  TableHead: MuiTableHead,
  TableBody: MuiTableBody,
  TableFooter: MuiTableFooter,
  TableRow: MuiTableRow,
  TableCell: MuiTableCell,
  Toolbar: TC.Toolbar,
  TableHeaders: TC.TableHeaders,
  TableEntries: TC.TableEntries,
  TableControls: TC.TableControls,
  TableRowEmpty: TC.TableRowEmpty,
  TableRowLoading: TC.TableRowLoading,
};

const defaultProps = {
  selectable: false,
  hideSearch: false,
  hideToolbar: false,
  hideControls: false,
  useCache: false,
  manual: false,
  height: '100%',
  emptyMessage: 'No Information Is Available.',
  search: 'search',
  ...defaultComponents,
};

const displayName = 'LPXTable';

const TableBase = props => {
  const classes = useStyles(props);
  return (
    <TableContext.Provider value={props}>
      <Box
        padding={1}
        component={Paper}
        className={classes.root}
        elevation={0}
        {...props.paperProps}
      >
        <props.TableContainer className={classes.tableContainer} {...props.tableContainerProps}>
          <props.Toolbar />
          <props.Table
            className={classes.table}
            size={props.size as MuiTableProps['size']}
            style={props.hideToolbar ? { marginTop: 0 } : {}}
            {...props.tableProps}
          >
            <props.TableHead>
              <props.TableRow>
                <props.TableHeaders />
              </props.TableRow>
            </props.TableHead>

            <MuiTableBody>
              <props.TableEntries />
              <props.TableRowLoading />
              <props.TableRowEmpty />
            </MuiTableBody>
            <props.TableFooter />
          </props.Table>
        </props.TableContainer>
        <props.TableControls />
      </Box>
    </TableContext.Provider>
  );
};

const AutoTable: FC<TableProps> = props => {
  const table = useTable(props);
  return <TableBase {...table} />;
};

const Table: React.FC<TableProps | UseTableResponse> = props => {
  switch (props.type) {
    case 'manual':
      return <TableBase {...props} />;
    default:
      return <AutoTable {...props} />;
  }
};

Table.defaultProps = defaultProps;
Table.displayName = displayName;

const TableContext = createContext({} as UseTableResponse);

export function useTableContext(): UseTableResponse {
  const ctx = useContext(TableContext);
  return ctx;
}

export default Table;

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
    },
    tableContainer: {
      overFlowY: 'auto',
      maxHeight: (props: TableProps) => props?.height,
    },
    table: {
      marginTop: (props: TableProps) => (props.hideToolbar ? 0 : 10),
      minWidth: '100%',
      padding: 0,
      '& th,td': {},
    },
    title: { flex: '1 1 100%' },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
    },
    buttonGroup: {
      alignSelf: 'center',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade('rgba(0,0,0,0.05)', 0.1),
      '&:hover': {
        backgroundColor: fade('rgba(0,0,0,0.05)', 0.125),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);
