import React, { useContext } from 'react';
import styled from 'styled-components';
import { fluidPadding } from 'utils/styles';
import { UserContext } from 'components/data/context';
import { LogInOrAccountIconButton } from 'components/AccountButtons';
import { useMeasure } from 'react-use';

interface Props {
  name?: string;
  headerText?: string;
  authenticated?: boolean;
}

export const Header: React.FC<Props> = props => {
  const user = useContext(UserContext);
  const [ref, { width }] = useMeasure();
  let { name, authenticated, verified } = user;

  if (props.name) {
    name = props.name;
  }
  let headerText = `${name ? `${name}` : ''} dashboard`;
  if (props.headerText) {
    headerText = props.headerText;
  }

  return (
    <Wrapper authenticated={authenticated}>
      {authenticated && (
        <Title marginLeft={width} dangerouslySetInnerHTML={{ __html: headerText }}></Title>
      )}
      <span ref={ref}>
        <LogInOrAccountIconButton />
      </span>
    </Wrapper>
  );
};

const Wrapper = styled.header<Props>`
  ${fluidPadding};
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: ${props => (props.authenticated ? 'center' : 'flex-end')};
  justify-items: ${props => (props.authenticated ? 'center' : 'flex-end')};
  align-items: center;
  align-content: center;
  min-height: 50px;
  height: auto;
  backdrop-filter: blur(30px);
  box-shadow: 3px 3px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: ${props => props.theme.colors.white};
  z-index: 10;
  button {
    flex: 0 1 auto;
  }
`;

export const Title = styled.div<any>`
  flex: 1 1 auto;
  font-size: 30px;
  margin-left: ${(props: any) => props.marginLeft}px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.navyBlue};
`;
