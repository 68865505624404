import { Reducer } from 'redux';
import { SignupState, SignupActionTypes } from './types';

export const initialState: SignupState = {
  data: {
    id: 0,
    firstName: '',
    lastName: '',
    title: '',
    creditUnion: '',
    contactInfo: '',
    phoneNumber: '',
    routingNumber: '',
    username: '',
    password: '',
    confirmPassword: '',
  },
  errors: undefined,
  loading: false,
};

export const SignupReducer: Reducer<SignupState> = (state = initialState, action) => {
  switch (action.type) {
    case SignupActionTypes.SIGNUP_REQUEST:
      return { ...state, loading: true };
    case SignupActionTypes.SIGNUP_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case SignupActionTypes.SIGNUP_ERROR:
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};
