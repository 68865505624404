/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { CircularProgress, makeStyles, Container } from '@material-ui/core';
import { callApi } from '../../utils/api';
import styled from 'styled-components';

export type SiteFinityContentTypes =
  | 'sites'
  | 'newsitems'
  | 'blogposts'
  | 'blogs'
  | 'events'
  | 'calendars'
  | 'image'
  | 'documents'
  | 'video'
  | 'albums'
  | 'documentlibraries'
  | 'VideoLibraries'
  | 'form-drafts'
  | 'lists'
  | 'contentitem'
  | 'taxonomies'
  | 'flat-taxa'
  | 'hierarchy-taxa'
  | 'folders'
  | 'page';

interface CMSContentProps {
  type: SiteFinityContentTypes;
  name: string;
  style?: any;
  [key: string]: any;
}

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    color: theme.colors.lipstick,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';
const URL = '/sitefinity/content/';

interface CMSContentResponse {
  loading?: boolean;
  data?: any;
  errors?: string;
}

const specialTypes: SiteFinityContentTypes[] = ['video', 'image'];

export const CMSContent: FunctionComponent<CMSContentProps> = ({ type, name, ...props }) => {
  const classes = useStyles(props);
  const firstRender = useRef(true);
  const _isMounted = useRef(false);
  const [content, setContent] = useState({
    loading: true,
    data: undefined,
    errors: undefined,
  } as CMSContentResponse);

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (firstRender.current && !specialTypes.includes(type)) {
      setContent({ ...content, loading: true });
      try {
        callApi('get', API_ENDPOINT, URL + `${type}/${name}`)
          .then(res => {
            if (_isMounted.current) {
              setContent({ ...content, data: res, loading: false });
              firstRender.current = false;
            }
          })
          .catch(err => {
            if (_isMounted.current) {
              setContent({ ...content, errors: 'Unable to load content', loading: false });
              firstRender.current = false;
            }
          });
      } catch (err) {
        if (_isMounted.current) {
          setContent({ ...content, errors: 'Unable to load content', loading: false });
          firstRender.current = false;
        }
      }
    }
  }, []);

  if (content.loading && !specialTypes.includes(type)) {
    return (
      <div style={{ position: 'relative' }}>
        <CircularProgress size={48} className={classes.buttonProgress} />
      </div>
    );
  }

  if (!content.data && content.errors) {
    return <div style={{ position: 'relative', minHeight: 100 }}>{content.errors}</div>;
  }

  if (type === 'video') {
    return (
      <Container>
        <video controls autoPlay muted={true} width="100%">
          <source src={API_ENDPOINT + 'api' + URL + 'video/' + name} type={'video/mp4'} />
        </video>
      </Container>
    );
  }

  if (type === 'image') {
    return <img src={API_ENDPOINT + 'api' + URL + 'image/' + name} alt="CMS content" />;
  }

  if (type === 'page') {
    return (
      <StyledIframe
        className="cms-content-component"
        src={content.data.iFrameUrl}
        style={props.style}
        {...props}
      />
    );
  }

  return (
    <div
      className="cms-content-component"
      dangerouslySetInnerHTML={{
        __html: content.data ? content.data : content.errors,
      }}
    />
  );
};

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
`;
