import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import { media, fluidPadding } from '../../utils/styles';
import FileDropZone from './FileDropZone';
import Link from '@material-ui/core/Link';
import { apiUrl } from '../../utils/api';
import { LPTable } from '../../components/Common/LPTable/LPTable';
import { UserContext } from '../../components/data/context';
import CMSIFrame from '../../components/cms/iFrame';

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;

  .top {
    background: ${props => props.theme.colors.veryLightBlue};
  }

  .bottom {
    ${fluidPadding};
    scroll-snap-align: start;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  table {
    overflow: scroll;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    margin: 0.5em 0;
  }

  th,
  tr {
    margin: 0;
    padding: 0;
  }

  td {
    white-space: nowrap;
  }

  td:first-of-type {
    padding-left: 1em;
  }

  td:last-of-type {
    text-transform: uppercase;
  }

  th,
  td {
    border: none;
    width: 100%;
    padding: 0.25em;
  }

  thead {
    td {
      font-weight: bold;
      color: ${props => props.theme.colors.navyBlue};
    }
  }

  tbody {
    tr {
      border-radius: 5px;
      background-color: ${props => props.theme.colors.iceBlue};
      :not(:last-child) {
        margin-bottom: 0.75em;
      }
    }
  }

  .download-link {
    color: black;
  }
`;

const Grid = styled.div`
  padding: 3em;
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'cms' 'upload';

  ${media.greaterThan('md')`
    grid-template-areas: 'cms upload';
    grid-template-columns: 50% 1fr;
  `}

  grid-template-rows: auto;
  /* justify-items: center; */
  gap: 1em;

  .cms {
    grid-area: cms;
    text-overflow: word-wrap;
    overflow-wrap: word-wrap !important;
    max-width: 100%;
    & > div {
      position: relative;
      height: 100%;
    }
  }

  .upload {
    grid-area: upload;
    max-width: 100%;
  }
`;

interface Props {}

const UploadSection = () => {
  return (
    <Fragment>
      <div className="cms">
        <div>
          <CMSIFrame name="upload-instructions" height="350px" />
        </div>
      </div>
      <div className="upload">
        <FileDropZone />
      </div>
    </Fragment>
  );
};

const tableHeaders = [
  {
    keyOrGetter: 'itemName',
    label: 'FILENAME',
  },
  {
    keyOrGetter: 'dateReceived',
    label: 'DATE RECEIVED',
  },
  {
    keyOrGetter: 'itemName',
    label: 'DOWNLOAD LINK',
    Component: row => {
      return (
        <Link
          href={`${apiUrl}api/documentcatalog/downloads/mycu/${row.itemUniqueId}`}
          className="download-link"
          download
        >
          download
        </Link>
      );
    },
  },
];

const DownloadsTable = () => {
  const defaultOrdering = {
    field: 'itemName',
    label: 'FILENAME',
  };

  return (
    <div>
      <LPTable
        headers={tableHeaders}
        endpoint={'/documentcatalog/downloads/mycu'}
        size="small"
        defaultOrdering={defaultOrdering}
        keyIdentifier={'objectId'}
        loadAll
      />
    </div>
  );
};

export const DocumentsPage: React.FC<Props> = () => {
  const user = useContext(UserContext);
  const { restrictions, verified } = user;

  return (
    <Wrapper>
      {!restrictions.disableUpload && (
        <Grid className="top">
          <UploadSection />
        </Grid>
      )}
      {verified && (
        <div className="bottom">
          <DownloadsTable />
        </div>
      )}
    </Wrapper>
  );
};

export default DocumentsPage;
