import { createStyles, makeStyles, Paper, TableContainer } from '@material-ui/core';
import Table from 'components/Table/Table';
import React from 'react';
import { useParams } from 'react-router';
import { TableDate } from 'utils/formatters';

const LPHistory: React.FC<any> = ({ history }) => {
  const { id } = useParams();
  const LPHistoryDefaults: any = {
    type: () => ({
      field: 'Type',
      header: 'Type',
      value: row => row.Type,
    }),
    rt: () => ({
      field: 'RtNumber',
      header: 'RT Number',
      value: row => (row.RtNumber == null ? '' : String(row.RtNumber)),
    }),
    date: () => ({
      field: 'Date',
      header: 'Date and Time',
      value: row => row.Date,
      display: row => TableDate(row.Date),
    }),
    user: () => ({
      field: 'User',
      header: 'User',
      value: row => row.User,
    }),
    cu: () => ({
      field: 'CreditUnionName',
      header: 'CU Name',
      value: row => row.CreditUnionName,
    }),
    detail: () => ({
      field: 'Detail',
      header: 'Detail',
      value: row => row.Detail,
    }),
  };

  const tableOptions = {
    title: 'LP#' + id + ' History',
    subtitle: '',
    endpoint: `/Marketplace/history/${id}`,
    orderBy: 'date',
    // order: 'desc',
    tableData: [
      LPHistoryDefaults.type(),
      LPHistoryDefaults.rt(),
      LPHistoryDefaults.date(),
      LPHistoryDefaults.user(),
      LPHistoryDefaults.cu(),
      LPHistoryDefaults.detail(),
    ],
  };

  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.paper}>
      <TableContainer className={classes.container}>
        <Table {...tableOptions} />
      </TableContainer>
    </Paper>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      // width: '100%',
      // overflowX: 'hidden',
    },
    paper: {
      width: '100%',
      paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(3),
    },
    container: {
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(2),
    },
    table: {
      marginTop: 10,
      minWidth: 750,
      padding: 0,
      '& th,td': {
        // padding: theme.spacing(2),
      },
    },
    title: { flex: '1 1 100%' },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
    },
    buttonGroup: {
      alignSelf: 'center',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    inputRoot: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 300,
      ':active': {
        backgroundColor: 'rgba(224, 224, 224, 0.5)',
      },
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

export default LPHistory;
