import React, { Fragment } from 'react';
import LPTableCell from '../../../components/Common/LPTableCell';
import {
  CircularProgress,
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
} from '@material-ui/core';
import { colors } from 'utils/theme';
import { lighten } from '@material-ui/core/styles';
import shortid from 'shortid';
import { LPTableRowMain } from './LPTableRowMain';
import { Header } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: 'none',
      color: colors.fadedBlack,
      fontWeight: 600,
      '& > td': {
        padding: '5px 16px',
      },
      paddingBottom: 15,
    },
    tableCellHead: {
      borderBottom: 'none',
      color: lighten(colors.fadedBlack, 0.1),
      fontWeight: 600,
      paddingBottom: 5,
    },
    highlighted: {
      backgroundColor: lighten(theme.colors.paleGrey, 0.4),
    },
    tableRow: {
      borderBottom: 'none',
    },
    buttonProgress: {
      position: 'absolute',
      top: '55%',
      left: '49%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export const LPTableSupplementalView: React.FC<any> = props => {
  const classes = useStyles(props);
  let { supplementalView, supplementalViewHeaders } = props;
  const emptyHeader: Header = {
    label: '',
    keyOrGetter: '',
  };
  const len = supplementalViewHeaders.length;
  for (let i = 0; i < props.headers.length - len; i++) {
    supplementalViewHeaders.push(emptyHeader);
  }

  return (
    <React.Fragment>
      {supplementalViewHeaders && supplementalView.loading && (
        <TableRow
          style={{ position: 'relative', minHeight: 200, width: '100%' }}
          className={classes.highlighted}
        >
          <TableCell colSpan={props.headers.length} style={{ position: 'relative', height: 200 }}>
            <CircularProgress size={24} color={'primary'} className={classes.buttonProgress} />
          </TableCell>
        </TableRow>
      )}
      {supplementalViewHeaders && supplementalView.errors && (
        <TableRow
          style={{ position: 'relative', minHeight: 200, width: '100%' }}
          className={classes.highlighted}
        >
          <TableCell colSpan={props.headers.length} style={{ height: 200, textAlign: 'center' }}>
            <span>{supplementalView.errors}</span>
          </TableCell>
        </TableRow>
      )}
      {supplementalViewHeaders && supplementalView.data && supplementalView.data.length > 0 && (
        <Fragment>
          <TableRow className={classes.highlighted}>
            {supplementalViewHeaders.map(header => (
              <LPTableCell
                variant="head"
                showBorder={false}
                className={classes.tableCellHead}
                key={header.label ? header.label : shortid.generate()}
              >
                {header.label}
              </LPTableCell>
            ))}
          </TableRow>
          {supplementalView.data.map((row, i) => (
            <LPTableRowMain
              row={row}
              key={i}
              classes={{ root: classes.root }}
              headers={supplementalViewHeaders}
              isDetailView={true}
              keyIdentifier={shortid.generate()}
            />
          ))}
          <TableRow
            style={{ position: 'relative', minHeight: 200, width: '100%' }}
            className={classes.highlighted}
          >
            <TableCell colSpan={props.headers.length} style={{ height: 20 }}>
              &nbsp;
            </TableCell>
          </TableRow>
        </Fragment>
      )}

      {supplementalViewHeaders && supplementalView.data && supplementalView.data.length === 0 && (
        <TableRow
          style={{ position: 'relative', minHeight: 200, width: '100%' }}
          className={classes.highlighted}
        >
          <TableCell colSpan={props.headers.length} style={{ height: 200, textAlign: 'center' }}>
            <span>No supplemental data available for this LP.</span>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};
