import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { Dollar } from 'utils/formatters';

type CurrencyInputProps = {
  formik: FormikProps<any>;
} & OutlinedInputProps;

const CurrencyInput: FC<CurrencyInputProps> = ({ formik, ...props }) => {
  const classes = useStyles(props);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    formik.setFieldValue('amount', value);
    formik.setFieldValue('value', value.split(',').join(''));
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (parseInt(event.target.value)) {
      const value = Dollar(event.target.value.split(',').join('')).substr(1);

      formik.setFieldValue(event.target.name, value);

      if (value !== '') formik.setFieldTouched('value', true);
    }
  }

  return (
    <div className={classes.root}>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">{props.label}</InputLabel>
        <OutlinedInput
          id="amount"
          name="amount"
          value={formik.values.amount}
          onChange={handleChange}
          onBlur={handleBlur}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          inputProps={{ type: 'currency' }}
          labelWidth={133}
        />
        <Typography component="strong" color="primary">
          <small>{formik.errors.value}</small>
        </Typography>
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles<Theme, Omit<CurrencyInputProps, 'formik'>>(theme =>
  createStyles({
    root: { backgroundColor: theme.colors.white },
  })
);

export default CurrencyInput;
