import LoadingSpinner from 'components/data/LoadingSpinner';
import * as seoConfig from 'components/SEOConfig';
import Layout, { RouteLayout, RouteLPLayout, RouteMarketingLayout } from 'layout/Layout';
import { HomePage } from 'pages';
import AdminAcceptRejectPage from 'pages/admin/AdminAcceptRejectPage';
import AdminCreditUnionsPage from 'pages/admin/AdminCreditUnionsPage';
import AdminTablePage from 'pages/admin/AdminTablePage';
import LPHistory from 'pages/admin/lp-history/lp-history';
import DashboardPage from 'pages/dashboard';
import DocumentsPage from 'pages/documents';
import DocusignCallback from 'pages/docusign-callback';
import IndicationOfInterest from 'pages/forms/IndicationOfInterest';
import PurchaseCommitment from 'pages/forms/PurchaseCommitment';
import LogoutPage from 'pages/Logout';
import DueDiligencePage from 'pages/lp/due-dilligence/DueDiligencePage';
import AdminDueDiligence from 'pages/lp/edit/AdminDueDiligencePage';
import { PrivacyNoticePage } from 'pages/PrivacyPage';
import UserProfilePage from 'pages/profile/UserProfilePage';
import ReportsPage from 'pages/reports';
import { TermsOfUsePage } from 'pages/TermsOfUsePage';
import * as paths from 'paths';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
const LearnPage = React.lazy(() => import('pages/LearnPage'));
const ContactPage = React.lazy(() => import('pages/ContactPage'));
const SellPage = React.lazy(() => import('pages/SellPage'));
const FAQsPage = React.lazy(() => import('pages/FAQPage'));
const BuyPage = React.lazy(() => import('pages/BuyPage'));
const LpsPage = React.lazy(() => import('pages/lps'));
const UserAllLPsList = React.lazy(() => import('pages/lps/all'));
const UserMyLPsList = React.lazy(() => import('pages/lps/my'));
const UserOpenLPsList = React.lazy(() => import('pages/lps/open'));
const UserRecentLPsList = React.lazy(() => import('pages/lps/recent'));
const TermSheetPage = React.lazy(() => import('pages/lp'));
const AdminUserVerification = React.lazy(() =>
  import('pages/admin/AdminUserVerification/AdminUserVerification')
);

const LPEditPage = React.lazy(() => import('pages/lp/edit'));
const Routes = (
  <Switch>
    <RouteMarketingLayout
      exact
      seo={{ title: 'Home', keywords: seoConfig.seoHomePageKeyWords }}
      path="/"
      component={HomePage}
    />
    <RouteMarketingLayout
      exact
      seo={{ title: 'Buy', keywords: seoConfig.seoBuyPageKeyWords }}
      path={paths.buy}
      component={() => (
        <React.Suspense fallback={<LoadingSpinner />}>
          <BuyPage />
        </React.Suspense>
      )}
    />
    <RouteMarketingLayout
      exact
      seo={{ title: 'Sell', keywords: seoConfig.seoSellPageKeyWords }}
      path={paths.sell}
      component={() => (
        <React.Suspense fallback={<LoadingSpinner />}>
          <SellPage />
        </React.Suspense>
      )}
    />
    <RouteMarketingLayout
      exact
      seo={{ title: 'Learn', keywords: seoConfig.seoLearnPageKeyWords }}
      path={paths.learn}
      component={() => (
        <React.Suspense fallback={<LoadingSpinner />}>
          <LearnPage />
        </React.Suspense>
      )}
    />
    <RouteMarketingLayout
      exact
      seo={{ title: 'Contact' }}
      path={paths.contact}
      component={() => (
        <React.Suspense fallback={<LoadingSpinner />}>
          <ContactPage />
        </React.Suspense>
      )}
    />
    <RouteMarketingLayout
      exact
      seo={{ title: 'Privacy Notice' }}
      path={paths.privacy}
      component={PrivacyNoticePage}
    />
    <RouteMarketingLayout
      exact
      seo={{ title: '' }}
      path={paths.termsofuse}
      component={TermsOfUsePage}
    />
    <RouteMarketingLayout
      exact
      path={paths.faqs}
      component={routeProps => (
        <React.Suspense fallback={<LoadingSpinner />}>
          <FAQsPage {...routeProps} />
        </React.Suspense>
      )}
    />
    <RouteLayout exact path="/logout" component={LogoutPage} />
    <RouteLayout path="/docs" component={DocumentsPage} />
    <RouteLayout path="/reports" component={ReportsPage} />
    <RouteLayout path="/docusign-callback" component={DocusignCallback} />
    <RouteLayout exact path="/profile" component={UserProfilePage} />
    <RouteLayout
      exact
      path={paths.lps}
      component={routeProps => (
        <React.Suspense fallback={<LoadingSpinner />}>
          <LpsPage {...routeProps} />
        </React.Suspense>
      )}
      seo={{ title: "ALL LP's" }}
    />
    <RouteLayout
      exact
      path={paths.userRecentLps}
      component={routeProps => (
        <React.Suspense
          fallback={
            <Layout>
              <LoadingSpinner />
            </Layout>
          }
        >
          <UserRecentLPsList {...routeProps} />
        </React.Suspense>
      )}
      seo={{ title: "Recent LP's" }}
    />
    <RouteLayout
      exact
      path={paths.userOpenLps}
      component={routeProps => (
        <React.Suspense
          fallback={
            <Layout>
              <LoadingSpinner />
            </Layout>
          }
        >
          <UserOpenLPsList {...routeProps} />
        </React.Suspense>
      )}
      seo={{ title: "Open LP's" }}
    />
    <RouteLayout
      exact
      path={paths.userMyLps}
      component={routeProps => (
        <React.Suspense
          fallback={
            <Layout>
              <LoadingSpinner />
            </Layout>
          }
        >
          <UserMyLPsList {...routeProps} />
        </React.Suspense>
      )}
      seo={{ title: "My LP's" }}
    />
    <RouteLayout
      exact
      path={paths.dashboard}
      component={DashboardPage}
      seo={{ title: 'Dashboard' }}
    />
    <RouteLPLayout
      exact
      path="/lp/:id"
      component={routeProps => (
        <React.Suspense
          fallback={
            <Layout>
              <LoadingSpinner />
            </Layout>
          }
        >
          <TermSheetPage {...routeProps} />
        </React.Suspense>
      )}
      seo={{ titleTemplate: 'Termsheet |', keywords: seoConfig.seoTermSheetPageKeyWords }}
    />
    <RouteLPLayout
      path="/lp/:id/due-diligence"
      component={DueDiligencePage}
      seo={{ title: 'Due Diligence' }}
    />
    <RouteLPLayout
      path="/admin/lp/:id/edit"
      component={routeProps => (
        <React.Suspense
          fallback={
            <Layout>
              <LoadingSpinner />
            </Layout>
          }
        >
          <LPEditPage {...routeProps} />
        </React.Suspense>
      )}
      seo={{ title: 'Edit User' }}
    />

    <RouteLayout exact path="/admin/lp/history/:id" component={LPHistory} />

    <RouteLPLayout path="/lp/:id/due-diligence" exact component={AdminDueDiligence} />
    <RouteLPLayout path={paths.adminUserProfile} component={UserProfilePage} />
    <Route path={paths.lpIndicationOfInterest}>
      <IndicationOfInterest key="indication-of-interest" />
    </Route>
    <Route path={paths.lpPurchaseCommitment} exact>
      <PurchaseCommitment />
    </Route>
    <Route path={['/admin/manage', '/admin/manage/users']} exact>
      <Redirect to="/admin/manage/users/ready-to-verify" />
    </Route>
    <Route path={['/admin/manage/offerings']} exact>
      <Redirect to="/admin/manage/offerings/all" />
    </Route>
    <Route exact path="/admin/manage/:type/:filter">
      <AdminTablePage />
    </Route>
    <Route path="/admin/manage/users/:filter/:userId">
      <React.Suspense
        fallback={
          <Layout>
            <LoadingSpinner />
          </Layout>
        }
      >
        <Switch>
          <Route path="/admin/manage/users/:filter/:userId" exact>
            <AdminUserVerification />
          </Route>
          <Route path="/admin/manage/users/credit-unions/:creditUnionName/:rtNumber">
            <AdminCreditUnionsPage />
          </Route>
        </Switch>
      </React.Suspense>
    </Route>

    <Route path="/admin/manage/offerings/:filter/:lpNumber">
      <AdminAcceptRejectPage />
    </Route>

    <Route path="*"></Route>
  </Switch>
);

export default Routes;
