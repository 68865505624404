import { Reducer } from 'redux';
import { UserManagementState, UserManagementActionTypes } from './types';
import { getName } from '../../utils/helpers';

export const initialState: UserManagementState = {
  data: undefined,
  errors: undefined,
  sfErrors: undefined,
  selected: undefined,
  loading: false,
  sfLoading: false,
  acceptLoading: false,
  updated: undefined,
  accepted: [],
};

export const UserManagementReducer: Reducer<UserManagementState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UserManagementActionTypes.FETCH_REQUEST:
      return { ...state, loading: true };
    case UserManagementActionTypes.FETCH_SUCCESS:
      action.payload = action.payload.map(obj => {
        obj.displayName = getName(obj);
        return obj;
      });
      return { ...state, loading: false, data: action.payload };
    case UserManagementActionTypes.FETCH_ERROR:
      return { ...state, loading: false, errors: action.payload };
    case UserManagementActionTypes.CLEAR_REQUESTED:
      return { ...state, loading: false, data: undefined };

    case UserManagementActionTypes.FETCH_RESOURCE_REQUEST:
      return { ...state, loading: true };
    case UserManagementActionTypes.FETCH_RESOURCE_SUCCESS:
      action.payload = action.payload.map(obj => {
        obj.displayName = getName(obj);
        return obj;
      });
      const genericCombinedData = state.data ? [...state.data, ...action.payload] : action.payload;
      return { ...state, loading: false, data: genericCombinedData };
    case UserManagementActionTypes.FETCH_RESOURCE_ERROR:
      return { ...state, loading: false, errors: action.payload };
    case UserManagementActionTypes.CLEAR_FETCH_RESOURCE:
      return { ...state, loading: false, data: undefined };

    case UserManagementActionTypes.FILTER_REQUEST:
      return { ...state, loading: true, data: [] };
    case UserManagementActionTypes.FILTER_SUCCESS:
      action.payload = action.payload.map(obj => {
        obj.displayName = getName(obj);
        return obj;
      });
      return { ...state, loading: false, data: action.payload };
    case UserManagementActionTypes.FILTER_ERROR:
      return { ...state, loading: false, errors: action.payload };

    case UserManagementActionTypes.UPDATE_USER_REQUEST:
      return { ...state, loading: true, updated: false };
    case UserManagementActionTypes.UPDATE_USER_SUCCESS:
      return { ...state, loading: false, updated: true };
    case UserManagementActionTypes.UPDATE_USER_ERROR:
      return { ...state, loading: false, errors: action.payload };
    case UserManagementActionTypes.ACCEPT_USER_REQUEST:
      return { ...state, acceptLoading: true };
    case UserManagementActionTypes.ACCEPT_USER_SUCCESS:
      return { ...state, acceptLoading: false, accepted: [action.payload] };
    case UserManagementActionTypes.ACCEPT_USER_ERROR:
      return { ...state, acceptLoading: false, errors: action.payload };
    case UserManagementActionTypes.CLEAR_ACCEPTED:
      return { ...state, loading: false, acceptLoading: false, accepted: [] };
    case UserManagementActionTypes.SELECT_USER:
      return { ...state, loading: true };
    case UserManagementActionTypes.SELECTED:
      return { ...state, loading: false, selected: action.payload };
    case UserManagementActionTypes.CLEAR_SELECTED:
      return { ...state, loading: false, selected: undefined };
    case UserManagementActionTypes.SF_ACCOUNTNUMBER_SELECT:
      return {
        ...state,
        selected: {
          ...state.selected,
          accountNumbers: state.selected.accountNumbers.map(a => {
            a.selected = a.RoutingNumber === action.payload && !a.selected;
            return a;
          }),
        },
      };
    case UserManagementActionTypes.SF_ACCOUNTNUMBER_REQUEST:
      return { ...state, sfLoading: true };
    case UserManagementActionTypes.SF_ACCOUNTNUMBER_SUCCESS:
      return {
        ...state,
        sfLoading: false,
        selected: {
          ...state.selected,
          accountNumbers: action.payload.filter(
            (e, i) => action.payload.findIndex(a => a['RoutingNumber'] === e['RoutingNumber']) === i
          ),
        },
      };
    case UserManagementActionTypes.SF_ACCOUNTNUMBER_ERROR:
      return { ...state, sfLoading: false, sfErrors: action.payload };
    case UserManagementActionTypes.CLEAR_SF_ACCOUNTNUMBERS:
      return {
        ...state,
        sfLoading: false,
        selected: {
          ...state.selected,
          accountNumbers: undefined,
        },
      };
    default:
      return state;
  }
};
