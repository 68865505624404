import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { History, Store } from '.';
import { SessionTimeoutProvider } from './components/SessionTimeout';
import { colors } from 'utils/theme';
import { LayoutProvider } from './layout/context';
export const ProviderComposer = ({ contexts, children }: any): JSX.Element =>
  contexts.reduceRight((kids, parent) => {
    return React.cloneElement(parent, {
      children: kids,
    });
  }, children);

interface Props {
  children: React.ReactNode;
}

interface Props {
  store: Store;
  history: History;
  theme: any;
}

export const ContextProvider: React.FC<Props> = ({ store, history, theme, children }) => (
  <ProviderComposer
    contexts={[
      <Provider store={store} />,
      <StyledThemeProvider theme={{ ...theme, colors }} />,
      <ConnectedRouter history={history} />,
      <SnackbarProvider SnackbarProps={{ open: true }}>{children}</SnackbarProvider>,
      <SessionTimeoutProvider />,
      <LayoutProvider />,
    ]}
  >
    <ThemeProvider theme={{ ...theme, colors }}>{children}</ThemeProvider>
  </ProviderComposer>
);

export default ContextProvider;
