import React from 'react';
import useCMS from './useCMS';
import LoadingSpinner from '../data/LoadingSpinner';
import Iframe from 'react-iframe';

type IFrameRes = {
  iFrameUrl: string;
};

interface Props {
  name: string;
  scrolling?: 'auto' | 'yes' | 'no';
  className?: string;
  allowFullScreen?: boolean;
  position?:
    | 'relative'
    | 'absolute'
    | 'fixed'
    | 'sticky'
    | 'static'
    | 'inherit'
    | 'initial'
    | 'unset';
  display?: 'block' | 'none' | 'inline';
  height?: string;
  width?: string;
  frameBorder?: number;
  loading?: 'auto' | 'eager' | 'lazy';
  importance?: 'auto' | 'high' | 'low';
  overflow?: string;
  style?: object;
}

const CMSIFrame: React.FC<Props> = ({ name, height, ...props }) => {
  const { data, loading } = useCMS<IFrameRes>(`/page/${name}`);

  if (loading) return <LoadingSpinner />;

  return (
    <Iframe
      id={`${name}-title`}
      title={`${name}-page`}
      url={data?.iFrameUrl}
      height={height}
      {...props}
    />
  );
};

CMSIFrame.defaultProps = {
  width: '100%',
  frameBorder: 0,
  loading: 'eager',
  height: '100%',
  style: { margin: 'none', border: 'none', padding: 0 },
};

export default CMSIFrame;
