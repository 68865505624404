import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { BackButton } from '../Common/BackButton';
import { SignAutorityForm } from './SignAuthorityForm';
import { lighten } from '@material-ui/core/styles';
import { LPButton } from '../../components/Common/LPButton';
interface SignAuthorityOverlayProps {
  displayOverlay?: boolean;
  onClose?: Function;
  addSigningAuth?: Function;
  negateSigningAuth?: Function;
  ForwardSigningRequest?: Function;
  userInfo?: any;
  loading: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: '80%',
    height: '75%',
    margin: '5% 10%',
    display: (props: SignAuthorityOverlayProps) => (props.displayOverlay ? 'block' : 'none'),
  },
  backButton: {
    marginTop: 10,
    '& .MuiIcon-root': {
      color: theme.colors.white,
    },
  },
  overlay: {
    display: (props: SignAuthorityOverlayProps) => (props.displayOverlay ? 'flex' : 'none'),
    position: 'fixed',
    height: '100vh',
    width: '100%',
    top: 0,
    backgroundColor: theme.colors.navyBlue,
    zIndex: 1000,
  },
  wrapper: {
    width: '100%',
    paddingTop: '200px',
  },
  buttons: {
    textAlign: 'center',
  },
  noButton: {
    color: theme.colors.white,

    padding: '5px 35px',
    fontWeight: 600,
  },
  yesButton: {
    color: theme.colors.white,

    padding: '5px 35px',
    fontWeight: 600,
    marginLeft: '50px',
  },
  title: {
    color: theme.colors.white,
    fontSize: 30,
    textAlign: 'center',
  },
  subTitle: {
    color: theme.colors.white,
    fontSize: 12,
    textAlign: 'center',
  },
  responseMessage: {
    width: '90%',
    textAlign: 'center',
    color: theme.colors.white,
    margin: 50,
  },
  responseMessageTitle: {
    '& span': {
      color: lighten(theme.colors.darkGrey, 0.5),
    },
  },
}));

const addSigning = props => {
  const { addSigningAuth } = props;
  addSigningAuth();
};

const Overlay = props => {
  const [showForwardForm, setShowForwardForm] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(true);
  useEffect(() => {
    setShowForwardForm(false);
    setShowConfirmation(false);
  }, []);
  const showForm = () => {
    if (props.negateSigningAuth) props.negateSigningAuth();

    setShowForwardForm(true);
    setShowConfirmation(false);
  };
  const hideForm = () => {
    setShowForwardForm(false);
    setShowConfirmation(false);
  };
  const showConf = () => {
    setShowForwardForm(false);
    setShowConfirmation(true);
  };
  const forwardRequest = (recipient: string, recipientEmail: string) => {
    const { ForwardSigningRequest } = props;
    ForwardSigningRequest(recipient, recipientEmail);
    showConf();
  };

  const classes = useStyles(props);
  const { onClose, userInfo } = props;
  if (!showForwardForm && !showConfirmation) {
    return (
      <div className={classes.overlay}>
        <BackButton classes={{ root: classes.backButton }} onClick={onClose} />
        <div className={classes.wrapper}>
          <h2 className={classes.title}>
            Are You a Signing Authority for {userInfo.data && userInfo.data.CreditUnionName}?
          </h2>
          <h2 className={classes.subTitle}>
            Please indicate if you have the ability to sign for your credit union
          </h2>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.noButton}
              onClick={() => showForm()}
            >
              NO
            </Button>
            <LPButton
              variant="contained"
              size="large"
              color="primary"
              className={classes.yesButton}
              onClick={() => addSigning(props)}
              loading={props.loading}
            >
              YES
            </LPButton>
            <LPButton
              variant="contained"
              size="large"
              color="primary"
              className={classes.yesButton}
              onClick={() => onClose()}
            >
              CLOSE
            </LPButton>
          </div>
        </div>
      </div>
    );
  } else if (showForwardForm && !showConfirmation) {
    return (
      <div className={classes.overlay}>
        <BackButton classes={{ root: classes.backButton }} onClick={onClose} />
        <div className={classes.wrapper}>
          <h2 className={classes.title}>Forward the User Agreement</h2>
          <h2 className={classes.subTitle}>
            Please type in a name and email to forward the user agreement to someone else in your
            credit union who is a signing authority.
          </h2>
          <br />
          <SignAutorityForm
            maxWidth={800}
            ForwardSigningRequest={forwardRequest}
            backOnClick={() => hideForm()}
          />
        </div>
      </div>
    );
  } else if (showConfirmation) {
    return (
      <div className={classes.overlay}>
        <BackButton classes={{ root: classes.backButton }} onClick={onClose} />
        <div className={classes.wrapper}>
          <h2 className={classes.title}>We have forwarded the request</h2>
          <div className={classes.buttons}>
            <Button variant="contained" size="large" color="primary" onClick={() => onClose()}>
              CLOSE
            </Button>
          </div>
        </div>
      </div>
    );
  }
};

export const SignAuthorityOverlay: FunctionComponent<SignAuthorityOverlayProps> = props => {
  const { onClose, displayOverlay } = props;
  return <Overlay {...props} displayOverlay={displayOverlay} onClose={onClose} />;
};
