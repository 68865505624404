import React, { useEffect, useRef, useState, FC } from 'react';
import { Button } from '@material-ui/core';
import { HeaderNavBarMenu } from './HeaderNavBarMenu';
import styled from 'styled-components';
import CC_LP_Exchange_Logo_Icon_Catalyst from 'assets/images/CC_LP_Exchange_Logo_Icon_Catalyst.svg';
import { AdapterLink } from 'components/Common/AdapterLink';
import { fluidPadding } from 'utils/styles';
import { animated, useSpring } from 'react-spring';

const ColorBar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 41% 20% 14% 25%;
  grid-template-rows: 0.75em;
  .blue-bar {
    background-image: linear-gradient(92deg, #1b3d6d, #114fa7);
  }
  .gray-bar {
    background-image: linear-gradient(94deg, #d0d0d0, #686868);
  }
  .yellow-bar {
    background-image: linear-gradient(261deg, #fadc00, #fcb714);
  }
  .red-bar {
    background-image: linear-gradient(267deg, #ed2c37, #ce202f);
  }
`;

const MarketingHeader: FC = () => {
  const header = useRef(null);
  const [shrink, setShrink] = useState(false);

  // run the header animation once right after render (even if the user hasn't scrolled yet)
  // so that the header appears in the event the scroll bar is not at the top

  const headerAnimation = () => {
    setShrink(header.current && window.pageYOffset > header.current.offsetHeight);
  };

  useEffect(() => {
    window.addEventListener('scroll', headerAnimation);
    return () => {
      window.removeEventListener('scroll', headerAnimation);
    };
  });

  const animation = useSpring({ height: shrink ? `35px` : '64px' });

  return (
    <Wrapper ref={header}>
      <ColorBar>
        <div className="blue-bar" />
        <div className="gray-bar" />
        <div className="yellow-bar" />
        <div className="red-bar" />
      </ColorBar>

      <Navbar>
        <div id="logo">
          <Button to="/" component={AdapterLink}>
            <animated.img src={CC_LP_Exchange_Logo_Icon_Catalyst} alt="logo" style={animation} />
          </Button>
        </div>
        <HeaderNavBarMenu />
      </Navbar>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 100;
  margin: 0;
  padding: 0;
`;

const Navbar = styled.div`
  ${fluidPadding};
  background: #fff;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(0, 300px) auto;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  margin: 0;

  #logo {
    display: flex;
    justify-self: flex-start;
    align-content: center;
    align-items: center;
    padding: 1em 0;
    & > * {
      width: 100%;
      /* height: 100%; */
    }
  }

  img {
    /* width: 100%; */
    /* height: auto; */
    margin: 0;
  }

  nav {
    justify-self: flex-end;
    justify-items: flex-end;
    align-items: center;
  }
`;

export default MarketingHeader;
