import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { DocusignActionTypes } from './types';
import {
  docusignCreateCARequest,
  docusignCreateCAError,
  docusignCreateCASuccess,
  docusignSyncRequest,
  docusignSyncError,
  docusignSyncSuccess,
  docusignLatestCAStatusRequest,
  docusignLatestCAStatusError,
  docusignLatestCAStatusSuccess,
  docusignRejectCARequest,
  docusignRejectCAError,
  docusignRejectCASuccess,
} from './actions';
import { callApi } from '../../utils/api';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

function* handleDocusignCreateCARequest(action: ReturnType<typeof docusignCreateCARequest>) {
  try {
    const res = yield call(
      callApi,
      'post',
      API_ENDPOINT,
      '/docusign/agreements/confidentiality',
      action.payload
    );

    yield res.error ? put(docusignCreateCAError(res.error)) : put(docusignCreateCASuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(docusignCreateCAError(err.stack!))
      : put(docusignCreateCAError('An unknown error occured.'));
  }
}

function* handleDocusignLatestCAStatusRequest(
  action: ReturnType<typeof docusignLatestCAStatusRequest>
) {
  try {
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `/usermanagement/views/admin/users/${action.payload}/latestcastatus`
    );

    yield res.error
      ? put(docusignLatestCAStatusError(res.error))
      : put(docusignLatestCAStatusSuccess(res));
  } catch (err) {
    yield err instanceof Error
      ? put(docusignLatestCAStatusError(err.stack!))
      : put(docusignLatestCAStatusError('An unknown error occured.'));
  }
}

function* handleDocusignSyncRequest(action: ReturnType<typeof docusignSyncRequest>) {
  try {
    const res = yield call(callApi, 'post', API_ENDPOINT, '/docusign/agreements/sync', {
      envelopeId: action.payload,
    });

    yield res.error ? put(docusignSyncError(res.error)) : put(docusignSyncSuccess());
  } catch (err) {
    yield err instanceof Error
      ? put(docusignSyncError(err.stack!))
      : put(docusignSyncError('An unknown error occured.'));
  }
}

function* handleDocusignRejectCARequest(action: ReturnType<typeof docusignRejectCARequest>) {
  try {
    const res = yield call(
      callApi,
      'post',
      API_ENDPOINT,
      `/docusign/agreements/confidentiality/${action.payload}/rejections`
    );

    yield res.error ? put(docusignRejectCAError(res.error)) : put(docusignRejectCASuccess());
  } catch (err) {
    yield err instanceof Error
      ? put(docusignRejectCAError(err.stack!))
      : put(docusignRejectCAError('An unknown error occured.'));
  }
}

function* watchDocusignCreateCARequest() {
  yield takeLatest(DocusignActionTypes.DOCUSIGN_CREATE_CA_REQUEST, handleDocusignCreateCARequest);
}

function* watchDocusignBulkCAStatusRequest() {
  yield takeLatest(
    DocusignActionTypes.DOCUSIGN_LATEST_CA_STATUS_REQUEST,
    handleDocusignLatestCAStatusRequest
  );
}

function* watchDocusignSyncRequest() {
  yield takeLatest(DocusignActionTypes.DOCUSIGN_SYNC_REQUEST, handleDocusignSyncRequest);
}

function* watchDocusignRejectCARequest() {
  yield takeLatest(DocusignActionTypes.DOCUSIGN_REJECT_CA_REQUEST, handleDocusignRejectCARequest);
}

function* docusignSaga() {
  yield all([
    fork(watchDocusignCreateCARequest),
    fork(watchDocusignBulkCAStatusRequest),
    fork(watchDocusignRejectCARequest),
    fork(watchDocusignSyncRequest),
  ]);
}

export { docusignSaga as DocusignSaga };
