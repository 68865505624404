import React from 'react';
import LPTableCell from '../../../components/Common/LPTableCell';
import { createStyles, makeStyles, TableRow, Theme } from '@material-ui/core';
import { colors } from 'utils/theme';
import { lighten } from '@material-ui/core/styles';
import shortid from 'shortid';
import { LPTableRowMain } from './LPTableRowMain';
import { Header } from './types';
import { LPTableSupplementalView } from './LPTableSupplementalView';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: 'none',
      color: colors.fadedBlack,
      fontWeight: 600,
      paddingBottom: 5,
      '& > td': {
        padding: '5px 16px',
      },
    },
    tableCellHead: {
      borderBottom: 'none',
      color: lighten(colors.fadedBlack, 0.1),
      fontWeight: 600,
      paddingBottom: 5,
    },
    highlighted: {
      backgroundColor: lighten(theme.colors.paleGrey, 0.4),
    },
    tableRow: {
      borderBottom: 'none',
    },
    buttonProgress: {
      position: 'absolute',
      top: '55%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export const LPTableDetailView: React.FC<any> = props => {
  const classes = useStyles(undefined);
  let { row, detailHeaders, supplementalView, supplementalViewHeaders } = props;
  const emptyHeader: Header = {
    label: '',
    keyOrGetter: '',
  };
  const len = detailHeaders.length;
  for (let i = 0; i < props.headers.length - len; i++) {
    detailHeaders.push(emptyHeader);
  }

  return (
    <React.Fragment>
      <TableRow className={classes.highlighted}>
        {detailHeaders.map(header => (
          <LPTableCell
            variant="head"
            showBorder={false}
            className={classes.tableCellHead}
            key={header.label ? header.label : shortid.generate()}
          >
            {header.label}
          </LPTableCell>
        ))}
      </TableRow>
      <LPTableRowMain
        row={row}
        headers={detailHeaders}
        isDetailView={true}
        keyIdentifier={shortid.generate()}
        classes={{ root: classes.root }}
      />
      {supplementalViewHeaders && (
        <LPTableSupplementalView
          supplementalView={supplementalView}
          supplementalViewHeaders={supplementalViewHeaders}
          {...props}
        />
      )}
    </React.Fragment>
  );
};
