import { Collapse, IconButton, Input, InputBaseProps } from '@material-ui/core';
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles';
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons';
import useQuery from 'hooks/useQuery';
import { stringify } from 'querystring';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce, throttle } from 'throttle-debounce';
export interface SearchInputProps extends InputBaseProps {
  param?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ param, inputProps, ...props }) => {
  const { pathname } = useLocation();
  const classes = useStyles(props);
  const history = useHistory();
  let query = useQuery(param);

  const [state, setState] = useState(query);

  const handleReset = () => {
    setState('');
    history.replace(pathname);
  };

  const handleSearch = value => {
    if (!value) history.push(pathname);

    history.replace(`${pathname}?${stringify({ [param]: value })}`);
  };

  const throttled = throttle(500, value => handleSearch(value));

  const debounced = debounce(500, value => handleSearch(value));

  const handleChange = (e: any) => {
    const value = e.currentTarget.value;
    setState(value);
    switch (value) {
      case null:
        handleReset();
        break;
      case value.length > 5:
        debounced(value);
        break;
      default:
        throttled(value);
        break;
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') handleSearch(e.target.value);
    if (e.key === 'Escape') handleReset();
  };

  const showCloseButton = () => {
    switch (state) {
      case null:
        return false;
      case '':
        return false;

      default:
        return true;
    }
  };

  return (
    <div className={classes.root}>
      <Collapse in={showCloseButton()}>
        <IconButton size="small" onClick={handleReset}>
          <ClearIcon />
        </IconButton>
      </Collapse>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon color="action" />
        </div>
        <Input
          id={param}
          name={param}
          value={state}
          disableUnderline
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          inputProps={{
            ...inputProps,
            'aria-label': param,
          }}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          autoComplete="off"
          {...props}
        />
      </div>
    </div>
  );
};

SearchInput.defaultProps = {
  param: 'search',
  placeholder: 'Search...',
};
export default SearchInput;

const useStyles = makeStyles<Theme, SearchInputProps>(theme =>
  createStyles({
    root: {
      // width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(1), // width: '100%',
    },
    search: {
      position: 'relative',
      backgroundColor: fade('rgba(0,0,0,0.05)', 0.075),
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(0, 2, 0, 1),
      '&:hover': {
        backgroundColor: fade('rgba(0,0,0,0.05)', 0.125),
      },
      '&:focus': {
        backgroundColor: fade('rgba(0,0,0,0.05)', 0.125),
        borderColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: props => (props.fullWidth ? '100%' : 'auto'),
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: props => ({
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      borderRadius: theme.shape.borderRadius,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: fade('rgba(0,0,0,0.05)', 0.075),
      [theme.breakpoints.up('sm')]: !props.fullWidth && {
        width: 120,
        '&:focus': {
          width: 200,
          borderColor: theme.palette.info.main,
        },
      },
    }),
  })
);
