import { SortDirection } from '@material-ui/core';
import { TableDataEntry } from 'components/Table/Table';
import _ from 'lodash';

export function createSort(val: any) {
  if (!val) return null;
  const num = parseFloat(val);
  let sort;
  if (isNaN(num))
    sort = _.lowerCase(val)
      .split(' ')
      .join('');
  else sort = parseFloat(val);
  if (val && typeof val === 'string' && val.includes('-')) sort = val.replace('-', '');
  return sort;
}

function detectType(value: unknown, row?: any) {
  switch (typeof value) {
    case 'string':
      return value;

    case 'number':
      return value;

    case 'function':
      return value(row);

    default:
      return 'other';
  }
}

export function createData(
  loading: boolean,
  data: any,
  tableData: TableDataEntry[],
  orderBy: string,
  order: SortDirection,
  query: string
) {
  if (loading) return [];

  const rows = data?.map(r => {
    const rowCells = tableData.map(cell => {
      const value = cell.value
        ? typeof cell.value === 'function'
          ? cell.value(r)
          : cell.value
        : null;
      return {
        ...cell,
        value,
        display: cell.display ? cell.display(r) : value,
        sort: createSort(value),
      };
    });

    let tRow = {
      row: r,
      cells: rowCells,
    };

    rowCells.forEach(c => {
      if (c.field) tRow[c.field] = c.value;
    });

    // if (cells?.length === 0) return null;
    return tRow;
  });

  const searchedData = rows?.filter(({ cells }) => {
    if (query === '') return true;
    if (!query) return true;

    return (
      cells?.filter(sc =>
        [sc.value, sc.display, sc.sort]
          .flat()
          .toString()
          .includes(createSort(query))
      ).length > 0
    );
  });

  return _.orderBy(searchedData, row => createSort(row[orderBy]), order);
}

function createSearchedRow(cells, query) {
  return cells.filter((srow, sIdx) => {
    return !query
      ? srow
      : cells
          .map(sc => [sc.value, sc.display, sc.sort])
          .flat()
          .toString()
          .includes(createSort(query));
  });
}

export const rowsPerPageOptions = [25, 50, 100];
const isDate = /(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})/gm;
