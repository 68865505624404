import { PardotTracker } from '@catalyst/pardot';
import { CssBaseline, Typography, Container } from '@material-ui/core';
import React from 'react';
import { History } from '.';
import TelemetryProvider from './components/data/telemetry/TelemetryProvider';
import { getAppInsights } from './components/data/telemetry/TelemetryService';
import Main from './Main';
import Routes from './Routes';
import { get } from './utils/api';
import { GlobalStyle } from './utils/styles';

export interface Props {
  history: History;
}

interface State {
  appInsights_InstrumentationKey?: string | null;
  pardotPiAId?: string;
  pardotPiCId?: string;
  pardotPiHostname?: string;
}

class App extends React.Component<Props, State> {
  static displayName = App.name;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { history } = this.props;

    if (!sessionStorage.getItem('warmedup')) {
      get('/warmup');
      sessionStorage.setItem('warmedup', 'true');
    }

    const getConfig = async () => {
      return get('/configuration/tracking').then(data => {
        this.setState(data);
        const { pardotPiAId, pardotPiCId, pardotPiHostname } = data;

        try {
          if ((window as any).piTracker !== undefined)
            PardotTracker.initialize(history, pardotPiAId, pardotPiCId, pardotPiHostname);
        } catch (e) {
          console.warn(e);
        }
      });
    };

    if (process.env.NODE_ENV === 'production') getConfig();
  }

  componentWillUnmount() {
    PardotTracker.terminate();
    sessionStorage.removeItem('warm');
  }

  render() {
    const {
      props: { history },
      state: { appInsights_InstrumentationKey },
    } = this;

    if (process.env.NODE_ENV === 'development')
      return (
        <Main history={history} instrumentationKey={appInsights_InstrumentationKey}>
          {Routes}
          <CssBaseline />
          <GlobalStyle />
        </Main>
      );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let appInsights: any;

    if (this.state.appInsights_InstrumentationKey === undefined) return null;

    try {
      return (
        <TelemetryProvider
          instrumentationKey={appInsights_InstrumentationKey}
          after={() => {
            appInsights = getAppInsights();
          }}
        >
          <Main history={history} instrumentationKey={appInsights_InstrumentationKey}>
            {Routes}
            <CssBaseline />
            <GlobalStyle />
          </Main>
        </TelemetryProvider>
      );
    } catch (err) {
      return (
        <Container>
          <Typography>Sorry, there's been an error</Typography>
          <pre>
            <code>{JSON.stringify(err)}</code>
          </pre>
        </Container>
      );
    }
  }
}

App.displayName = 'LPMarketplaceApplication';

export default App;
