import { PardotTracker } from '@catalyst/pardot';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { History } from '.';
import { UserContext } from 'components/data/context';
import ScrollToTop from 'components/ScrollToTop';
import useFlashNotification from 'hooks/useFlashNotification';
import { ApplicationState } from 'store';
import { fetchRequest } from 'store/auth/actions';
import { AuthState } from 'store/auth/types';
import SessionTimeout from 'components/SessionTimeout';
import SignCaPage from './pages/SignCaPage';

const initialUser = {
  authenticated: false,
  verified: false,
  admin: false,
  completedMFA: false,
  id: '',
  creditUnionAccountId: '',
  creditUnionName: '',
  phoneNumber: '',
  routingNumber: '',
  loading: true,
  name: '',
  email: '',
  restrictions: { disableUpload: false, disableReports: false },
};

interface MainProps {
  history: History;
  instrumentationKey: string | null;
}

const Main: React.FC<MainProps> = ({ children, history, instrumentationKey }) => {
  const dispatch = useDispatch();
  const auth = useSelector<ApplicationState, AuthState>(state => state.auth);
  const [mounted, setMounted] = useState<boolean>(false);
  const [user, setUser] = useState<typeof initialUser>(initialUser);
  const flashNotification = useFlashNotification();

  useEffect(() => {
    if (!auth.loading && !auth.errors && !auth.data) {
      dispatch(fetchRequest());
    }

    if (auth.data && user.loading) {
      const formattedUser = {
        data: auth.data,
        authenticated: auth.data.IsAuthenticated,
        verified: auth.data.Roles.includes('Verified'),
        admin: auth.data.Roles.includes('LPAdmin'),
        completedMFA: auth.data.Roles.includes('mfa'),
        id: auth.data.UserObjectId,
        creditUnionAccountId: auth.data.CreditUnionAccountId,
        creditUnionName: auth.data.CreditUnionName,
        phoneNumber: auth.data.PhoneNumber,
        routingNumber: auth.data.RoutingNumber,
        loading: false,
        name: auth.data.Name,
        email: auth.data.Email,
        restrictions: {
          disableUpload: auth.data.DisableUpload,
          disableReports: auth.data.DisableReports,
        },
      };

      setUser(formattedUser);
      if (formattedUser.email) PardotTracker.setPiEmail(formattedUser.email);
    }

    if (!mounted && !user.loading) {
      setMounted(true);
    }
  }, [auth, dispatch, mounted, user.authenticated, user.email, user.loading, user.verified]);

  return (
    <div>
      <UserContext.Provider value={user}>
        <div>{children}</div>
        {user && !user.verified && <SignCaPage></SignCaPage>}
      </UserContext.Provider>
      <ScrollToTop />
      {flashNotification}
      {process.env.NODE_ENV === 'production' && (
        <SessionTimeout loggedIn={user && user.authenticated} />
      )}
    </div>
  );
};

export default Main;
