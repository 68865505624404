import {
  AccountBalanceOutlined,
  Description,
  HomeOutlined,
  PeopleAltOutlined,
} from '@material-ui/icons';
import Link from 'components/Link';
import Table, { TableProps } from 'components/Table/Table';
import AdminLayout from 'layout/Layout';
import React, { Fragment } from 'react';
import { Route, useParams } from 'react-router-dom';
import { DaysInactive } from 'utils/formatters';
import { MenuLink } from 'layout/Sidebar';
import tables from './tables';

export const adminLinks: MenuLink<{ table?: TableProps }, { table?: TableProps }>[] = [
  {
    label: 'Dashboard',
    Icon: HomeOutlined,
    to: '/dashboard',
  },
  {
    label: 'User Management',
    Icon: PeopleAltOutlined,
    to: '/admin/manage/users',
    table: tables.users['ready-to-verify'],
    subLinks: [
      {
        crumb: 'ready-to-verify',
        label: 'Ready to verify',
        to: '/admin/manage/users/ready-to-verify',
        table: tables.users['ready-to-verify'],
      },
      {
        crumb: 'New',
        label: 'New Users',
        to: `/admin/manage/users/new`,
        table: tables.users['new'],
      },
      {
        crumb: 'Inactive',
        label: 'Inactive Users',
        to: `/admin/manage/users/inactive`,
        table: tables.users['inactive'],
      },
      {
        crumb: 'Disabled',
        label: 'Disabled Users',
        to: `/admin/manage/users/disabled`,
        table: tables.users['disabled'],
      },
      {
        crumb: 'Disabled',
        label: 'Credit Unions',
        to: `/admin/manage/users/credit-unions`,
        table: tables.users['credit-unions'],
      },
      {
        label: 'All Users',
        to: '/admin/manage/users/all',
        table: tables.users['all'],
      },
    ],
  },
  {
    label: 'Offer Management',
    Icon: Description,
    table: tables.offerings['all'],
    to: '/admin/manage/offerings',
    subLinks: [
      {
        label: 'Drafts',
        to: `/admin/manage/offerings/drafts`,
        table: tables.offerings['drafts'],
      },
      {
        label: 'Open',
        to: `/admin/manage/offerings/open`,
        table: tables.offerings['open'],
      },
      {
        label: 'Full',
        to: `/admin/manage/offerings/full`,
        table: tables.offerings['full'],
      },
      {
        label: 'Sold',
        to: `/admin/manage/offerings/sold`,
        table: tables.offerings['sold'],
      },
      {
        label: 'Withdrawn',
        to: `/admin/manage/offerings/withdrawn`,
        table: tables.offerings['withdrawn'],
      },
      {
        label: 'Retention',
        to: `/admin/manage/offerings/retention`,
        table: tables.offerings['retention'],
      },
      {
        label: 'Archived',
        to: `/admin/manage/offerings/archived`,
        table: tables.offerings['archived'],
      },
      {
        label: 'All LPs',
        to: `/admin/manage/offerings/all`,
        table: tables.offerings['all'],
      },
      {
        label: 'Purchases',
        to: `/admin/manage/offerings/purchases`,
        table: tables.offerings['purchases'],
      },
      {
        label: 'Indications',
        to: `/admin/manage/offerings/indications`,
        table: tables.offerings['indications'],
      },
    ],
  },
  {
    label: 'Account',
    Icon: AccountBalanceOutlined,
    subLinks: [{ label: 'Logout', to: '/logout' }],
  },
];

export const AdminTablePage = () => {
  const { name, rtNumber } = useParams();
  // const tableData = tables?.[type]?.[table];

  return (
    <Table
      title={`${name} - ${rtNumber}`}
      subtitle="Select a user to view their profile"
      endpoint="/usermanagement/views/admin/users"
      orderBy="name"
      order="asc"
      oData={{ $filter: [`creditUnionName eq '${name}'`, `rtNumber eq '${rtNumber}'`] }}
      tableData={[
        { field: 'creditUnionName', header: 'union', value: row => row.creditUnionName }, // Remove After Filter
        { field: 'rtNumber', header: 'number', value: row => row.rtNumber }, // Remove After Filter
        {
          field: 'name',
          header: 'Name',
          value: row => row.name,
          display: row => <Link to={`/admin/user/profile/${row.id}`}>{row.name}</Link>,
        },
        { field: 'jobTitle', header: 'Job Title', value: row => row.jobTitle },
        { field: 'emailAddress', header: 'Email Address', value: row => row.emailAddress },
        { field: 'phoneNumber', header: 'Phone Number', value: row => row.phoneNumber },
        {
          field: 'lastActiveDate',
          header: 'Days Inactive',
          value: row => row.lastActiveDate,
          display: row => DaysInactive(row),
        },
      ]}
    />
  );
};

const AdminRoutes: any = () => (
  <AdminLayout>
    <Fragment>
      <Route path="/">j;lkj</Route>

      {/* <Route path="/admin/manage/purchases/:lpNumber">
        <AllLpsSubTable />
      </Route> */}
    </Fragment>
  </AdminLayout>
);

export default AdminRoutes;
