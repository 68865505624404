import React, { useEffect } from 'react';
import { IconButton, makeStyles, Snackbar, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  success: {
    color: theme.colors.signupBlue,
  },
  error: {
    color: theme.colors.lipstick,
  },
}));

type event = 'success' | 'error';

interface LPSnackBarProps {
  isOpen: boolean;
  type: event;
  message: string;
}

export const LPSnackBar = (props: LPSnackBarProps) => {
  const classes = useStyles(props);
  const { isOpen, type, message } = props;
  const [open, setOpen] = React.useState(isOpen || false);

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={
        <span
          className={clsx({
            [classes.success]: type === 'success',
            [classes.error]: type === 'error',
          })}
        >
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
};
