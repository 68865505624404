import Axios, { AxiosPromise } from 'axios';
import { stringify } from 'querystring';
/**
 * Ex: https://localhost:44388/
 */
export const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const TransformResponse = (method?: any) => {
  let defaultTransformer = [].concat(Axios.defaults.transformResponse);
  if (method) return defaultTransformer.concat(method);
  return defaultTransformer;
};

const headers = {
  'Access-Control-Allow-Origin': '*',
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const axiosConfig = {
  headers,
};

export const AxiosInstance = Axios.create({
  baseURL: '/api',
  headers,
  paramsSerializer: args => stringify(args),
});

export const get = async (path: string) => {
  return await callApi('get', apiUrl, path).catch(e => new Error());
};

export const post = async (path: string, data?: any) => {
  return await callApi('post', apiUrl, path, data);
};

export const callApi = async (method: string, url: string, path: string, data?: any) => {
  let res: AxiosPromise<any>;

  switch (method.toLowerCase()) {
    case 'get':
      res = Axios.get(url + 'api' + path);
      break;
    case 'post':
      res = Axios.post(url + 'api' + path, JSON.stringify(data), axiosConfig);
      break;
    case 'patch':
      res = Axios.patch(url + 'api' + path, JSON.stringify(data), axiosConfig);
      break;
    case 'put':
      res = Axios.put(url + 'api' + path, JSON.stringify(data), axiosConfig);
      break;
    case 'delete':
      res = Axios.delete(url + 'api' + path);
      break;
  }

  const result = await res;

  return result.data;
};
