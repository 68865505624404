import { logUserIn, logUserOut } from '../utils/helpers';
import { Button } from '@material-ui/core';
import UserInfoDropdown from '../layout/Marketing/UserInfoDropdown';
import { UserContext } from './data/context';
import React, { FC, useContext } from 'react';

interface Props {
  className?: string;
}

export const LoginButton: FC<Props> = ({ className }) => (
  <Button className={className} onClick={logUserIn} variant="outlined" color="primary">
    Login
  </Button>
);

export const LogoutButton: FC<Props> = ({ className }) => (
  <Button className={className} onClick={logUserOut} variant="outlined">
    Logout
  </Button>
);

export const LogInOrAccountIconButton: FC<Props> = () => {
  const user = useContext(UserContext);

  if (user.loading) {
    return (
      <Button variant="outlined" style={{ border: 'none' }}>
        &nbsp;
      </Button>
    );
  } else {
    if (user.authenticated) {
      return <UserInfoDropdown />;
    } else {
      return <LoginButton />;
    }
  }
};

export const LogInOrOutButton = () => {
  const user = useContext(UserContext);

  if (user?.loading) return null;

  if (!user?.authenticated) return <LogoutButton />;

  return <LoginButton />;
};
