import { Reducer } from 'redux';
import { LPState, LPActionTypes } from './types';

export const initialState: LPState = {
  data: undefined,
  errors: undefined,
  selected: undefined,
  loading: false,
  lpData: {},
  lpImages: [],
};

export const LPReducer: Reducer<LPState> = (state = initialState, action) => {
  switch (action.type) {
    case LPActionTypes.FETCH_REQUEST:
      return { ...state, loading: true };
    case LPActionTypes.FETCH_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case LPActionTypes.FETCH_LP_IMAGES_REQUEST:
      return { ...state, loading: true, lpImages: [] };
    case LPActionTypes.FETCH_LP_IMAGES_SUCCESS:
      return { ...state, loading: false, lpImages: action.payload };

    case LPActionTypes.FETCH_SINGLE_LO_SUCCESS:
      return {
        ...state,
        errors: undefined,
        loading: false,
        lpData: { ...state.lpData, [action.payload.LPIdentifier]: action.payload },
      };
    case LPActionTypes.FETCH_ERROR:
      return { ...state, loading: false, errors: action.payload };
    case LPActionTypes.SELECT:
      return { ...state, loading: true };
    case LPActionTypes.SELECTED:
      return { ...state, loading: false, selected: action.payload };
    case LPActionTypes.CLEAR_SELECTED:
      return { ...state, loading: false, selected: undefined };
    case LPActionTypes.DUE_DILIGENCE_REQUEST:
      return { ...state, loading: true };
    case LPActionTypes.DUE_DILIGENCE_SUCCESS:
      return { ...state, loading: false, files: action.payload };
    case LPActionTypes.DUE_DILIGENCE_ERROR:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

//const update = (array, action) => {
//    return array.map((item) => {
//        if (item.id !== action.payload.id) {
//            return item;
//        }

//        return {
//            ...item,
//            ...action.item
//        };
//    })
//};

//const remove = (array, action) => {
//    return array.filter(i => i.id !== action.payload.id);
//};
