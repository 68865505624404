import React, { FC } from 'react';
import {
  Box as MuiBox,
  BoxProps,
  PaperProps,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import useAxios from 'hooks/useAxios';
import Axios from 'axios';
import { Dollar, forSaleAmount, TableDate, Percentage } from 'utils/formatters';
import LoadingSpinner from '../../components/data/LoadingSpinner';

let Box: FC<BoxProps & PaperProps>;
Box = MuiBox as any;

const useAllLpsStyles = makeStyles(theme =>
  createStyles({
    row: { border: 'none' },
    accecptOrReject: {
      button: {
        ':root': {
          color: theme.palette.success,
        },
        width: '100%',
        display: 'flex',
      },
    },
    overview: {
      padding: theme.spacing(1),
      backgroundColor: theme.colors.veryLightBlue,
      marginTop: theme.spacing(2),
      fontSize: '110%',
      '& th': {
        fontWeight: 'bold',
      },
      '& td': {
        fontWeight: 500,
      },
      '&tr,th,td': {
        border: 'none',
      },
    },
    entries: {
      marginTop: theme.spacing(5),
    },
  })
);
interface Props {
  lpNumber: string;
}
const Overview: React.FC<Props> = ({ lpNumber }) => {
  const classes = useAllLpsStyles();

  const [{ data: offeringData, loading }] = useAxios<SummaryInfo>(
    {
      method: 'get',
      url: `/marketplace/views/admin/offerings`,
      params: {
        $top: 1000,
        $filter: `id eq '${lpNumber}'`,
      },
      transformResponse: []
        .concat(Axios.defaults.transformResponse)
        .concat(([data]) => data)
        .concat(d => ({
          ...d,
          type: d?.LoanType,
          state: d?.State,
          forSaleAmount: forSaleAmount(d?.AmountAvailableForSalePercent, d?.PoolBalance),
          calculatedAmountRemaining: Dollar(d.CalculatedAmountRemaining),
          seller: d?.SellerName,
          settlementDate: TableDate(d?.SettlementDate, 'simple'),
          poolBalanceAsOf: TableDate(d?.PoolBalanceAsOf, 'simple'),
          dueDiligenceDeadline: TableDate(d?.DueDiligenceDeadline, 'simple'),
          totalIndications: Dollar(d?.IndicationsOfInterestTotal),
          totalPurchases: Dollar(d?.PurchaseCommitmentsTotal),
          ioiOverSubscriptionPercent: Percentage(d?.IOIOverSubscriptionPercentage),
          ioiOverSubscriptionAmount: Dollar(d?.IOIOverSubscriptionAmount),
        })),
    },
    { useCache: false }
  );

  if (loading) return <LoadingSpinner />;

  return (
    <Box marginY={3} component={Paper} variant="outlined" className={classes.overview}>
      <TableContainer>
        <MuiTable>
          <TableHead>
            <TableRow className={classes.row}>
              <TableCell align="center" size="small">
                For Sale Amount
              </TableCell>
              <TableCell align="center" size="small">
                Pool Balance As Of Date
              </TableCell>
              <TableCell align="center" size="small">
                Calculated Amount Remaining
              </TableCell>
              <TableCell align="center" size="small">
                Total Indications ($)
              </TableCell>
              <TableCell align="center" size="small">
                Total Accepted PC's
              </TableCell>
              <TableCell align="center" size="small">
                IOI Oversubscription %
              </TableCell>
              <TableCell align="center" size="small">
                IOI Oversubscription Amt
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell align="center" size="small">
                {offeringData?.forSaleAmount}
              </TableCell>
              <TableCell align="center" size="small">
                {offeringData?.poolBalanceAsOf}
              </TableCell>
              <TableCell align="center" size="small">
                {offeringData?.calculatedAmountRemaining}
              </TableCell>
              <TableCell align="center" size="small">
                {offeringData?.totalIndications}
              </TableCell>
              <TableCell align="center" size="small">
                {offeringData?.totalPurchases}
              </TableCell>
              <TableCell align="center" size="small">
                {offeringData?.ioiOverSubscriptionPercent}
              </TableCell>
              <TableCell align="center" size="small">
                {offeringData?.ioiOverSubscriptionAmount}
              </TableCell>
            </TableRow>
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Box>
  );
};
interface SummaryInfo {
  type;
  state: string;
  forSaleAmount: string;
  amountRemaining: string;
  seller: string;
  settlementDate: string;
  dueDiligenceDeadline: string;
  totalIndications: string;
  totalPurchases: string;
  calculatedAmountRemaining: string;
  poolBalanceAsOf: string;
  ioiOverSubscriptionPercent: string;
  ioiOverSubscriptionAmount: string;
}

export default Overview;
