import Breadcrumbs from 'components/Breadcrumbs';
import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from 'layout/Layout';
import adminTables from './tables';
import Table, { TableProps } from 'components/Table/Table';
import { Button } from '@material-ui/core';

interface Props {
  table?: TableProps;
}

const AdminTablePage: React.FC<Props> = ({ table, children }) => {
  const { type, filter } = useParams();
  const seo = {
    title: `${type} / ${filter}`,
  };
  return (
    <Layout seo={seo}>
      <Breadcrumbs
        links={[
          {
            to: `/admin/manage/${type}`,
            label: type === 'users' ? 'User Management' : 'Offer Management',
          },
          { to: `/admin/manage/${type}/${filter}`, label: adminTables[type][filter].title },
        ]}
      />
      <Table
        key={`${type}-${filter}`}
        tableProps={{ stickyHeader: true }}
        height="83vh"
        {...adminTables[type][filter]}
      />
    </Layout>
  );
};

export default AdminTablePage;
