import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { UserContext } from 'components/data/context';
import DueDiligenceDocuments from 'pages/lp/due-dilligence/DueDiligenceDocuments';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationState } from 'store';
import { fetchRequest } from 'store/lps/actions';
import { select } from 'store/lps/actions';
import { LPState } from 'store/lps/types';
import { pathReplace, paths } from 'store/routePaths';
import styled from 'styled-components';
import { selectData } from 'utils/helpers';

interface Props extends RouteComponentProps<{ id: string }> {
  id: string;
  indicated: boolean;
  committed: boolean;
  lpNumber: string;
}

const PurchaseStatusBanner = styled.div.attrs((props: any) => ({
  committed: props.committed,
}))`
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background: ${(props: any) => (!props.committed ? 'transparent' : 'auto')};
`;

const DueDiligencePage: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const lp = useSelector<ApplicationState, LPState>(state => state.lp);

  const { committed } = props;
  const user = useContext(UserContext);

  useEffect(() => {
    dispatch(select(props.match.params.id));
  }, [dispatch, props.match.params.id]);

  if (user && !lp?.loading && !user?.loading && !user?.data?.Roles?.includes('mfa')) {
    return <Redirect to={`/api/auth/pii/lp/${props.match.params.id}`} />;
  }

  if (lp?.errors) {
    console.log('Errors');
    console.log(lp);
    return (
      <Typography className={classes.label}>The requested offering is not available.</Typography>
    );
  }

  let daysRemaining: any = undefined;
  const progress = (
    <div
      style={{
        minHeight: 70,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={24} color="secondary" />
    </div>
  );

  if (lp.lpData[props.match.params.id] && !lp?.loading) {
    daysRemaining = lp.lpData[props.match.params.id].DueDiligenceDaysRemaining;
  }

  return (
    <div className={classes.wrapper}>
      <section className={classes.root}>
        <div className={classes.number}>
          <div className={classes.numberWrapper}>
            <div style={{ position: 'relative' }}>
              <span>{daysRemaining === undefined ? progress : daysRemaining}</span>
              <div>DAYS LEFT</div>
            </div>
          </div>
        </div>
        <div>
          <h1 className={classes.title}>LOAN PARTICIPATION PORTFOLIO #{props.match.params.id}</h1>
          <p className={classes.descriptiveText}>
            Download any files you need for your records or additional research.
          </p>

          <section className={classes.dueDiligenceDocs}>
            <h3>Due Diligence Documents</h3>
            <DueDiligenceDocuments match={props.match} {...props} />
          </section>
        </div>
      </section>
      {!lp?.loading && !user?.loading && !user.admin && (
        <PurchaseStatusBanner committed={committed}>
          <PurchaseButton committed={committed} classes={classes} />
        </PurchaseStatusBanner>
      )}
    </div>
  );
};

export default DueDiligencePage;

const PurchaseButton = ({ committed, classes }) => {
  const { id: lpNumber } = useParams();
  const history = useHistory();
  const handleClick = e => {
    history.push(pathReplace(paths.lpPurchaseCommitment, lpNumber));
  };
  if (committed) {
    return <div className={classes.submitButton}>Purchase Request Submitted</div>;
  } else {
    return (
      <Button
        color="primary"
        variant="contained"
        className={classes.submitButton}
        onClick={handleClick}
      >
        SUBMIT PURCHASE
      </Button>
    );
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundImage: `linear-gradient(232deg, ${theme.colors.darkSkyBlue}, #397cc6)`,
    },
    label: {
      textAlign: 'left',
      margin: '10px',
    },
    root: {
      flex: '1',
      display: 'flex',
      alignItems: 'flex-start',
      padding: '100px 150px 100px 100px',
      '& > div': {
        flex: '1 1 auto',
        '&:nth-child(1)': {
          flex: '0 0 auto',
        },
        '&:nth-child(2)': {
          marginLeft: 20,
          alignSelf: 'center',
        },
      },
    },
    number: {
      color: theme.colors.white,
      display: 'flex',
      justifyContent: 'flex-end',
      height: 100,
    },
    numberWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: 10,
      padding: '0 5px',
      backgroundColor: theme.colors.lipstick,
      width: 105,
      textAlign: 'center',
      fontStyle: 'italic',
      fontFamily: 'OpenSans',
      textShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
      fontSize: 16,
      '& span': {
        fontFamily: 'Montserrat',
        margin: 0,
        fontStyle: 'normal',
        fontSize: 49,
        fontWeight: 800,
      },
      '& > div > div': {
        marginTop: -10,
      },
    },
    title: {
      fontSize: 50,
      color: theme.colors.white,
      marginBottom: 0,
      marginTop: 0,
    },
    descriptiveText: {
      margin: 0,
      color: theme.colors.white,
      fontSize: 16,
      fontWeight: 600,
      textShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    },
    dueDiligenceDocs: {
      marginTop: 100,
      minHeight: 500,
      '& h3': {
        textAlign: 'center',
        color: theme.colors.white,
        fontSize: 30,
        fontWeight: 600,
      },
    },
    submitButton: {
      flexBases: '100%',
      width: 'calc(100% - 240px)',
      height: 75,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      justifyItems: 'center',
      fontSize: 20,
      fontWeight: 600,
      color: theme.colors.navyBlue,
      textAlign: 'center',
      textShadow: '0 3px 10px rgba(0, 0, 0, 0.49)',
      '& span': {
        color: theme.colors.white,
      },
    },
  })
);
