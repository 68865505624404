import React from 'react';
import { AccountCircle } from '@material-ui/icons';
import { UserContext } from '../../components/data/context';
import {
  Button,
  MenuItem,
  Popper,
  ClickAwayListener,
  Paper,
  MenuList,
  Grow,
} from '@material-ui/core';
import { logUserOut } from '../../utils/helpers';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FC } from 'react';

type Props = RouteComponentProps;

const UserInfoDropdown: FC<Props> = ({ history }) => {
  const user = React.useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleLogout = (event: React.MouseEvent<EventTarget, MouseEvent>) => {
    handleClose(event);
    logUserOut();
  };

  const goToProfile = (event: React.MouseEvent<EventTarget, MouseEvent>) => {
    handleClose(event);
    history.push('/profile');
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={handleToggle}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        style={{ zIndex: 1000 }}
      >
        <AccountCircle />
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'left' }}>
            <Paper className="menu">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem disabled>{user.name}</MenuItem>
                  {user.admin || <MenuItem onClick={goToProfile}>Profile</MenuItem>}
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default withRouter(UserInfoDropdown);
