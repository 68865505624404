import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LPLink = styled(Link)`
  color: ${({ theme }) => theme.colors.blackTwo};
  &:hover {
    color: ${({ theme }) => theme.colors.navyBlue};
  }
`;

export default LPLink;
