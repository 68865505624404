import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps,
  LinkProps as MuiLinkProps,
} from '@material-ui/core';
import React from 'react';
import Link from './Link';

export interface BreadcrumProps extends MuiLinkProps {
  to: string;
  label: any;
  component?: any;
}

interface Props extends BreadcrumbsProps {
  links: BreadcrumProps[];
}

const Breadcrumbs: React.FC<Props> = ({ links, ...props }) => {
  return (
    <MuiBreadcrumbs {...props}>
      {links.map(({ to, label, ...props }, index) => {
        let color: any = 'textSecondary';

        if (index === links.length - 1) color = 'primary';
        return (
          <Link key={`${to}-${index}`} to={to} color={color} {...props}>
            {label}
          </Link>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
