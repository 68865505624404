export enum DownloadActionTypes {
  FETCH_REQUEST = '@@DOWNLOAD/FETCH_REQUEST',
  FETCH_SUCCESS = '@@DOWNLOAD/FETCH_SUCCESS',
  FETCH_ERROR = '@@DOWNLOAD/FETCH_ERROR',
  FETCH_DOWNLOAD_REQUEST = '@@DOWNLOAD/FETCH_DOWNLOAD_REQUEST',
  FETCH_DOWNLOAD_SUCCESS = '@@DOWNLOAD/FETCH_DOWNLOAD_SUCCESS',
  FETCH_DOWNLOAD_ERROR = '@@DOWNLOAD/FETCH_DOWNLOAD_ERROR',
}

export enum DisplayImageArray {
  All = 1,
  FirstOnly = 2,
  AllButFirst = 3
}

export interface DownloadsState {
  readonly loading: boolean;
  readonly data?: any;
  readonly errors?: string;
}

export interface DownloadInfo {
  itemUniqueId: string;
  itemName: string;
  contentType: string;
  parentContentType: string;
  section: string;
  exists: boolean;
  isTermSheet: boolean;
  version: number;
  tags: string[] | null;
  library: string;
  path: string;
  relatedLP: string;
  cuFile: boolean;
  dateReceived: string | null;
}

export interface Attributes {
  LPOfferingNumber?: null;
  SellerRTNumber: string;
  BuyerRTNumber: string;
}

/*
{
  * itemUniqueId:
    ! `f13d6aad-54b8-4a97-a40b-68c0e3070845`,
  * itemName:
    ! 'LP Confidentiality Agreement Signed',
  * contentType:
    ! 'LP Confidentiality Agreement Signed',
  * parentContentType:
    ! 'LP Confidentiality Agreement Signed',
  * section:
    ! null,
  * exists:
    ! true,
  * isTermSheet:
    ! false,
  * version:
    ! 512,
  * views:
    ! ['My CU Files'],
  * tags:
    ! null,
  * restrictions:
    ! ['Credit Union'],
  * library:
    ! 'CU Files',
  * attributes:
    ! {
    ? LP Offering Number:
      * null,
    ? Seller RT Number:
      * '123456',
    ? Buyer RT Number:
      * '654321'
   },
  path: '/sites/LPMarketplaceDEV/CUSigned.pdf',
  relatedLP: null,
},
* */
