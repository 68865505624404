import React, { useContext } from 'react';
import styled from 'styled-components';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { UserContext } from '../data/context';
import LPLinkPlain from './LPLinkPlain';
import { paths } from '../../store/routePaths';
import { createStyles, darken, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperLink: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  })
);

const QuestionsWrapper = styled.div`
  position: ${(props: QuestionsWidgetProps) => props.position};
  top: ${props => (props.top ? props.top : '18%')};
  right: 0;
  text-align: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  backdrop-filter: blur(50px);
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.white};
  opacity: ${props => (props.opacity ? props.opacity : '0.5')};
  padding: ${props => (props.padding ? props.padding : '10px')};
  width: 94px;
  display: flex;
  flex-direction: column;
  z-index: 85;
  .qw-help-icon {
    svg {
      font-size: 3rem;
      color: ${props => (props.color ? props.color : props.theme.colors.black)};
    }
  }

  .qw-label {
    color: ${props => (props.color ? props.color : props.theme.colors.black)};
    font-weight: bold;
    font-size: 0.85rem;
  }

  :hover {
    background-color: ${props => darken(props.theme.colors.white, 0.1)};
  }
`;

interface QuestionsWidgetProps {
  position?: string;
  backgroundColor?: string;
  opacity?: string;
  padding?: string;
  top?: string;
}

export const QuestionsWidget = props => {
  const user = useContext(UserContext);
  const classes = useStyles(props);
  const { position = 'fixed' } = props;

  if (user.admin) {
    return null;
  }

  return (
    <LPLinkPlain to={paths.contact} className={classes.wrapperLink}>
      <QuestionsWrapper position={position} {...props}>
        <div className="qw-help-icon">
          <HelpOutlineIcon />
        </div>
        <div className="qw-label">Questions</div>
      </QuestionsWrapper>
    </LPLinkPlain>
  );
};
