/* eslint-disable react-hooks/exhaustive-deps */

import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import ActionBar from 'components/ActionBar';
import { BackButton } from 'components/Common/BackButton';
import { QuestionsWidget } from 'components/Common/QuestionsWidget';
import LoadingSpinner from 'components/data/LoadingSpinner';
import LoadingButton from 'components/LoadingButton';
import useUserInfo from 'hooks/useUserInfo';
import { ApplicationState } from 'store';
import {
  clearNotification,
  clearUser,
  userNotificationsRequest,
  userSelectRequest,
} from 'store/auth/actions';
import { AuthState } from 'store/auth/types';
import { UserManagementState } from 'store/user-management/types';
import { fluidPadding } from 'utils/styles';
import { NotificationsOrAdminPermissions } from './components/NotificationsOrAdminPermissions';
import { UserInfoInputFields } from './components/UserInfoInputFields';
import useUserProfileReducer, { actions } from './useUserProfileReducer';

const UserProfilePage: React.FC<RouteComponentProps<{ id?: string }>> = props => {
  const {
    mounted,
    user,
    dispatch,
    usePhoneForSMS,
    userRoles,
    notification,
    handleInputChange,
    toggleNotification,
    togglePermission,
    toggleUsePhoneForSMS,
    handleSubmit,
    edited,
  } = useUserProfileReducer();
  const dis = useDispatch();
  const auth = useSelector<ApplicationState, AuthState>(state => state.auth);
  const userManagement = useSelector<ApplicationState, UserManagementState>(
    state => state.userManagement
  );
  const currentUser = useUserInfo();

  useEffect(() => {
    if (mounted && userManagement.updated) {
      dispatch({ type: actions.TOGGLE_SUBMITTING });
      dispatch({ type: actions.UNMOUNT });
      dispatch({ type: actions.SET_USER, payload: undefined });
      if (!currentUser.admin) {
        dis(clearUser());
        dis(clearNotification());
        dispatch({ type: actions.SET_INITIAL_NOTIFICATIONS, payload: undefined });
        dis(userSelectRequest(user.objectId));
        dis(userNotificationsRequest(user.objectId));
      }
    }
  }, [userManagement.updated]);

  if (!mounted) {
    return (
      <Wrapper style={{ height: '100vh' }}>
        <LoadingSpinner height="100%" />
      </Wrapper>
    );
  }

  const isUserVerified = userRoles.includes('Verified');

  // if (currentUser.admin && location.pathname.toLowerCase() === '/profile')
  //   props.history.push('/dashboard');

  return (
    <PageWrapper>
      {currentUser.admin && (
        <UserStatusWrapper>
          <Typography variant="h4">
            [{user.accountEnabled ? 'active' : 'deactivated'}
            {user.accountEnabled && <span>: {isUserVerified ? 'partner' : 'unverified'}</span>}]
          </Typography>
        </UserStatusWrapper>
      )}
      {!currentUser.admin && <QuestionsWidget top={'5%'} />}
      <Wrapper>
        <BackButton />
        <ContentWrapper>
          <div className="instructions">
            <Typography variant="h4">
              Edit {currentUser.admin ? user.givenName + "'s" : 'your'} profile
            </Typography>

            <Typography variant="subtitle1">
              To make changes, place your cursor in a box to select it then start typing.
            </Typography>
          </div>

          <Form>
            <UserInfoInputFields
              user={user}
              notification={notification}
              usePhoneForSMS={usePhoneForSMS}
              toggleUsePhoneForSMS={toggleUsePhoneForSMS}
              handleInputChange={handleInputChange}
            />
            <NotificationsOrAdminPermissions
              user={user}
              currentUser={currentUser}
              notification={notification}
              togglePermission={togglePermission}
              toggleNotification={toggleNotification}
              isUserVerified={isUserVerified}
              {...props}
            />
          </Form>
        </ContentWrapper>
      </Wrapper>
      <ActionBar show={edited}>
        <LoadingButton
          color="primary"
          type="submit"
          variant="contained"
          loading={auth?.loading ? true : userManagement?.loading}
          onClick={handleSubmit}
        >
          SUBMIT CHANGES
        </LoadingButton>
      </ActionBar>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.windowsBlue};
`;

const UserStatusWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.windowsBlue};
  h4 {
    padding: 1em 1em 0 1em;
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.5;
    text-align: right;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-rows: repeat(6, auto);
  grid-template-columns: 100%;
  gap: 2em;
  width: 100%;
  input {
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
  }

  .MuiFormControl-root {
    border: 1px solid white;
    border-radius: 4px !important;
  }

  .MuiFormLabel-root.Mui-disabled {
    color: white;
  }

  .MuiInputBase-root.Mui-disabled {
    opacity: 0.3;
    color: white;
    input {
      background-color: ${({ theme }) => theme.colors.windowsBlue};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Wrapper = styled.div`
  ${fluidPadding}
  padding-top: 3em !important;
  padding-bottom: 3em !important;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.windowsBlue};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  & > div:nth-child(2) {
    flex: 1 1 auto;
  }

  .instructions {
    font-weight: bold;
    margin-bottom: 30px;
    h4 {
      font-size: 2.75em;
      font-weight: bold;
    }
    h6 {
      font-style: italic;
    }
  }

  .info {
    text-align: center;
    ${fluidPadding}
  }

  span {
    color: white;
    fill: white;
  }

  .update {
    text-align: center;
  }
`;

const ContentWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  max-width: 800px;
  margin: 0px auto;
`;

export default withRouter(UserProfilePage);
