import React from 'react';
import { Reducer } from 'redux';
import { get } from '../../../utils/api';
import {
  Filter,
  INITIAL_LIMIT,
  initialFilterState,
  Order,
  SUBSEQUENT_LIMIT,
  SUPER_LARGE_LIMIT,
} from './types';
import _ from 'underscore';

type Action = { type: TableActionTypes; payload: any };
type TableSize = 'medium' | 'small';
type Dispatch = (action: Action) => void;

export enum TableActionTypes {
  LOAD_MORE_REQUEST = '@@table/LOAD_MORE_REQUEST',
  LOAD_MORE_SUCCESS = '@@table/LOAD_MORE_SUCCESS',
  LOAD_MORE_ERROR = '@@table/LOAD_MORE_ERROR',
  FILTER_REQUEST = '@@table/FILTER_REQUEST',
  FILTER_SUCCESS = '@@table/FILTER_SUCCESS',
  FILTER_ERROR = '@@table/FILTER_ERROR',
  UPDATE_TABLE_SIZE = '@@table/UPDATE_TABLE_SIZE',
  UPDATE_LIMIT = '@@table/UPDATE_LIMIT',
  UPDATE_OFFSET = '@@table/UPDATE_OFFSET',
  UPDATE_FILTER = '@@table/UPDATE_FILTER',
  CLEAR_FILTER = '@@table/CLEAR_FILTER',
  UPDATE_ORDER = '@@table/UPDATE_ORDER',
  CLEAR_ORDER = '@@table/CLEAR_ORDER',
}

interface State {
  readonly loading: boolean;
  readonly filterLoading: boolean;
  readonly showAllLoading: boolean;
  readonly data?: any;
  readonly newDataIndices: { [key: number]: number };
  readonly errors?: string;
  readonly tableSize: TableSize;
  readonly limit: number;
  readonly offset: number;
  readonly filter: Filter;
  readonly defaultOrdering?: Order;
  readonly order: Order;
  readonly noMoreToLoad: boolean;
}
type TableProviderProps = {
  children: React.ReactNode;
  defaultOrdering?: Order;
};

const TableStateContext = React.createContext(undefined);
const TableDispatchContext = React.createContext(undefined);

export const initialState: State = {
  data: [],
  newDataIndices: {},
  errors: undefined,
  loading: false,
  filterLoading: false,
  showAllLoading: false,
  tableSize: 'medium',
  limit: INITIAL_LIMIT,
  offset: 0,
  filter: initialFilterState,
  order: undefined,
  defaultOrdering: undefined,
  noMoreToLoad: false,
};

export const tableReducer: Reducer<State> = (state: State, action: Action) => {
  switch (action.type) {
    case TableActionTypes.LOAD_MORE_REQUEST:
      return {
        ...state,
        loading: true,
        showAllLoading: state.limit === SUPER_LARGE_LIMIT,
        errors: undefined,
        newDataIndices: {},
      };
    case TableActionTypes.LOAD_MORE_SUCCESS:
      const combinedData = state.data ? [...state.data, ...action.payload] : action.payload;
      return {
        ...state,
        loading: false,
        showAllLoading: false,
        data: combinedData,
        newDataIndices: calcNewDataIndices(action.payload, state),
        noMoreToLoad: isNoMoreToLoad(action.payload, state),
      };
    case TableActionTypes.LOAD_MORE_ERROR:
      return { ...state, loading: false, showAllLoading: false, errors: action.payload };

    case TableActionTypes.FILTER_REQUEST:
      return {
        ...state,
        filterLoading: true,
        newDataIndices: {},
      };
    case TableActionTypes.FILTER_SUCCESS:
      return {
        ...state,
        filterLoading: false,
        noMoreToLoad: isNoMoreToLoad(action.payload, state),
        data: action.payload,
      };
    case TableActionTypes.FILTER_ERROR:
      return { ...state, filterLoading: false, errors: action.payload };

    case TableActionTypes.UPDATE_TABLE_SIZE:
      return { ...state, tableSize: action.payload };

    case TableActionTypes.UPDATE_LIMIT:
      return { ...state, limit: action.payload };

    case TableActionTypes.UPDATE_OFFSET:
      return { ...state, offset: action.payload };

    case TableActionTypes.UPDATE_FILTER:
      return {
        ...state,
        filter: action.payload,
        offset: action.payload.serverField ? 0 : state.offset,
        limit: INITIAL_LIMIT,
      };
    case TableActionTypes.CLEAR_FILTER:
      return {
        ...state,
        filter: { ...initialFilterState, cleared: true },
        noMoreToLoad: false,
        offset: 0,
      };

    case TableActionTypes.UPDATE_ORDER:
      return {
        ...state,
        order: { ...action.payload, direction: action.payload.direction || 'asc' },
        offset: action.payload.serverField ? 0 : state.offset,
        limit: INITIAL_LIMIT,
      };

    case TableActionTypes.CLEAR_ORDER:
      return {
        ...state,
        order: initialFilterState,
        noMoreToLoad: false,
        offset: 0,
      };

    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

export const useTable = () => {
  return [useTableState(), useTableDispatch()];
};

const useTableState = () => {
  const context = React.useContext(TableStateContext);
  if (!context) {
    throw new Error(`useTableState must be used within a TableStateProvider`);
  }
  return context;
};

const useTableDispatch = () => {
  const context = React.useContext(TableDispatchContext);
  if (!context) {
    throw new Error(`useTableDispatch must be used within a TableStateProvider`);
  }
  return context;
};

const calcNewDataIndices = (payload, state) => {
  let newDataIndices = _.range(state.data.length, state.data.length + payload.length);
  newDataIndices = newDataIndices.reduce((acc, idx) => {
    acc[idx] = idx;
    return acc;
  }, {});
  return newDataIndices;
};

const isNoMoreToLoad = (payload, state) => {
  return payload.length < SUBSEQUENT_LIMIT || state.limit === SUPER_LARGE_LIMIT;
};

const getInitialOrderState = defaultOrdering => {
  return {
    direction: defaultOrdering.direction ? defaultOrdering.direction : 'asc',
    field: defaultOrdering.field,
    serverField: defaultOrdering.serverField ? defaultOrdering.serverField : '',
    label: defaultOrdering.label ? defaultOrdering.label : '',
  };
};

const TableProvider = ({ children, defaultOrdering }: TableProviderProps) => {
  const [state, dispatch] = React.useReducer(tableReducer, {
    ...initialState,
    defaultOrdering: defaultOrdering,
    order: getInitialOrderState(defaultOrdering),
  });
  //
  return (
    <TableStateContext.Provider value={state}>
      <TableDispatchContext.Provider value={dispatch}>{children}</TableDispatchContext.Provider>
    </TableStateContext.Provider>
  );
};

export async function loadMore(dispatch, endpoint, queryParams) {
  dispatch({
    type: TableActionTypes.LOAD_MORE_REQUEST,
    payload: { endpoint: endpoint, queryParams: queryParams },
  });
  try {
    const result = await get(endpoint + '?' + queryParams);
    dispatch({ type: TableActionTypes.LOAD_MORE_SUCCESS, payload: result });
  } catch (error) {
    dispatch({ type: TableActionTypes.LOAD_MORE_ERROR, payload: error });
  }
}

export async function filter(dispatch, endpoint, queryParams) {
  dispatch({
    type: TableActionTypes.FILTER_REQUEST,
    payload: { endpoint: endpoint, queryParams: queryParams },
  });
  try {
    const result = await get(endpoint + '?' + queryParams);
    dispatch({ type: TableActionTypes.FILTER_SUCCESS, payload: result });
  } catch (error) {
    dispatch({ type: TableActionTypes.FILTER_ERROR, payload: error });
  }
}

export { TableProvider, useTableState, useTableDispatch };
