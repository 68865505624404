import {
  Box,
  BoxProps,
  CircularProgress,
  Divider,
  Drawer,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@material-ui/icons';
import logo from 'assets/images/CC_LP_Logo-Icon_2x.png';
import clsx from 'clsx';
import Breadcrumbs, { BreadcrumProps } from 'components/Breadcrumbs';
import SEO, { SEOProps } from 'components/SEO';
import useAxios from 'hooks/useAxios';
import { Footer } from 'layout/Footer';
import MarketingHeader from 'layout/Marketing/MarketingHeader';
import Sidebar from 'layout/Sidebar';
import React, { FC, Fragment, ReactNode } from 'react';
import Img from 'react-image';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

export const drawerWidth = 240;

type LayoutProps = {
  loading?: boolean;
  Toolbar?: React.ReactNode;
  Header?: any;
  Footer?: any;
  noAppbar?: boolean;
  AdditionalLinks?: () => any;
  seo?: SEOProps;
  breadcrumbs?: BreadcrumProps[];
  LoadingSpinner?: ReactNode;
} & BoxProps;

const Layout: React.FC<LayoutProps> = ({
  children,
  loading,
  AdditionalLinks,
  Header,
  Footer: FooterComponent,
  noAppbar: noAppBar = false,
  LoadingSpinner,
  ...props
}) => {
  const history = useHistory();
  const classes = useStyles(props);
  const [sidebarOpen, setSidebarOpen] = useLocalStorage('sidebar', true);

  const goHome = () => history.push('/');

  const handleToggleDrawer = () => {
    setSidebarOpen(state => !state);
  };

  return (
    <Fragment>
      <Box className={classes.root} {...props}>
        <SEO {...props.seo} />
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: sidebarOpen,
            [classes.drawerClose]: !sidebarOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: sidebarOpen,
              [classes.drawerClose]: !sidebarOpen,
            }),
          }}
        >
          <List
            style={{
              height: '100vh',
              maxHeight: '95vh',
              overflowY: 'auto',
              paddingBottom: 3,
              overflowX: 'hidden',
            }}
          >
            <ListItem button onClick={goHome}>
              <ListItemIcon>
                <Img src={logo} width={30} alt="logo" />
              </ListItemIcon>
              <ListItemText>
                <strong>LP Exchange</strong>
              </ListItemText>
            </ListItem>

            <Divider />

            <Sidebar />
          </List>
          <Divider />
          <ListItem button onClick={handleToggleDrawer} disableRipple>
            <ListItemIcon>
              {sidebarOpen ? <ChevronRightOutlined /> : <ChevronLeftOutlined />}
            </ListItemIcon>
          </ListItem>
        </Drawer>

        <Box className={classes.content} {...props}>
          <div>{Header}</div>
          {props.breadcrumbs && <Breadcrumbs links={props.breadcrumbs} />}

          <Fade in={loading} unmountOnExit mountOnEnter>
            <Box
              width="100%"
              height="100vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {LoadingSpinner}
            </Box>
          </Fade>

          {!loading && children}
          <div>{FooterComponent}</div>
        </Box>
      </Box>
    </Fragment>
  );
};

Layout.defaultProps = {
  padding: [1, 3],
  minHeight: '100vh',
  LoadingSpinner: <CircularProgress />,
};

export default Layout;

const useStyles = makeStyles<Theme, LayoutProps>(theme =>
  createStyles({
    root: {
      display: 'flex',
      '& img': { margin: 0, padding: 0 },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      display: 'flex',
      justifyContent: 'space-between',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
      marginLeftt: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    account: {
      flex: '1 1 auto',
    },
    toolbar: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: '100%',
      flexGrow: 1,
      backgroundColor: props => {
        if (theme.colors[props?.bgcolor]) return theme.colors[props.bgcolor];
        return props.bgcolor;
      },
      // padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      // color: '#fff',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);
export type RouteLayoutProps = RouteProps & {
  component: ReactNode;
  seo?: SEOProps;
};
export const RouteLayout: FC<RouteLayoutProps> = ({ component: Component, seo, ...props }) => (
  <Route
    {...props}
    render={routeProps => (
      <Layout padding={0} seo={seo}>
        <Component {...routeProps} />
      </Layout>
    )}
  />
);

export const RouteMarketingLayout: FC<RouteLayoutProps> = ({
  component: Component,
  seo,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={routeProps => {
        return (
          <Box minHeight="100vh" display="flex" flexDirection="column">
            <SEO {...seo} />
            <MarketingHeader />
            <Box flex="1">
              <Component {...routeProps} />
            </Box>
            <Footer />
          </Box>
        );
      }}
    />
  );
};

interface ComputedProps<T = {}> {
  computedMatch?: {
    params: T;
  };
}

export const RouteLPLayout: FC<RouteLayoutProps & ComputedProps<{ id: string }>> = ({
  component: Component,

  ...props
}) => {
  const history = useHistory();
  const lpNumber = props?.computedMatch?.params?.id;
  const [{ data: relation, loading, error }] = useAxios<CURelation>(
    `/marketplace/offering/${lpNumber}/culprelation`,
    {
      useCache: false,
    }
  );

  if (error) history.goBack();
  const seo = lpNumber && !loading && { ...props.seo, title: `${lpNumber}` };
  return (
    <Route
      {...props}
      render={routeProps => {
        return (
          <Layout padding={0} seo={seo}>
            <Component {...routeProps} {...relation} />
          </Layout>
        );
      }}
    />
  );
};

export interface CURelation {
  indicated?: boolean;
  committed?: boolean;
  committmentaccepted?: boolean;
}
