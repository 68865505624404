export const adminNewUsers = '/admin/new-users';
export const adminAllLps = '/admin/all-lps';
export const adminPurchaseCommitments = '/admin/purchase-commitments';
export const adminInactiveUsers = '/admin/inactive-users';
export const adminAllUsers = '/admin/all-users';
export const adminAllNewUsers = '/admin/all-new-users';
export const adminDraftLps = '/admin/draft-lps';
export const adminOpenLps = '/admin/open-lps';
export const adminIois = '/admin/iois';
export const adminNewUserVerificationPage = '/admin/manage/users/ready-to-verify/:id';
export const lps = '/lps';
export const userAllLps = '/lps/all';
export const userRecentLps = '/lps/recent';
export const userOpenLps = '/lps/open';
export const userMyLps = '/lps/my';
export const lp = '/lp/:id';
export const lpIndicationOfInterest = '/lp/:id/indication-of-interest';
export const lpPurchaseCommitment = '/lp/:id/purchase-commitment';
export const lpDueDiligence = '/api/auth/pii/lp/:id';
export const adminLpDueDiligence = '/api/auth/pii/lp/:id';
export const dashboard = '/dashboard';
export const reports = '/reports';
export const learn = '/learn';
export const buy = '/buy';
export const sell = '/sell';
export const contact = '/contact';
export const privacy = '/privacy-notice';
export const faqs = '/faqs';
export const docs = '/docs';
export const profile = '/profile';
export const adminUserProfile = '/admin/user/profile/:id';
export const forgotPassword = '/AzureADB2C/Account/ResetPassword';
export const termsofuse = '/terms-of-use';
